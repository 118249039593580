import React, { useState, useEffect, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import OrderlikeLayout from "../../../../../generic/Layouts/OrderlikeLayout";
import styled from "styled-components";
import { Pallets } from "../../../../../modules/API/Pallets/pallets";
import { IPalletDetails } from "../../../../../modules/API/Pallets/models/palletDetails";
import ControlSearchPannelLayout from "../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout";
import SearchInput from "../../../../../generic/inputs/inputSearch";
import BodyWrapContainer from "../../../../../generic/Layouts/Wrappers/BodyWrapContainer";
import ViewItem from "./ViewItem";
import ControlButton from "../../../../../generic/buttons/ControlButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/reducers/rootReducer";
import { IPackagesItem } from "../../../../../modules/API/Defaults/models/packages";
import { LINKS } from "../../../../../Routs/config";
import TitleLayout from "../generic/TitleLayout";
import TitleRow from "../generic/TitleRow";
import TitleRowItem from "../generic/TitleRowItem";
import { ITeudotItem } from "../../../../../../models/RequestsModel/teudot";
import { AppleIcon, InvoiceIcon, SpeedIcon, UserIcon, VerticalDots } from "../../../../../generic/icons/Icons";
import useDataFetcher from "../../knisot/hooks/useFetchData";
import { Defaults } from "../../../../../modules/API/Defaults/defaults";
import { IGetReuqestResponseNode } from "../../../../../modules/API/Defaults/models/pallets";
import ColumModal from "../../../../../generic/Layouts/ColumModal";
import ChangePalletModal from "./ChangePalletModal";
import TabsBodyContent from "../Components/TabsBodyContent";
import { IGrowerNodeGetResponse } from "../../../../../modules/API/Defaults/models/growers";
import { IMarketPalletDetails, IUpdateProduct } from "../../../../../modules/API/Market/models/marketRequests";
import { Market } from "../../../../../modules/API/Market/market";
import ProductModal from "../../market/Collect/Components/ProductModal";
import useTabsTeuda from "../generic/tabList";
interface Item {
  id: number;
  name: string;
}

interface Params {
  id: string;
  subID: string;
}


function TeudotView() {
  const { id, subID } = useParams<Params>();
  const history = useHistory()
  const { TAB_LIST } = useTabsTeuda();
  const titleTeuda: ITeudotItem = useSelector((teuot: RootState) => teuot.kosherTeudotReducer.title);
  const packages = useSelector((packages: RootState): IPackagesItem[] => packages.defaults.packageList);
  const isEdit = useSelector((packages: RootState) => packages.mainReducer.user?.rights)?.find(e => e === "SELLER" || e === "ADMIN" || e === "OFFICE");
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [itemsPallet, setItemsPallet] = useState<IPalletDetails[]>([]);
  const [activeTab, setActiveTab] = useState<string>(TAB_LIST[1]);
  const [isOpenProductModal, setIsOpenProductModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<IPalletDetails | null>(null);

  const {
    data: paletTypes
  } = useDataFetcher<IGetReuqestResponseNode>(
    Defaults.getPalletTypes, 'data'
  )
  const packageHash = useMemo(() => 
    packages.reduce((acc: Record<number, IPackagesItem>, cur) => {
      acc[cur.PackID] = cur
      return acc
    }, {})
  , [packages]) 

  const {
    data: growerList
  } = useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, "data"
  )
    console.log()
  const [palletDetailsList, setPalletDetailsList] = useState<IPalletDetails[]>(
    []
  );
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function fetchData() {
      // Fetch the list of items based on the id and subId parameters
      const res: IPalletDetails[] = await Pallets.getPalletsDetailsByDelivery(+id, +subID);
      const filteredPallets = res.filter((item) => item.Quantity_Pallets > 0);
      setPalletDetailsList(res);
      setItemsPallet(filteredPallets);
    }
    fetchData();
  }, [id, subID]);

  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };

  const filteredList = useMemo(
    () =>
      palletDetailsList.filter((item) =>
        item.ProductDesc?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.Pack_Name?.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [palletDetailsList, searchTerm]
  );

  const handleControlModal = () => {
    setIsOpenModal(!isOpenModal);
  }
  const handleSelectProduct = (item: IPalletDetails) => {
    setSelectedProduct(item)
    handleControlProductModal()
  }

  const handleChangePalletType = (e: number, RecID: number) => {
    const newItemsPallet = itemsPallet.map((item) => {
      if (item.RecID === RecID) {
        item.Pallet_Type = e;
      }
      return item;
    });
    setItemsPallet(newItemsPallet);
  }
  
  const palletProps = {
    palletTypeList: paletTypes?.pallets || [],
    exitsData: itemsPallet,
    handleChange: handleChangePalletType,
    handleClose: handleControlModal,
    handleSelectProduct: handleSelectProduct
  }
  const PropsForPalletItem = {

  }

  const handleControlProductModal = () => {
    setIsOpenProductModal(!isOpenProductModal);
  }
  const updateProduct = async (data: IUpdateProduct) => {
    if(!selectedProduct) return
    try {
      const recID = selectedProduct.RecID
      const palletNum = selectedProduct.Pallet_Num
      const res: IPalletDetails = await Market.updateProduct(recID, data)
      const updatedList = palletDetailsList.map((item) => {
        if(item.Pallet_Num === palletNum) {
          return res
        }
        return item
      })
      setPalletDetailsList(updatedList)
      handleControlProductModal()
    } catch (error) {
      console.log(error)
    } finally {

    }
  }
  const selectedProductModalProps = {
    data: selectedProduct,
    updateProduct: updateProduct,
    pallets: paletTypes?.pallets || [],
    growers: growerList?.growers || [],
    isWorker: false
  }
  return (
    <OrderlikeLayout label={''}>
      {
        isOpenProductModal &&
        <ColumModal
          title={`${selectedProduct?.ProductDesc.trim()} ${selectedProduct?.VarietyDesc.trim()} ${selectedProduct?.SizeID.trim()}`}
          Children={ProductModal}
          controlFunc={handleControlProductModal}
          childrenProps={selectedProductModalProps}
        />
      }
      {
        isOpenModal &&
          <ColumModal 
            title="שינוי סוג משטח"
            Children={ChangePalletModal}
            childrenProps={palletProps}
            controlFunc={handleControlModal}
          />
      }
      <TitleLayout>
        <SideButtonWrapper onClick={handleControlModal}>
          <VerticalDots />
        </SideButtonWrapper>
        <BodyTeudotTitle>
          <TitleRow>
            <TitleRowItem 
              name={`(${titleTeuda.Order_Num}) / ${titleTeuda.Delivery_Num}`}
              icon={<InvoiceIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem 
              name={titleTeuda.Customer_Name}
              icon={<UserIcon />}
            />
          </TitleRow>
          <TitleRow>
            <TitleRowItem 
              name={titleTeuda.GroupDesc}
              icon={<AppleIcon />}
            />
            <TitleRowItem 
              name={titleTeuda.TranzitDesc?.trim() || ''}
              icon={<SpeedIcon color={''} />}
            />
          </TitleRow>
        </BodyTeudotTitle>
      </TitleLayout>
      <ControlSearchPannelLayout>
        <SearchInput input={searchTerm} setInput={handleSearch} />
      </ControlSearchPannelLayout>
      <BodyWrapContainer>
        {filteredList.map((item) => (
          <ViewItem key={item.RecID} {...item} packageHash={packageHash} isEdit={isEdit} />
        ))}
      </BodyWrapContainer>

        <TabsBodyContent 
          activeTab={activeTab}
          handleTabClick={() => {}}
          renderPalletList={[]}
          PropsForPalletItem={PropsForPalletItem}
        
        />
     
        <ControlButton handleClick={() => history.goBack()} lable={'חזרה'} />
     
    </OrderlikeLayout>
  );
}
const BodyTeudotTitle = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`
const SideButtonWrapper = styled.div`
  height: 4em;
  width: 2em;
  left: 1em;
  top: calc(50% - 2em);
  position: absolute;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #297F76;
  font-size: 1.2em;
  cursor: pointer;
`
export default TeudotView;
