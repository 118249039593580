
const teudotMainTH = {
  "teudot_main_title": "ใบรับสินค้า",
  "teudot_main_search_placeholder": "ค้นหาใบรับสินค้า",
  "teudot_main_button_new": "สร้างใบรับสินค้า",
  "teudot_main_element_swipe_edit": "อัพเดท",
}

const teudotCreatePageModalTitleTH = {
  "teudot_create_modal_title_title": "ชื่อใบรับสินค้า",
  "teudot_create_modal_title_client": "เลือกลูกค้า",
  "teudot_create_modal_title_seller": "เลือกผู้ขาย",
  "teudot_create_modal_title_teudaType": "เลือกประเภทใบรับสินค้า",
  "teudot_create_modal_title_car": "หมายเลขรถ",
}

const teudotCreatePageTH = {
  "teudot_create_page_title": "ใบรับสินค้าใหม่",
  "teudot_create_page_title_ids": "ใบรับสินค้า: {{id}}",
  "teudot_create_page_search_placeholder": "ค้นหา",
  "teudot_create_page_tabs_rest": "สินค้าคงคลัง",
  "teudot_create_page_tabs_taken": "เลือก",
  "teudot_create_page_element_swipe_take": "เพิ่ม",
  "teudot_create_page_element_swipe_cancel": "ลบ",
}

const createProductModalTH = {
  "create_product_modal_title_new": "สินค้าใหม่",
  "create_product_modal_title_created": "สินค้า {{id}}",
  "create_product_modal_search": "ค้นหา",
  "create_product_modal_product": "เลือกสินค้า",
  "create_product_modal_size": "เลือกขนาด",
  "create_product_modal_grower": "ผู้ปลูก",
  "create_product_modal_grower_button": "ทั้งหมด",
  "create_product_modal_grower_package": "เลือกบรรจุภัณฑ์",
  "create_product_modal_grower_qty": "จำนวน",
  "create_product_modal_calcMethod": "วิธีการคำนวณ",
  "create_product_modal_weight": "น้ำหนัก",
  "create_product_modal_price": "ราคา",
  "create_product_modal_pallet": "ประเภทสินค้า",
  "create_product_modal_includeTareWeight": "รวมน้ำหนักเบา",
  "create_product_modal_remark": "หมายเหตุ",
  "create_product_modal_button_save": "บันทึกสินค้า",
}

export const teudotTranslationTH = {
  teudot: {
    ...teudotMainTH,
    ...teudotCreatePageModalTitleTH,
    ...teudotCreatePageTH,
    ...createProductModalTH
  }
}

