import React, {useState, useMemo} from 'react'
import { VARIATION_FILTER_KAV_LIST } from '../generic/filterKavs'
import SearchFromTheList from '../../../../../generic/customSearchComponents/SearchFromTheList'
import styled from 'styled-components'

type Props = {
  id: number
  setId: React.Dispatch<React.SetStateAction<number>> | ((id: number) => void)
}

const KavFilter = ({
  id, setId
}: Props) => {
  
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const [search, setSearch] = useState<string>(VARIATION_FILTER_KAV_LIST.filter(e => e.id === id)[0].desc.trim())
  const handleSearch = (e: string) => {
    if(e === '') {
      setIsOpenDropDown(false)
      setSearch('')
      setId(0)
      return
    }
    setIsOpenDropDown(true)
    setSearch(e)
  }
  const handleSelect = (item: {id: number, desc: string}) => {
    setSearch(item.desc.trim())
    setIsOpenDropDown(false)
    setId(item.id)
  }
  const render = useMemo(() => {
    if(!VARIATION_FILTER_KAV_LIST) return []
    return VARIATION_FILTER_KAV_LIST.filter(item => 
      item.desc.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    ) 
  }, [search])
  return (
    <MainWrapper>
      <SearchFromTheList
        isOpenList={isOpenDropDown}
        placeHolder='חפש סוג קב'
        list={render}
        selectFunc={handleSelect}
        searchFunc={handleSearch}
        isNeedFullObject
        input={search}
        itemDisplayName='desc'
        nameIdElement='id'
        size={'full'}
      />

    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 200px;

`
export default KavFilter