
const knisaMainTH = {
  "knisa_main_title": "เอกสารเข้า",
  "knisa_main_search_placeholder": "ค้นหาเอกสาร",
  "knisa_main_button_new": "เพิ่มเข้า",
}

const knisaCreatePageTH = {
  "knisa_create_page_title": "เข้าใหม่",
  "knisa_create_page_title_ids": "เข้า: {{id}}",
  "knisa_create_page_search_placeholder": "ค้นหา",
}

const knisaCreateTitleModalTH = {
  "knisa_create_modal_title_title": "เข้าใหม่",
  "knisa_create_modal_title_date": "วันที่",
  "knisa_create_modal_title_grower": "เกษตรกร",
  "knisa_create_modal_title_refference": "อ้างอิง",
  "knisa_create_modal_title_carNum": "หมายเลขรถ",
  "knisa_create_modal_title_driver": "คนขับ",
  "knisa_create_modal_title_warehouse": "คลังสินค้า",
  "knisa_create_modal_title_teudaType": "ประเภทเอกสาร",
  "knisa_create_modal_title_remark": "หมายเหตุ",
  "knisa_create_modal_title_saveTitle": "สร้างเข้า",
}

const knisaCreateProductModalTH = {
  "knisa_create_modal_product_title_create": "เพิ่มรายการเข้า",
  "knisa_create_modal_product_title_edit": "แก้ไขรายการ",
  "knisa_create_modal_product_externalEntry": "หมายเลขเข้าภายนอก",
  "knisa_create_modal_product_pallet": "พาเลท",
  "knisa_create_modal_product_qty": "จำนวน",
  "knisa_create_modal_product_product": "สินค้า",
  "knisa_create_modal_product_size": "ขนาด",
  "knisa_create_modal_product_package": "บรรจุภัณฑ์",
  "knisa_create_modal_product_weight": "น้ำหนัก",
  "knisa_create_modal_product_price": "ราคา",
  "knisa_create_modal_product_detection": "ตำแหน่ง",
  "knisa_create_modal_product_copy": "คัดลอก",

  "knisa_create_modal_product_includeWeight": "รวมน้ำหนัก",
  "knisa_create_modal_product_createButton": "เพิ่มรายการ",
}

const knisaCreateElementTH = {
  "knisa_create_element_swipe_edit": "แก้ไข",
  "knisa_create_element_swipe_delete": "ลบ",
}

export const knisaTranslationTH = {
  knisa: {
    ...knisaMainTH,
    ...knisaCreatePageTH,
    ...knisaCreateTitleModalTH,
    ...knisaCreateProductModalTH,
    ...knisaCreateElementTH
  }
}
