import React from 'react'
import { OrderDetail } from '../../../../../../modules/API/Orders/models/detailsProductCustomer'
import styled from 'styled-components'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { EditIcon, UserIcon } from '../../../../../../generic/icons/Icons'
import { stylePackByID } from '../../../../../../generic/utils/stylePackByPackType'
import { statusConverter } from '../../../../../../generic/artcoded/statusAzmanot'

interface Props extends OrderDetail {
  handleNestedProduct: (recID: number) => void
}

const ProductDetailsItem = ({
  Customer_Name, 
  SupplyQty, 
  OrderPacks, 
  Pack_Type, 
  Status, 
  handleNestedProduct,
  RecID
}: Props) => {
  const stylePack = stylePackByID(Pack_Type)
  const {status, color} = statusConverter(Status)
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    handleNestedProduct(RecID)
  }
  return (
    <MainWrapper>
      <TitleLayout
        disableShadow
      >
        <TitleRow>
          <TitleRowItem 
            name={Customer_Name.trim()}
            icon={<UserIcon />}
          />
          <TitleRowItem 
            name={`${OrderPacks}`}
            icon={stylePack.component}
            iconColor={stylePack.color}
          />
          <Circle color={color} >
            {status.at(0)}
          </Circle>
        </TitleRow>
        <SideWrapper
          onClick={handleClick}
        >
          <EditIcon />
        </SideWrapper>
      </TitleLayout>
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
`
const Circle = styled.div<{color: string} >`
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background-color: ${props => props.color};
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #297F76;
`
const SideWrapper = styled.div`
  position: absolute;
  left: 0.3em;
  width: 2em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #297F76;
  cursor: pointer;

`
export default ProductDetailsItem