import React from 'react'
import { ISellerTableProductItem } from '../../../../../modules/API/Seller/models/tableData'
import TableBody from '../../procedures/Q8/Layouts/Table/TableBody'
import SellerBodyBlock from './SellerBodyBlock'
import { ISellerChangeOrderProps } from '../types/handlerChangeOrder'

type Props = {
  list: ISellerTableProductItem[],
  handleChangeOrder: (props: ISellerChangeOrderProps) => void
}

const SellerBody = ({
  list,
  handleChangeOrder
}: Props) => {
  return (
    <TableBody>
      {
        list.map((item, index) => (
          <SellerBodyBlock
            key={item.id}
            {...item}
            handleChangeOrder={handleChangeOrder}
          />
        ))
      }
    </TableBody>
  )
}

export default SellerBody