export const INNER_ROUTE_MAIN_PAGE = '/main/'

// ORDERS
const ORDERS_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}azmanot/`
const ORDER_CLIENT_ORDERS_SUBMODULE = `${ORDERS_MAIN_URL}client-orders/`
const ORDER_GROWER_ORDERS_SUBMODULE = `${ORDERS_MAIN_URL}grower-orders/`

// GATHERING
const LIKUT_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}warhouse/`

// PACKAGES
const ARIZOT_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}arizot/`
const ARIZOT_AHZARA_SUBMODULE = `${ARIZOT_MAIN_URL}ahzara/`
const ARIZOT_MLAI_SUBMODULE = `${ARIZOT_MAIN_URL}mlai/`

// SHIPPING DOCUMENTS
const TEUDOT_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}teudot/`
const TEUDOT_TEUDOTMISHLOAH_SUBMODULE = `${TEUDOT_MAIN_URL}teudot-mishloah/`

// EXPECTED
const CEFI_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}cefi/`

// DISTRIBUTION
const AFACA_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}trucks/`

// PRICES
const PRICES_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}prices/`
const PRICES_SALESPRICE_SUBMODULE = `${PRICES_MAIN_URL}sales/`

// PALLETS
const PALLETS_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}pallet/`
const PALLETS_CONTROL_SUBMODULE = `${PALLETS_MAIN_URL}control/`

// DRIVERS
const DRIVERS_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}drivers/`
const DRIVERS_QUESTS_SUBMODULE = `${DRIVERS_MAIN_URL}quests`

// MIUN
const MIUN_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}miun/`
const MIUN_CONTROL_SUBMODULE = `${MIUN_MAIN_URL}control/`

// KNISOT
const KNISOT_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}knisot/`
const KNISOT_CONTROL_SUBMODULE = `${KNISOT_MAIN_URL}teudot/`

// QUERIES
const QUERIES_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}queries/`
const QUERIES_TEUDOT_DETAILS_SUBMODULE = `${QUERIES_MAIN_URL}teudot-details/`

// BILLS
const BILLS_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}bills/`
const BILLS_CLIENT_BILLS_SUBMODULE = `${BILLS_MAIN_URL}clients/`
//
const MLAI_MAIN_URL = `${INNER_ROUTE_MAIN_PAGE}mlai/`
export const LINKS = {
    orders: {
        moduleUrl: ORDERS_MAIN_URL,
        clientOrders: {
            mainUrl: ORDER_CLIENT_ORDERS_SUBMODULE,
            create: `${ORDER_CLIENT_ORDERS_SUBMODULE}create`,
            editGeneral: `${ORDER_CLIENT_ORDERS_SUBMODULE}edit/:id`,
            editByID: `${ORDER_CLIENT_ORDERS_SUBMODULE}edit/`
        },
        growerOrders: {
            mainUrl: ORDER_GROWER_ORDERS_SUBMODULE,
            create: `${ORDER_GROWER_ORDERS_SUBMODULE}create`,
            editGeneral: `${ORDER_GROWER_ORDERS_SUBMODULE}edit/:id`,
            editByID: `${ORDER_GROWER_ORDERS_SUBMODULE}edit/`
        },
        count: {
            mainUrl: `${ORDERS_MAIN_URL}count`,
        },
        product: {
            mainUrl: `${ORDERS_MAIN_URL}product`,
        }
    },
    likut: {
        moduleUrl: LIKUT_MAIN_URL,
        likutExperess: {
            mainUrl: `${LIKUT_MAIN_URL}likutexpress`,
            palletSection: `${LIKUT_MAIN_URL}likutexpress/pallet`,
            likutWorkSection: `${LIKUT_MAIN_URL}likutexpress/pallet/likut`
        },
        likutWithoutOrder: {
            mainUrl: `${LIKUT_MAIN_URL}likutexpress/pallet`,
        },
        checkPallets: {
            mainUrl: `${LIKUT_MAIN_URL}checkpallet`,
        },
        controlWorkers: {
            mainUrl: `${LIKUT_MAIN_URL}workers`,
        },
        bakara: {
            mainUrl: `${LIKUT_MAIN_URL}monitoring`,
        },
        statisticStatus: {
            mainUrl: `${LIKUT_MAIN_URL}status`,
        },
        gatheringStats: {
            mainUrl: `${LIKUT_MAIN_URL}statistic`,
        }
    },
    arizot: {
        moduleUrl: ARIZOT_MAIN_URL,
        arizaAhzara : {
            mainUrl: `${ARIZOT_AHZARA_SUBMODULE}`,
            create: `${ARIZOT_AHZARA_SUBMODULE}create`,
            editGeneral: `${ARIZOT_AHZARA_SUBMODULE}edit/:id`,
            editByID: `${ARIZOT_AHZARA_SUBMODULE}edit/`

        },
        arizaMlai : {
            mainUrl: ARIZOT_MLAI_SUBMODULE,
            create: `${ARIZOT_MLAI_SUBMODULE}create`,
            editGeneral: `${ARIZOT_MLAI_SUBMODULE}edit/:id`,
            editByID: `${ARIZOT_MLAI_SUBMODULE}edit/`

        },
        movements: {
            mainUrl: `${ARIZOT_MAIN_URL}movements`,
        }
    }, 
    teudot: {
        moduleURL: TEUDOT_MAIN_URL,
        teudotMishloah: {
            mainURL: `${TEUDOT_TEUDOTMISHLOAH_SUBMODULE}`,
            create: `${TEUDOT_TEUDOTMISHLOAH_SUBMODULE}create/:id/:subID`,
            createByID: `${TEUDOT_TEUDOTMISHLOAH_SUBMODULE}create/`,
            editGeneral: `${TEUDOT_TEUDOTMISHLOAH_SUBMODULE}edit/:id/:subID`,
            editByID: `${TEUDOT_TEUDOTMISHLOAH_SUBMODULE}edit/`
        }
    },
    cefi: {
        moduleURL: CEFI_MAIN_URL,
        cefi: {
            mainURL: CEFI_MAIN_URL,
            create: `${CEFI_MAIN_URL}create`,
            editGeneral: `${CEFI_MAIN_URL}edit/:id`,
            editByID: `${CEFI_MAIN_URL}edit/`
        }
    },
    afaca: {
        moduleURL: AFACA_MAIN_URL,
        trucksGathering: {
            mainURL: `${AFACA_MAIN_URL}control`,
         
        },
        driversReport: {
            mainURL: `${AFACA_MAIN_URL}driversreport`,
        }
    },
    prices: {
        moduleURL: PRICES_MAIN_URL,
        salesData: {
            mainURL: PRICES_SALESPRICE_SUBMODULE,
         
        }
    },
    mishtahim: {
        moduleURL: PALLETS_MAIN_URL,
        control: {
            mainURL: PALLETS_CONTROL_SUBMODULE,
            create: `${PALLETS_CONTROL_SUBMODULE}create/`, // id
            createWithID: `${PALLETS_CONTROL_SUBMODULE}create/:id`, // id
         
        },
        market: {
            mainURL: `${PALLETS_MAIN_URL}market`,
        },
        location: {
            mainURL: `${PALLETS_MAIN_URL}location`,
        },
        editCustomer: {
            mainURL: `${PALLETS_MAIN_URL}editcustomer`,
        }, 
        likutPrahim: {
            mainURL: `${PALLETS_MAIN_URL}likut`,
            editGeneral: `${PALLETS_MAIN_URL}likut/edit/:id`,
            editByID: `${PALLETS_MAIN_URL}likut/edit/`
        }
        
    },
    drivers: {
        moduleURL: DRIVERS_MAIN_URL,
        quests: {
            mainURL: DRIVERS_QUESTS_SUBMODULE,
            createOrderPackages: `${DRIVERS_QUESTS_SUBMODULE}/create`,
            editGeneral: `${DRIVERS_QUESTS_SUBMODULE}/edit/:id`,
            editByID: `${DRIVERS_QUESTS_SUBMODULE}/edit/`
        }
        
    },
    miun: {
        moduleURL: MIUN_MAIN_URL,
        control: {
            mainURL: MIUN_CONTROL_SUBMODULE,
            create: `${MIUN_CONTROL_SUBMODULE}create`,
            editGeneral: `${MIUN_CONTROL_SUBMODULE}edit/:id`,
            editByID: `${MIUN_CONTROL_SUBMODULE}edit/`
        }
        
    },
    knisot: {
        moduleURL: KNISOT_MAIN_URL,
        teudot: {
            mainURL: KNISOT_CONTROL_SUBMODULE,
            create: `${KNISOT_CONTROL_SUBMODULE}create`,
            editGeneral: `${KNISOT_CONTROL_SUBMODULE}create/:id`,
            editByID: `${KNISOT_CONTROL_SUBMODULE}create/`
        },
        external: {
            mainURL: `${KNISOT_MAIN_URL}external`,
            editGeneral: `${KNISOT_MAIN_URL}external/edit/:id`,
            editByID: `${KNISOT_MAIN_URL}external/edit/`
        }
    },
    queries: {
        moduleURL: QUERIES_MAIN_URL,
        teudotDetails: {
            mainURL: QUERIES_TEUDOT_DETAILS_SUBMODULE
        },
        q4: {
            mainURL: `${QUERIES_MAIN_URL}q4`
        },
        q2: {
            mainURL: `${QUERIES_MAIN_URL}q2`
        },
        q8: {
            mainURL: `${QUERIES_MAIN_URL}q8`
        },
        q10: {
            mainURL: `${QUERIES_MAIN_URL}q10`
        }

    },
    bills: {
        moduleURL: BILLS_MAIN_URL,
        clientBills: {
            mainURL: BILLS_CLIENT_BILLS_SUBMODULE,
            editGeneral: `${BILLS_CLIENT_BILLS_SUBMODULE}edit/:id`,
            editByID: `${BILLS_CLIENT_BILLS_SUBMODULE}edit/`
        },
        customerBills: {
            mainURL: `${BILLS_MAIN_URL}customers/`,
            editGeneral: `${BILLS_MAIN_URL}customers/edit/:id`,
            editByID: `${BILLS_MAIN_URL}customers/edit/`
        },
        payments: {
            mainURL: `${BILLS_MAIN_URL}payments/`,
            editGeneral: `${BILLS_MAIN_URL}payments/edit/:id`,
            editByID: `${BILLS_MAIN_URL}payments/edit/`
        },
        receipt: {
            mainURL: `${BILLS_MAIN_URL}receipt/`,
            editGeneral: `${BILLS_MAIN_URL}receipt/edit/:id`,
            editByID: `${BILLS_MAIN_URL}receipt/edit/`,
            create: `${BILLS_MAIN_URL}receipt/create`,
        }
    },
    mlai: {
        moduleURL: MLAI_MAIN_URL,
        mlai: {
            mainURL: `${MLAI_MAIN_URL}mlai`,
            create: `${MLAI_MAIN_URL}mlai/create/:id/:subID`,
            editGeneral: `${MLAI_MAIN_URL}mlai/edit/:id`,
            editByID: `${MLAI_MAIN_URL}mlai/edit/`
        }
    },
    forecasts: {
        moduleURL: `${INNER_ROUTE_MAIN_PAGE}forecasts/`,
        distribution: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}forecasts/distribution`,
            
        },
        products: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}forecasts/products`,
            
        },
        supply: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}forecasts/supply/:id`,
        },
        supplyWarehouse: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}forecasts/supply/`,
        },
        seller: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}forecasts/seller`,
        }
    },
    seller: {
        moduleUrl: `${INNER_ROUTE_MAIN_PAGE}seller/`,
        seller: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}seller/order`,
        },
    },
    defaultTables: {
        moduleURL: `${INNER_ROUTE_MAIN_PAGE}default-tables/`,
        products: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}default-tables/products`,
        },
        clients: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}default-tables/clients`,
        },
        sellers: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}default-tables/sellers`,
        },
        growers: {
            mainURL: `${INNER_ROUTE_MAIN_PAGE}default-tables/growers`,
        }  
    }
}
