import React, { useEffect, useMemo, useRef, useState } from "react";
import FullSizeLayout from "../../../../generic/Layouts/FullSizeLayout";
import SellerTable from "./Components/SellerTable";
import { addIDForTheList } from "../../../../generic/utils/addUIDForTheList";
import {
  ISellerDataHashItem,
  ISellerHashCustomerTopData,
  ISellerHeshCustomerItem,
  ISellerHeshGrowerItem,
} from "./types/hash";
import {
  Customer,
  ISellerTableDataItem,
  ISellerTableProductItem,
} from "../../../../modules/API/Seller/models/tableData";
import ControlSearchPannelLayout from "../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout";
import DatePickerISO from "../forcasts/Distribution/Components/DatePickerISO";
import SearchInput from "../../../../generic/inputs/inputSearch";
import { FilterIcon } from "../../../../generic/icons/Icons";
import DropdownByIcon from "../azmanotRehesh/Layouts/DropdownByIcon";
import SearchCustomers from "../forcasts/Supply/Components/SearchCustomers";
import SearchGrower from "../knisot/Create/Components/SearchGrower";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/reducers/rootReducer";
import useDataFetcher from "../knisot/hooks/useFetchData";
import { Defaults } from "../../../../modules/API/Defaults/defaults";
import { IGrowerNodeGetResponse } from "../../../../modules/API/Defaults/models/growers";
import { SELLER } from "../../../../modules/API/Seller/seller";
import { format } from "date-fns";
import { LOAD, LOAD_END } from "../../../../../store/actions/actionsTypes";
import { ISellerChangeOrderProps } from "./types/handlerChangeOrder";
import useSellerController from "./Service/useSellerController";
import { ISellerForecastReqData, ISellerReqData } from "../../../../modules/API/Seller/models/reqests";
import useDebounce from "./Helpers/useDebounce";
import axios, { CancelToken, CancelTokenSource } from "axios";

// const testList: ISellerTableProductItem[] = addIDForTheList(testData.Products)
const SellerMain = () => {
  const customerList = useSelector(
    (state: RootState) => state.defaults.clientList.list
  );
  const {
    postData,
    putData,
    createForecast,
    updateForecast
  } = useSellerController()

  const [date, setDate] = useState<Date>(new Date());
  const [input, setInput] = useState<string>("");
  const [selectedGrower, setSelectedGrower] = useState<number>(0);
  const [selectedCustomer, setSelectedCustomer] = useState<number>(0);
  const [listWithID, setListWithID] = useState<ISellerTableProductItem[]>([]);
  const [tableMeta, setTableMeta] = useState<Customer[]>([]);
  const [changeData, setChangeData] = useState<ISellerChangeOrderProps | null>(null)
  
  const debouncedChangeData  = useDebounce(changeData, 1000)

  const dispatch = useDispatch();
  const { data: growerData } = useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList,
    "data"
  );

  // const listWithID: ISellerTableProductItem[] = useMemo(() => {
  //   if(!tableData) return []
  //   return addIDForTheList(tableData.Products)
  // }, [tableData])

  const hashData = useMemo(() => {
    if (!listWithID) return {};
    const hash = listWithID.reduce((acc: ISellerDataHashItem, cur, index) => {
      if (!cur.id) return acc;

      const growers = cur.Growers.reduce(
        (accGrower: ISellerHeshGrowerItem, curGrower, indexGrower) => {
          const customers = curGrower.Customers.reduce(
            (
              accCustomer: ISellerHeshCustomerItem,
              curCustomer,
              indexCustomer
            ) => {
              accCustomer[curCustomer.Customer.CustomerID] = {
                index: indexCustomer,
              };
              return accCustomer;
            },
            {}
          );

          accGrower[curGrower.Grower.GrowerID] = {
            index: indexGrower,
            customers: customers,
          };

          return accGrower;
        },
        {}
      );

      const customersTop = cur.Customers.reduce(
        (
          accCustomer: ISellerHashCustomerTopData,
          curCustomer,
          indexCustomer
        ) => {
          accCustomer[curCustomer.Customer.CustomerID] = {
            index: indexCustomer,
          };
          return accCustomer;
        },
        {}
      );

      acc[cur.id] = {
        index: index,
        customers: customersTop,
        growers: growers,
      };
      return acc;
    }, {});
    return hash;
  }, [listWithID]);

  const handleChangeOrder = ({
    id,
    customerTopID,
    growerID,
    customerInnerID,
    isForecast,
    qty,
  }: ISellerChangeOrderProps) => {
    const newArr = [...listWithID];
    const productIndex = hashData[id].index;
    if(isForecast && growerID) {
      const growerIndex = hashData[id].growers[growerID].index;
      // newArr[productIndex].Growers[growerIndex].Forecast.ForecastPallets = qty;
      const updatedGrowers = [...newArr[productIndex].Growers];
      updatedGrowers[growerIndex].Forecast = {
        ...updatedGrowers[growerIndex].Forecast,
        ForecastPallets: qty
      }
      newArr[productIndex] = {
        ...newArr[productIndex],
        Growers: updatedGrowers
      }
      // newArr[productIndex].Growers[growerIndex].Forecast = {
      //   ...newArr[productIndex].Growers[growerIndex].Forecast,
      //   ForecastPallets: qty
      // }
    }
    if (growerID && customerInnerID) {
      const growerIndex = hashData[id].growers[growerID].index;
      const customerInnerIndex =
        hashData[id].growers[growerID].customers[customerInnerID].index;
      // newArr[productIndex].Growers[growerIndex].Customers[
      //   customerInnerIndex
      // ].OrderDetail.Order_Pallets = qty;
      const updatedGrowers = [...newArr[productIndex].Growers];
      const updatedCustomers = [...updatedGrowers[growerIndex].Customers];
      const updatedOrderDetail = {
        ...updatedCustomers[customerInnerIndex].OrderDetail,
        Order_Pallets: qty
      };
      
      // newArr[productIndex].Growers[growerIndex].Customers[customerInnerIndex].OrderDetail = {
      //   ...newArr[productIndex].Growers[growerIndex].Customers[customerInnerIndex].OrderDetail,
      //   Order_Pallets: qty
      // }
      updatedCustomers[customerInnerIndex] = {
        ...updatedCustomers[customerInnerIndex],
        OrderDetail: updatedOrderDetail
      }
      updatedGrowers[growerIndex] = {
        ...updatedGrowers[growerIndex],
        Customers: updatedCustomers
      }
      

      newArr[productIndex] = {
        ...newArr[productIndex],
        Growers: updatedGrowers
      }
      
    }
    if (customerTopID) {
      const customerTopIndex = hashData[id].customers[customerTopID].index;
      // newArr[productIndex].Customers[
      //   customerTopIndex
      // ].OrderDetail.Order_Pallets = qty;

      const updatedCustomers = [...newArr[productIndex].Customers];
      const updatedOrderDetail = {
        ...updatedCustomers[customerTopIndex].OrderDetail,
        Order_Pallets: qty
      }
      updatedCustomers[customerTopIndex] = {
        ...updatedCustomers[customerTopIndex],
        OrderDetail: updatedOrderDetail
      }
      newArr[productIndex] = {
        ...newArr[productIndex],
        Customers: updatedCustomers
      }

      // newArr[productIndex]
      //   .Customers[customerTopIndex]
      //   .OrderDetail = {
      //     ...newArr[productIndex].Customers[customerTopIndex].OrderDetail,
      //     Order_Pallets: qty
      //   }

    }
    setListWithID(newArr);
    return { 
      id: id, 
      growerID: growerID, 
      customerTopID: customerTopID, 
      customerInnerID: customerInnerID, 
      isForecast: isForecast,
      qty: qty 
    }
  };

  const onInputChange = (data: ISellerChangeOrderProps) => {
    const result = handleChangeOrder(data);
    setChangeData(result);
  };

  useEffect(() => {
    if (!debouncedChangeData) return;


    const { id, customerTopID, growerID, customerInnerID, qty, isForecast } = debouncedChangeData;
    const newArr = [...listWithID];
    const productIndex = hashData[id].index;
    if(isForecast && growerID) {
      const growerIndex = hashData[id].growers[growerID].index;
      const productData = newArr[productIndex];
      const order = newArr[productIndex].Growers[growerIndex];

      const formedData: ISellerForecastReqData = {
        RecID: order.Forecast.RecID,
        ProductID: productData.Product.ProductID,
        GrowerID: growerID,
        ForecastPallets: qty,
        VarietyID: productData.Product.VarietyID,
        SizeID: productData.Product.SizeID,
        Collection_Date: format(date, "yyyyMMdd"),
      };

      const isNewOrder = order.Forecast.RecID === 0;
      (async () => {
        
   
        if (isNewOrder) {
          const res = await createForecast(formedData);
          if(res) {
            // newArr[productIndex].Growers[growerIndex].Forecast = res[0];
            newArr[productIndex].Growers[growerIndex].Forecast = {
              ...newArr[productIndex].Growers[growerIndex].Forecast,
              ...res[0]
            }
            setListWithID(newArr);
          }
        } else {
           await updateForecast(formedData);
        }
        
      })();
      
    }
    if (growerID && customerInnerID) {
      const growerIndex = hashData[id].growers[growerID].index;
      const customerInnerIndex = hashData[id].growers[growerID].customers[customerInnerID].index;

      const productData = newArr[productIndex];
      const order = newArr[productIndex].Growers[growerIndex].Customers[customerInnerIndex];

      const formedData: ISellerReqData = {
        RecID: order.OrderDetail.RecID,
        ProductID: productData.Product.ProductID,
        CustomerID: order.Customer.CustomerID,
        GrowerID: growerID,
        Order_Pallets: qty,
        VarietyID: productData.Product.VarietyID,
        SizeID: productData.Product.SizeID,
        Delivery_Date: format(date, "yyyyMMdd"),
        LineType: 2
      };

      const isNewOrder = order.OrderDetail.RecID === 0;
      (async () => {
        let res;
        if (isNewOrder) {
          res = await postData(formedData);
        } else {
          res = await putData(formedData);
        }
        if (res) {
          // newArr[productIndex].Growers[growerIndex].Customers[customerInnerIndex].OrderDetail = res;
          newArr[productIndex].Growers[growerIndex].Customers[customerInnerIndex].OrderDetail = {
            ...newArr[productIndex].Growers[growerIndex].Customers[customerInnerIndex].OrderDetail,
            ...res
          };
          setListWithID(newArr);
        }
      })();

      // calc total by grower, if its more then Order_Pallets, then set Order_Pallets equal to total by grower and make request fore change Order line type 1

      const totalByGrower = newArr[productIndex].Growers.reduce((acc, cur) => {
        return acc + cur.Customers[customerInnerIndex].OrderDetail.Order_Pallets
      }, 0)
      const customerOrder = newArr[productIndex].Customers.find(customer => customer.Customer.CustomerID === order.Customer.CustomerID)
     
      if(customerOrder && totalByGrower > customerOrder.OrderDetail.Order_Pallets) {
        const formedData: ISellerReqData = {
          RecID: customerOrder.OrderDetail.RecID,
          ProductID: productData.Product.ProductID,
          CustomerID: order.Customer.CustomerID,
          GrowerID: growerID,
          Order_Pallets: totalByGrower,
          VarietyID: productData.Product.VarietyID,
          SizeID: productData.Product.SizeID,
          Delivery_Date: format(date, "yyyyMMdd"),
          LineType: 1
        };
        (async () => {
          let res;
          if (isNewOrder) {
            res = await postData(formedData);
          } else {
            res = await putData(formedData);
          }
          if (res) {
            // set Order_Pallets on top lvl customers equal to total by grower
            const updatedCustomers = [...newArr[productIndex].Customers];
            const updatedOrderDetail = {
              ...updatedCustomers[customerInnerIndex].OrderDetail,
              RecID: res.RecID,
              Order_Pallets: totalByGrower
            }
            updatedCustomers[customerInnerIndex] = {
              ...updatedCustomers[customerInnerIndex],
              OrderDetail: updatedOrderDetail
            }
            newArr[productIndex] = {
              ...newArr[productIndex],
              Customers: updatedCustomers
            }
            
            console.log(totalByGrower)
            setListWithID(newArr);
          }
        })();
        }
    }


    if (customerTopID) {
      const customerTopIndex = hashData[id].customers[customerTopID].index;
      const productData = newArr[productIndex];
      const order = newArr[productIndex].Customers[customerTopIndex];

      const formedData: ISellerReqData = {
        RecID: order.OrderDetail.RecID,
        ProductID: productData.Product.ProductID,
        CustomerID: order.Customer.CustomerID,
        GrowerID: 0,
        Order_Pallets: qty,
        VarietyID: productData.Product.VarietyID,
        SizeID: productData.Product.SizeID,
        Delivery_Date: format(date, "yyyyMMdd"),
        LineType: 1
      };
  
      const isNewOrder = order.OrderDetail.RecID === 0;
  
      (async () => {
        let res;
        if (isNewOrder) {
          res = await postData(formedData);
        } else {
          res = await putData(formedData);
        }
        if (res) {
          // newArr[productIndex].Customers[customerTopIndex].OrderDetail = res;
          newArr[productIndex].Customers[customerTopIndex].OrderDetail = {
            ...newArr[productIndex].Customers[customerTopIndex].OrderDetail,
            ...res
          };
          setListWithID(newArr);
        }
      })();
    } 

    
  }, [debouncedChangeData]);

  useEffect(() => {
    (async () => {
      dispatch({ type: LOAD });
      try {
        const res = await SELLER.getSellerPGC(format(date, "yyyyMMdd"));
        setTableMeta(res.Metadata);
        setListWithID(addIDForTheList(res.Products));
      } catch {
      } finally {
        dispatch({ type: LOAD_END })
      }
    })();
  }, [date])

  const render = useMemo(() => {
    let data = [...listWithID];
     data = listWithID.map((item) => {
        let newItem = { ...item };
        if(selectedGrower !== 0) {
          newItem.Growers = item.Growers.filter(grower => grower.Grower.GrowerID === selectedGrower)
        }
        if(selectedCustomer !== 0) {
          newItem.Customers = newItem.Customers.filter(customer => customer.Customer.CustomerID === selectedCustomer)
          newItem.Growers = newItem.Growers.map(grower => {
            let newGrower = { ...grower }
            newGrower.Customers = newGrower.Customers.filter(customer => customer.Customer.CustomerID === selectedCustomer)
            return newGrower
          })
        }
        return newItem
    }).filter((item) => 
      (item.Growers.length > 0) &&
      `${item.Product.ProductDesc.trim()} ${item.Product.VarietyDesc.trim()}`
        .toLowerCase()
          .includes(input.toLowerCase()
    )
    )
    return data;
  }, [listWithID, input, selectedGrower, selectedCustomer])

  const renderTitle = useMemo(() => {
    if (selectedCustomer === 0) return tableMeta;
      return tableMeta.filter((item) => item.CustomerID === selectedCustomer || item.CustomerID === 0)
  }, [selectedCustomer, tableMeta])

  
  return (
    <FullSizeLayout label="סידור יום למשווק">
      <ControlSearchPannelLayout>
        <DatePickerISO selected={date} onChange={setDate} />
        <SearchInput input={input} setInput={setInput} value={"חיפוש פריט"} />
        <DropdownByIcon
          icon={<FilterIcon />}
          isSelected={!!selectedCustomer || !!selectedGrower}
          isOreintaionRight
        >
          <FilterWrapper>
            <SearchCustomers
              defaultID={selectedCustomer}
              setCustomerID={setSelectedCustomer}
              list={customerList || []}
            />
            <SearchGrower
              defaultID={selectedGrower}
              setGrowerID={setSelectedGrower}
              list={growerData?.growers || []}
            />
          </FilterWrapper>
        </DropdownByIcon>
      </ControlSearchPannelLayout>
      {
        <SellerTable
          meta={renderTitle}
          list={render}
          handleChangeOrder={onInputChange}
        />
      }
    </FullSizeLayout>
  );
};


const FilterWrapper = styled.div`
  width: 300px;
  padding: 0.5em;
  background-color: #fff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
export default SellerMain;
