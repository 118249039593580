import React from 'react'
import styled from 'styled-components'
import { AppleIcon, ScalesIcon } from '../../../../../generic/icons/Icons'
import { stylePackByID } from '../../../../../generic/utils/stylePackByPackType'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import { IPalletDetailes } from '../../../../../modules/API/Pallets/models/palletList'
import ProductImage from '../../queries/TeudotDetails/Components/ProductImage'

interface IPalletDetailesItem extends IPalletDetailes {
    packageHash?: Record<number, IPackagesItem>
    handleSelectProduct?: (item: IPalletDetailes) => void
    item: IPalletDetailes
}
interface IRowElement {
    color?: string
}

const PalletDetailesItem = ({
     ProductDesc, 
     VarietyDesc, 
     SizeID, 
     packageHash, 
     PackID, 
     Quantity, 
     WeightBuy, 
     Pack_Name, 
     handleSelectProduct, 
     GeneralProductID,
     item,
     InvoiceBuy
    }: IPalletDetailesItem) => {
    const goodTitle = `${SizeID.trim()} ${VarietyDesc.trim()}  ${ProductDesc.trim()}`
    const packType = packageHash ? packageHash[PackID]?.Pack_Type : 0
    const { color, component } = stylePackByID(packType)
    const handleSelectForUpdateProduct = () => {
        if(!handleSelectProduct || InvoiceBuy !== 0) return
        handleSelectProduct(item)
    }
    return (
        <MainWrapper
            onClick={handleSelectForUpdateProduct}
        >
            <SideWrapper>
                <ProductImage
                    id={GeneralProductID}
                />
            </SideWrapper>
            <RowItem>
                <RowElement >
                    {goodTitle}
                    <AppleIcon />
                </RowElement>
            </RowItem>
            <RowItem>
                <RowElement >
                    {WeightBuy}
                    <ScalesIcon />
                </RowElement>
                <RowElement >
                    {Quantity} - {Pack_Name?.trim()}
                    {component}
                </RowElement>
            </RowItem>
        </MainWrapper>
    )
}

const MainWrapper = styled.div`
position: relative;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 0.3em 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.5em;
    color: #7D7DA4;
    i {
        color: #7ab0ab;
    }
`
const RowItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 1em;
`
const RowElement = styled.div<IRowElement>`
    display: flex;
    gap: 0.5em;
    i {
        color: ${props => props.color ? props.color : '#7ab0ab'}
    }
    @media screen and (max-width: 800px) {
        font-size: 0.9em;
    }
`
const SideWrapper = styled.div`
    position: absolute;
    top: 0.2em;
    left: 1em;
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #297F76;
    cursor: pointer;
    transition: all 0.3s ease-in;
    :hover {
        color: #7ab0ab;
    }
`
export default PalletDetailesItem