
const knisaMainRU = {
  "knisa_main_title": "Входные документы",
  "knisa_main_search_placeholder": "Поиск документа",
  "knisa_main_button_new": "Добавить поступление",
}

const knisaCreatePageRU = {
  "knisa_create_page_title": "Новое поступление",
  "knisa_create_page_title_ids": "Поступление: {{id}}",
  "knisa_create_page_search_placeholder": "Поиск",
}

const knisaCreateTitleModalRU = {
  "knisa_create_modal_title_title": "Новое поступление",
  "knisa_create_modal_title_date": "Дата",
  "knisa_create_modal_title_grower": "Поставщик",
  "knisa_create_modal_title_refference": "Ссылка",
  "knisa_create_modal_title_carNum": "Номер машины",
  "knisa_create_modal_title_driver": "Водитель",
  "knisa_create_modal_title_warehouse": "Склад",
  "knisa_create_modal_title_teudaType": "Тип документа",
  "knisa_create_modal_title_remark": "Примечание",
  "knisa_create_modal_title_saveTitle": "Создать поступление",
}

const knisaCreateProductModalRU = {
  "knisa_create_modal_product_title_create": "Добавить товар",
  "knisa_create_modal_product_title_edit": "Редактировать товар",
  "knisa_create_modal_product_externalEntry": "Внешний номер ",
  "knisa_create_modal_product_pallet": "Паллет",
  "knisa_create_modal_product_qty": "Кол-во",
  "knisa_create_modal_product_product": "Товар",
  "knisa_create_modal_product_size": "Размер",
  "knisa_create_modal_product_package": "Упаковка",
  "knisa_create_modal_product_weight": "Вес",
  "knisa_create_modal_product_price": "Цена",
  "knisa_create_modal_product_detection": "Местоположение",
  "knisa_create_modal_product_copy": "Копировать",
  "knisa_create_modal_product_includeWeight": "Включая вес",
  "knisa_create_modal_product_createButton": "Добавить товар",
}

const knisaCreateElementRU = {
  "knisa_create_element_swipe_edit": "Редактировать",
  "knisa_create_element_swipe_delete": "Удалить",
}

export const knisaTranslationRU = {
  knisa: {
    ...knisaMainRU,
    ...knisaCreatePageRU,
    ...knisaCreateTitleModalRU,
    ...knisaCreateProductModalRU,
    ...knisaCreateElementRU
  }
}