export const VARIATION_FILTER_KAV_LIST = [
  {
    id: 0,
    desc: 'הכל'
  },
  {
    id: 1,
    desc: 'קווים סגורים'
  },
  {
    id: 2,
    desc: 'קווים לא סגורים'
  }
]