import { useRef } from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import FilterGoodsItem from './filterGoodsItem'

const Wrapper = styled.div`
    /* background: red; */
    min-width: 100px;
    height: 3.5em;
    border-radius: 30px;
    position: relative;
`
const Select = styled.select`
    width: 100%;
    height: 100%;
    border-radius: 30px;
    outline: none;
    border: none;
    font-size: 1em;
    background: #eff3f4;
    padding: 0 0.7em;
    appearance: none;
    direction: rtl;
    cursor: pointer;
    color: #297f76;
`

export default function FilterGoodsList({
    fileterGoods, goodsListDefault, setGoodsList, setFilterToggleStatus,
    setFilterGoodsDefault, setFilterGoods, setFilteredExist,
    setFilterGoodsValue, fileterGoodsDefault, setFilterGoodName,
    selectStatus, setSelectStatus, neededStatus, loadAddData
}) {

    const [stat, setStat] = useState(neededStatus ? '101' : '100')

    // console.log(fileterGoodsDefault.filter(i => i.GroupArray))
    const filterProdTypes = () => {
        const filtered = goodsListDefault.filter(item => {
            return item.GroupArray?.includes(+stat)
        })
        // console.log(stat)
        if (stat === '100' || null || +'') {
            setGoodsList(goodsListDefault)
            // loadAddData()
            setFilterToggleStatus(false)
            // console.log(goodsListDefault)
        }
        else if (stat === '101') {
            // console.log(stat)

            setGoodsList([])
        }
        else {
            setGoodsList(filtered)
            setFilterGoodsDefault(filtered)
            setFilteredExist(filtered)
            if (filtered.length > 0) {
                setFilterToggleStatus(true)

            }
            // console.log(111111)
        }
        // console.log(filtered)
        // console.log('hello')
    }
    useEffect(() => {
        // console.log(stat)
        filterProdTypes()
    }, [stat])
    // useEffect(()=> {
    //     // console.log(stat)
    //     filterProdTypes()
    // }, [])

    console.log(fileterGoods)
    let firstOptionToggle = neededStatus ? <option selected={!selectStatus} value={101}>בחר קבוצה</option> : <option value={100}>כל הקבוצות</option>
    return (

        <Wrapper>

            <Select 
            disabled={selectStatus && neededStatus} 
            onChange={(e) => {
                if(setFilterGoodName){
                    setFilterGoodName(e.target.selectedOptions[0].innerText); 
                }
                setFilterGoodsValue(e.target.value); 
                setStat(e.target.value);

            }}>
                {firstOptionToggle}
                {fileterGoods.map((item) => <FilterGoodsItem key={item.GroupID}{...item} />)}
            </Select>
        </Wrapper>
    );
}