import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { CONTROL_PALLETS_CLEAR_STATE, LOAD, LOAD_END, LOGOUT, SELECT_CLIENT } from '../../../../../store/actions/actionsTypes'
import ControlButton from '../../../../generic/buttons/ControlButton'
import DatePickerModule from '../../../../generic/datePicker/datePicker'
import SearchInput from '../../../../generic/inputs/inputSearch'
import Title from '../../../../generic/Title/Title'
import TitlesList from '../../../../generic/titlesRowItem/TitlesList'
import { addIDForTheList } from '../../../../generic/utils/addUIDForTheList'
import { GET_CUSTOMER_LIST, GET_LIKUT_GROWERS, GET_PALLETS, GET_PALLETS_BY_DATE, GET_PRODUCT_VARIETY_SIZE, GET_SIZES } from '../../../../modules/API/settings'
import { ContextModal } from '../../../../modules/modal/modalContext'
import { getPallets } from '../../../../modules/requests/getPallets'
import { LINKS } from '../../../../Routs/config'
import { SelectPanelWrapper } from '../secondbar/azmanotmain/SelectBar'
import { ContentWrapper, Decorator, MainWrapper, SectionMain } from '../secondbar/mainbar2'
import ControlPalletsTitle from './ControlPalletsTitle'
import AddPalletDetailes from './editPallet/AddPalletDetailes'
import { Pallets } from '../../../../modules/API/Pallets/pallets'
import { SET_IS_OPEN } from '../../../../../store/reducers/printer'


export default function ControlPallets() {
    const dispatch = useDispatch();
    const getToken = useSelector(token => token.mainReducer.token);
    const CancelToken = axios.CancelToken.source();
    let { path } = useRouteMatch();
    const printerState = useSelector(state => state.printerReducer);
    const [modal, setModal] = useContext(ContextModal);
    const [searchPallet, setSearchPallet] = useState('')
    const [selectedDateStart, setSelectedDateStart] = useState(new Date());
    const [selectedDateEnd, setSelectedDateEnd] = useState('');
    const [palletList, setPalletList] = useState([]);
    const [palletTypeList, setPalletTypeList] = useState([]);

    const [clientList, setClientList] = useState([]);
    const [clientListDefault, setClientListDefault] = useState([]);
    const [growersList, setGrowersList] = useState([]);
    const [growersListDefault, setGrowersListDefault] = useState([]);

    const [productList, setProductList] = useState([]);
    const [productListDefault, setProductListDefault] = useState([]);
    
    const [sizeList, setSizeList] = useState([]);
    const [temp, setTemp] = useState(null);
    
    const combinePalletsByOrder = (arr) => {
        let temp =  arr.reduce((acc, cur) => {
            acc[cur.OrderID] = acc[cur.OrderID]?.concat(cur) || [].concat(cur)
            return acc
        }, {})
        
       return Object.keys(temp).map(key => {
            return {
                OrderID: Number(key),
                PalletsQty: temp[key].length,
                WeightSum: temp[key].reduce((acc, cur) => acc += cur.Weight, 0),
                OrderPallets: temp[key]
            }
        })
    }
    
    const getPallet = async (fromDate, toDate) => {
        dispatch({ type: LOAD}) 
        try {    
            let res = await axios.get(`${GET_PALLETS_BY_DATE}fromDate=${fromDate}&toDate=${toDate}`, {
                headers: {
                    'Authorization' : 'Bearer ' + getToken
                }
            })   
            setPalletList(res.data.pallets);
            let addArtAzmana = res.data.pallets.map((e, index) => {
                return {
                    ...e,
                    OrderID:  (index + 1) % 2 !== 0? 1 : 2,
                    Weight: Math.ceil(15 * Math.random())
                }
            })
            setTemp(combinePalletsByOrder(addArtAzmana))
            console.log(combinePalletsByOrder(addArtAzmana))
        } catch (err) {
            console.log(err)
            setModal({
                open: true,
                value: err.response?.data?.message,
                mood: false
            });
            setTimeout( () =>{ 
                setModal({
                    open: false,
                    value: ''
                });
            }, 3000);
            console.log(err.response.data.message)
        } finally {
            dispatch({ type: LOAD_END})
            
        }
        
    }
    const getClients = async () => {
        dispatch({ type: LOAD });
        try {
            let res = await axios.get(GET_CUSTOMER_LIST, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            const filtered = addIDForTheList(res.data.customers.filter(item => {
                return item.Active
            }))
            setClientList(filtered);
            setClientListDefault(filtered);
            console.log(filtered);
        } catch (err) {
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        } finally {
            dispatch({ type: LOAD_END });
        }
    }

    const getGrowers = async () => {
       
        try {
            let res = await axios.get(GET_LIKUT_GROWERS, {
                headers: {
                    'Authorization': 'Bearer ' + getToken
                }
            })
            const filtered = addIDForTheList(res.data.growers.filter(item => {
                return item.Active
            }))
            setGrowersList(filtered);
            setGrowersListDefault(filtered);
            console.log(filtered);
        } catch (err) {
            if (err.response?.data.message === 'jwt malformed' || err.response?.data.message === 'jwt expired') {
                dispatch({ type: LOGOUT, payload: false });
            }
        }
    }
   
    const getSizes = async () => {
        try {
            let response = await axios.get(`${GET_SIZES}`, {
                headers: {
                    'Authorization' : 'Bearer ' + getToken
                } 
            })
            let prod = addIDForTheList(response.data.sizes.filter(e => e.Active));
            setSizeList(prod);
            // setCefiSizesDefaults(prod);
        } catch (err) {
            console.log(err.response);
        }
    }
    const getProductComplete = async () => {
        try {
            let response = await axios.get(`${GET_PRODUCT_VARIETY_SIZE}`, {
                headers: {
                    'Authorization' : 'Bearer ' + getToken
                } 
            })
            let prod = addIDForTheList(response.data.result);
            let reduced = prod.map(cur => {
                cur = {
                    ...cur,
                    name: `${cur.ProductDesc?.trim() || ''} ${cur.VarietyDesc?.trim() || ''} ${cur.Description?.trim() || ''}`
                }
                return cur
            })
            // setSizeList(prod);
            setProductList(reduced);
            setProductListDefault(reduced);
            console.log(reduced)
            // setCefiSizesDefaults(prod);
        } catch (err) {
            console.log(err.response);
        }
    }
    const printPallet = async (pallet_num) => {
        if(!printerState.printerID) return dispatch({ type: SET_IS_OPEN, payload: true });
        dispatch({ type: LOAD });
        try {
            const data = {
                "ZPL_15_75": 3,
                "PalletEntryNum": +pallet_num,
                "PalletZPLFormat": +printerState.formatID
            }
            const defPrinter = printerState.printerID 
            let res = await Pallets.printPalletZPL(defPrinter, data)
            console.log(res)
            if (typeof res !== 'number') {
                let modifyed = res.split('.il').join('.il:9086');
                console.log(modifyed)
                console.log(res)
                window.open(modifyed);
            } else {
                setModal({
                    open: true,
                    value: 'הדפסת משטח בוצעה בהצלחה',
                    mood: true
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
            dispatch({ type: LOAD_END });
        } catch (err) {
            if (err?.response?.status === 404) {
                setModal({
                    open: true,
                    value: 'שגיאה הדפסת משטח',
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
                // console.log(err.response)
            }
            else {
                setModal({
                    open: true,
                    value: err?.response?.data?.message,
                    mood: false
                })
                setTimeout(() => {
                    setModal({
                        ...modal,
                        open: false
                    })
                }, 3000)
            }
            dispatch({ type: LOAD_END });
        }
    }

    const deletePallet = async (palletNum) => {
        try {
            const newArr = [...palletList]
            const found = newArr.filter(e => e.Pallet_Num !== palletNum)
            const res = await Pallets.deletePalletGo(palletNum)
            setPalletList(found)
            setModal({
                open: true,
                value: `משטח נמחק בהצלחה`,
                mood: true
            });
            setTimeout( () =>{ 
                setModal({
                    open: false,
                    value: ''
                });
            }, 3000);
        } catch {
            setModal({
                open: true,
                value: 'משטח לא נמחק',
                mood: false
            });
            setTimeout( () =>{ 
                setModal({
                    open: false,
                    value: ''
                });
            }, 3000);
        }
    } 
    const selectClientFunc = (name) => {
        dispatch({type: SELECT_CLIENT, name: name, id: clientListDefault.filter(e => e?.Customer_Name.trim() === name?.trim())[0]?.CustomerID})
    }
    const newPalletFunc = () => {
        dispatch({
            type: CONTROL_PALLETS_CLEAR_STATE
        })
    }
    const getPalletCurrent = () => getPallet(selectedDateStart, selectedDateEnd)
    useEffect(() => {
        if(selectedDateStart?.toString()?.length > 8) return
        getPalletCurrent();
        return () => {
            CancelToken.cancel("Component got unmounted");
        }
    }, [selectedDateEnd])
    
    useEffect(() => {
        getClients();
        getGrowers();
        // getProducts();
        getPallets(getToken, GET_PALLETS, setPalletTypeList, () => {return});
        getSizes();
        getProductComplete();
    }, [])

    const palletDetailesProps = {
        clientList: clientList,
        clientListDefault: clientListDefault,
        setClientList: setClientList,
        growersList: growersList,
        growersListDefault: growersListDefault,
        setGrowersList: setGrowersList,
        productList: productList,
        productListDefault: productListDefault,
        setProductList: setProductList,
        palletTypeList: palletTypeList,
        sizeList: sizeList,
        getPalletCurrent: getPalletCurrent,
        
    }
  return (
    <SectionMain>
        <Switch>
            <Route exact path={path}>
                <MainWrapper>
                    <Decorator />
                    <Title label={'רשימת תעודות משטח'} />
                    <SelectPanelWrapper>
                        <DatePickerModule
                            multyDate={true}
                            ignorBrod
                            // defEndDate={20}
                            setSelectedDateStart={setSelectedDateStart}
                            setSelectedDateEnd={setSelectedDateEnd}
                        />
                        <SearchInput input={searchPallet} setInput={setSearchPallet} />
                    </SelectPanelWrapper>
                    <ContentWrapper>
                        <TitlesList 
                            list={palletList}
                            uniqueKey={'Pallet_Num'}
                            Element={ControlPalletsTitle}
                            additionalData={{
                                selectClientFunc: selectClientFunc,
                                handleDelete: deletePallet,
                                printPallet:printPallet
                            }}
                        />
                    </ContentWrapper>
                    
                        <Link to={LINKS.mishtahim.control.create + 'new'}>
                            <ControlButton
                                lable='משטח חדש'
                                handleClick={newPalletFunc}
                            />
                        </Link>
                </MainWrapper>
            </Route>
            <Route path={LINKS.mishtahim.control.createWithID} render={() => <AddPalletDetailes {...palletDetailesProps} />} />
        </Switch>
    </SectionMain>
  )
}
