
const teudotMainRU = {
  "teudot_main_title": "Транспортные накладные",
  "teudot_main_search_placeholder": "Поиск накладной",
  "teudot_main_button_new": "Создать накладную",
  "teudot_main_element_swipe_edit": "Обновить",
}

const teudotCreatePageModalTitleRU = {
  "teudot_create_modal_title_title": "Название накладной",
  "teudot_create_modal_title_client": "Выберите клиента",
  "teudot_create_modal_title_seller": "Выберите продавца",
  "teudot_create_modal_title_teudaType": "Выберите тип накладной",
  "teudot_create_modal_title_car": "Номер машины",
}

const teudotCreatePageRU = {
  "teudot_create_page_title": "Новая накладная",
  "teudot_create_page_title_ids": "Накладная: {{id}}",
  "teudot_create_page_search_placeholder": "Поиск",
  "teudot_create_page_tabs_rest": "Инвентарь",
  "teudot_create_page_tabs_taken": "Выбрано",
  "teudot_create_page_element_swipe_take": "Добавить",
  "teudot_create_page_element_swipe_cancel": "Удалить",
}

const createProductModalRU = {
  "create_product_modal_title_new": "Новый продукт",
  "create_product_modal_title_created": "Продукт {{id}}",
  "create_product_modal_search": "Поиск",
  "create_product_modal_product": "Выберите продукт",
  "create_product_modal_size": "Выберите размер",
  "create_product_modal_grower": "Производитель",
  "create_product_modal_grower_button": "Все",
  "create_product_modal_grower_package": "Выберите упаковку",
  "create_product_modal_grower_qty": "Количество",
  "create_product_modal_calcMethod": "Метод расчета",
  "create_product_modal_weight": "Вес",
  "create_product_modal_price": "Цена",
  "create_product_modal_pallet": "Тип продукта",
  "create_product_modal_includeTareWeight": "Включить вес тары",
  "create_product_modal_remark": "Примечание",
  "create_product_modal_button_save": "Сохранить продукт",
}

export const teudotTranslationRU = {
  teudot: {
    ...teudotMainRU,
    ...teudotCreatePageModalTitleRU,
    ...teudotCreatePageRU,
    ...createProductModalRU
  }
}


