

const teudotMainAR = {
  "teudot_main_title": "تصاريح الشحن",
  "teudot_main_search_placeholder": "بحث عن تصريح",
  "teudot_main_button_new": "إضافة شحنة",
  "teudot_main_element_swipe_edit": "تعديل",
}

const teudotCreatePageModalTitleAR = {
  "teudot_create_modal_title_title": "عنوان التصريح",
  "teudot_create_modal_title_client": "اختر العميل",
  "teudot_create_modal_title_seller": "اختر البائع",
  "teudot_create_modal_title_teudaType": "اختر نوع التصريح",
  "teudot_create_modal_title_car": "رقم السيارة",
}

const teudotCreatePageAR = {
  "teudot_create_page_title": "تصريح جديد",
  "teudot_create_page_title_ids": "تصريح: {{id}}",
  "teudot_create_page_search_placeholder": "بحث",
  "teudot_create_page_tabs_rest": "المخزون",
  "teudot_create_page_tabs_taken": "تم اختياره",
  "teudot_create_page_element_swipe_take": "إضافة",
  "teudot_create_page_element_swipe_cancel": "إلغاء",
}

const createProductModalAR = {
  "create_product_modal_title_new": "جديد :منتج",
  "create_product_modal_title_created": ":منتج {{id}}",
  "create_product_modal_search": "بحث",
  "create_product_modal_product": "اختر منتج",
  "create_product_modal_size": "اختر حجم",
  "create_product_modal_grower": "المزارع",
  "create_product_modal_grower_button": "الكل",
  "create_product_modal_grower_package": "اختر عبوة",
  "create_product_modal_grower_qty": "الكمية",
  "create_product_modal_calcMethod": "طريقة الحساب",
  "create_product_modal_weight": "الوزن",
  "create_product_modal_price": "السعر",
  "create_product_modal_pallet": "نوع المنتج",
  "create_product_modal_includeTareWeight": "شمل الوزن الخالي",
  "create_product_modal_remark": "ملاحظة",
  "create_product_modal_button_save": "حفظ المنتج",
}

export const teudotTranslationAR = {
  teudot: {
    ...teudotMainAR,
    ...teudotCreatePageModalTitleAR,
    ...teudotCreatePageAR,
    ...createProductModalAR
  }
}
