import React from 'react'
import TableHead from '../../procedures/Q8/Layouts/Table/TableHead'
import TableHeadCell from '../../procedures/Q8/Layouts/Table/TableHeadCell'
import { Customer } from '../../../../../modules/API/Seller/models/tableData'

type Props = {
  list: Customer[]
}

const SellerHead = ({
  list
}: Props) => {
  return (
    <TableHead>
      {
        list.map((item, index) => (
          <TableHeadCell
            key={index}
            minWidth={index === 0 ? '200px' : '100px'}
          >
            {item.Customer_Name.trim()}
          </TableHeadCell>
        ))
      }
    </TableHead>
  )
}

export default SellerHead