import { Component } from "react"
import ArizotAccoutingMain from "../../main/second/mainbar/arizot/accounting/ArizotAccoutingMain"
import ArizotMain from "../../main/second/mainbar/arizot/arizotMain"
import PacksMovements from "../../main/second/mainbar/arizot/movements/PacksMovements"
import AzmanotReheshMain from "../../main/second/mainbar/azmanotRehesh/AzmanotReheshMain"
import ClientsBills from "../../main/second/mainbar/bills/Clients/ClientsBills"
import CustomerBills from "../../main/second/mainbar/bills/Customers/CustomerBills"
import Payments from "../../main/second/mainbar/bills/Payments/Payments"
import CefiMain from "../../main/second/mainbar/cefi/cefiMain"
import ControlPallets from "../../main/second/mainbar/controlPallets/ControlPallets"
import DriversMain from "../../main/second/mainbar/drivers/DriversMain"
import ForcastsMain from "../../main/second/mainbar/forcasts/Distribution/ForcastsMain"
import GrowerProductsMain from "../../main/second/mainbar/forcasts/Products/GrowerProductsMain"
import SupplyMain from "../../main/second/mainbar/forcasts/Supply/SupplyMain"
import KnisotMain from "../../main/second/mainbar/knisot/KnisotMain"
import KnisotExternalMain from "../../main/second/mainbar/knisotExternal/KnisotExternalMain"
import KosherTeudot from "../../main/second/mainbar/kosherTeudot/KosherTeudot"
import LikutExpressCheckPalletMain from "../../main/second/mainbar/likutExpress/checkPallet/LikutExpressCheckPalletMain"
import LikutExpress from "../../main/second/mainbar/likutExpress/likutExpressMain"
import LikutSetWorkers from "../../main/second/mainbar/likutExpress/likutWorkers/LikutSetWorkers"
import MonitoringMain from "../../main/second/mainbar/likutExpress/monitoring/MonitoringMain"
import LikutExpressPalletSection from "../../main/second/mainbar/likutExpress/palletSection/LikutExpressPalletSection"
import WorkersStatisticMain from "../../main/second/mainbar/likutExpress/workersStatistic/WorkersStatisticMain"
import WorkStatusesMain from "../../main/second/mainbar/likutExpress/workStatuses/WorkStatusesMain"
import MarketCollectMain from "../../main/second/mainbar/market/Collect/MarketCollectMain"
import MiunMain from "../../main/second/mainbar/miun/MiunMain"
import MlaiSectionMain from "../../main/second/mainbar/mlai/MlaiSectionMain"
import PalletLocationMain from "../../main/second/mainbar/palletLocation/PalletLocationMain"
import EditCustomerMain from "../../main/second/mainbar/Pallets/EditCustomer/EditCustomerMain"
import ReportPriceMain from "../../main/second/mainbar/priceReport/ReportPriceMain"
import MainQ10 from "../../main/second/mainbar/procedures/Q10/MainQ10"
import MainQ2 from "../../main/second/mainbar/procedures/Q2/MainQ2"
import MainQ4 from "../../main/second/mainbar/procedures/Q4/MainQ4"
import MainQ8 from "../../main/second/mainbar/procedures/Q8/MainQ8"
import TeudotDetailsMain from "../../main/second/mainbar/queries/TeudotDetails/TeudotDetailsMain"
import ReceiptMain from "../../main/second/mainbar/receipt/ReceiptMain"
import MainBarSecond from "../../main/second/mainbar/secondbar/mainbar2"
import DriversReportMain from "../../main/second/mainbar/trucks/DriverReports/DriversReportMain"
import TrucksMain from "../../main/second/mainbar/trucks/TrucksMainT"
import { LINKS } from "../../Routs/config"
import Index from "../../main/second/mainbar/Pallets/LikutPrahim/Index"
import OrderCount from "../../main/second/mainbar/orderDohs/count/OrderCount"
import SellerMain from "../../main/second/mainbar/seller/SellerMain"
import ProductChangesMain from "../../main/second/mainbar/products/ProductChanges/ProductChangesMain"

export type TMainCodes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15
export interface IModulesHardCodeItem {
    name: string,
    link: string,
    Component: React.FC | React.ReactNode,
}
export interface ISubModuleItem {
    name: string,
    link: string,
    Component: React.FC | React.ReactNode,
}
interface IMainHash {
    name: string,
    link: string,
    Component: any,
    subModules: any
}
export const mainModules = (mainID: TMainCodes) => {
    return mainHash[mainID]
}
const subOrder = {
    1: {
        name: 'הזמנות לקוח',
        link: LINKS.orders.clientOrders.mainUrl,
        Component: MainBarSecond
    },
    2: {
        name: 'הזמנות רכש',
        link: LINKS.orders.growerOrders.mainUrl,
        Component: AzmanotReheshMain
    },
    3: {
        name: "תחזית אספקה",
        link: LINKS.forecasts.distribution.mainURL,
        Component: ForcastsMain
    }, 
    4: {
        name: "תוכנית רכש שבועית",
        link: LINKS.forecasts.supplyWarehouse.mainURL,
        Component: SupplyMain
    },
    5: {
        name: "הגדרת פריטים לספק",
        link: LINKS.forecasts.products.mainURL,
        Component: GrowerProductsMain

    },
    6: {
        name: "סידור יום למשווק",
        link: LINKS.seller.seller.mainURL,
        Component: SellerMain

    },
    7: {
        name: "דוח חוסרים יומי",
        link: LINKS.orders.count.mainUrl,
        Component: OrderCount
    },
    8: {
        name: 'עדכון שורות הזמנות לקוח',
        link: LINKS.orders.product.mainUrl,
        Component: ProductChangesMain
    }
}
const subCefi = {

}
const subLikut = {
    1: {
        name: 'ליקוט',
        link: LINKS.likut.likutExperess.mainUrl,
        Component: LikutExpress
    },
    2: {
        name: 'ליקוט בלי הזמנה',
        link: LINKS.likut.likutWithoutOrder.mainUrl,
        Component: LikutExpressPalletSection
    },
    3: {
        name: 'ביקורת משטח',
        link: LINKS.likut.checkPallets.mainUrl,
        Component: LikutExpressCheckPalletMain
    },
    4: {
        name: 'ניהול משמרת',
        link: LINKS.likut.controlWorkers.mainUrl,
        Component: LikutSetWorkers
    },
    5: {
        name: 'בקרה',
        link: LINKS.likut.bakara.mainUrl,
        Component: MonitoringMain
    },
    6: {
        name: 'סטטוס הזמנה',
        link: LINKS.likut.statisticStatus.mainUrl,
        Component: WorkStatusesMain
    },
    7: {
        name: 'דשבורד מלקטים',
        link: LINKS.likut.gatheringStats.mainUrl,
        Component: WorkersStatisticMain
    }
}

const subArizot = {
    1: {
        name: 'הזמנה/החזרה',
        link: LINKS.arizot.arizaAhzara.mainUrl,
        Component: ArizotMain
    },
    2: {
        name: 'ספירת מלאי',
        link: LINKS.arizot.arizaMlai.mainUrl,
        Component: ArizotAccoutingMain
    },
    3: {
        name: "תנועות אריזה", 
        link: LINKS.arizot.movements.mainUrl,
        Component: PacksMovements
    }
}

const subMishloah = {

}
const subAfaca = {
    1: {
        name: 'הפצה',
        link: LINKS.afaca.trucksGathering.mainURL,
        Component: TrucksMain
    
    },
    2: {
        name: 'דוחות הפצה',
        link: LINKS.afaca.driversReport.mainURL,
        Component: DriversReportMain
    }
}
const subPrice = {

}
const subMishtahim = {
    1: {
        name: "רשימת תעודות משטח",
        link: LINKS.mishtahim.control.mainURL,
        Component: ControlPallets
    },
    2: {
        name: "מכירה במקום",
        link: LINKS.mishtahim.market.mainURL,
        Component: MarketCollectMain
    },
    3: {
        name: "עדכון איתור למשטח",
        link: LINKS.mishtahim.location.mainURL,
        Component: PalletLocationMain
    },
    4: {
        name: "עדכון יעד למשטח",
        link: LINKS.mishtahim.editCustomer.mainURL,
        Component: EditCustomerMain
    },
    5: {
        name: 'ליקט',
        link: LINKS.mishtahim.likutPrahim.mainURL,
        Component: Index
    }
}
const subDrivers = {

}
const subMiun = {

}
const subKnisot = {
    1: {
        name: "תעודות כניסה",
        link: LINKS.knisot.teudot.mainURL,
        Component: KnisotMain
    },
    2: {
        name: "כניסות חיצוניות",
        link: LINKS.knisot.external.mainURL,
        Component: KnisotExternalMain
    }
}
const Queries = {
    1 : {
        name: "שורות תעודות משלוח Q3",
        link: LINKS.queries.teudotDetails.mainURL,
        Component: TeudotDetailsMain
    },
    2 : {
        name: "סיכום תעודות משלוח Q4",
        link: LINKS.queries.q4.mainURL,
        Component: MainQ4
    },
    3 : {
        name: "סיכום תעודות כניסה Q2",
        link: LINKS.queries.q2.mainURL,
        Component: MainQ2
    },
    4 : {
        name: "יתרות אריזה/משטחים Q8",
        link: LINKS.queries.q8.mainURL,
        Component: MainQ8
    },
    5: {
        name: "סיכום הזמנות Q10",
        link: LINKS.queries.q10.mainURL,
        Component: MainQ10
    }
}
const subBills = {
    1: {
        name: 'חשבונית מגדל',
        link: LINKS.bills.clientBills.mainURL,
        Component: ClientsBills
    },
    2: {
        name: 'חשבונית לקוח',
        link: LINKS.bills.customerBills.mainURL,
        Component: CustomerBills
    },
    3: {
        name: 'תשלומים' ,
        link: LINKS.bills.payments.mainURL,
        Component: Payments
    },
    4: {
        name: 'קבלות',
        link: LINKS.bills.receipt.mainURL,
        Component: ReceiptMain
    }
}
const subMlai = {

}

const subPrahim = {

}
const mainHash: Record<number, IMainHash> = {
    1: {
        name: 'הזמנות',
        link: LINKS.orders.clientOrders.mainUrl,
        Component: MainBarSecond,
        subModules: subOrder
    },
    2: {
        name: 'תעודות משלוח למחסן',
        link: LINKS.cefi.cefi.mainURL,
        Component: CefiMain,
        subModules: subCefi
    },
    3: {
        name: 'אריזות',
        link: LINKS.arizot.arizaAhzara.mainUrl,
        Component: ArizotMain,
        subModules: subArizot
    },
    4: {
        name: 'עבודה במחסן',
        link: LINKS.likut.likutExperess.mainUrl,
        Component: LikutExpress,
        subModules: subLikut
    },
    5: {
        name: 'תעודות משלוח',
        link: LINKS.teudot.teudotMishloah.mainURL,
        Component: KosherTeudot,
        subModules: subMishloah
    },
    6: {
        name: 'הפצה',
        link: LINKS.afaca.trucksGathering.mainURL,
        Component: TrucksMain,
        subModules: subAfaca
    },
    7: {
        name: 'נתוני מכר',
        link: LINKS.prices.salesData.mainURL,
        Component: ReportPriceMain,
        subModules: subPrice
    },
    8: {
        name: 'תעודות משטח',
        link: LINKS.mishtahim.control.mainURL,
        Component: ControlPallets,
        subModules: subMishtahim
    },
    9: {
        name: 'תעודות מיון',
        link: LINKS.miun.control.mainURL,
        Component: MiunMain,
        subModules: subMiun
    },
    10: {
        name: 'נהגים',
        link: LINKS.drivers.quests.mainURL,
        Component: DriversMain,
        subModules: subDrivers
    },
    11: {
        name: "כניסה",
        link: LINKS.knisot.teudot.mainURL,
        Component: KnisotMain,
        subModules: subKnisot
    },
    12: {
        name: 'שאילתות',
        link: LINKS.queries.teudotDetails.mainURL,
        Component: TeudotDetailsMain,
        subModules: Queries
    },
    13: {
        name: "הנהלת חשבונות",
        link: LINKS.bills.clientBills.mainURL,
        Component: ClientsBills,
        subModules: subBills
    },
    14: {
        name: " ספירת מלאי ",
        link: LINKS.mlai.mlai.mainURL,
        Component: MlaiSectionMain,
        subModules: subMlai
    },
    15: {
        name: "משטחים(פרחים)",
        link: LINKS.mishtahim.likutPrahim.mainURL,
        Component: Index,
        subModules: subPrahim
    }
}


