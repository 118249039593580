import {
  SwipeableListItem,
} from "@sandstreamdev/react-swipeable-list";
import React from "react";
import styled from "styled-components";
import { ITeudotItem } from "../../../../../models/RequestsModel/teudot";
import {
  CalendatDay,
  DollarIcon,
  EditIcon,
  InvoiceIcon,
  PhoneIcon,
  PrintIcon,
  StampIcon,
  UserIcon,
} from "../../../../generic/icons/Icons";
import {
  convertToDate
} from "../../../../modules/convertDateString";
import {useHistory} from 'react-router-dom'
import { LINKS } from "../../../../Routs/config";
import { useDispatch, useSelector } from "react-redux";
import { SELECT_TEUDA_TITLE } from "../../../../../store/actions/actionsTypes";
import { useTranslation } from "react-i18next";

// interface ITeudotItemExtends extends ITeudotItem {
//   print: (CODE: number, SUBCODE: number) => void;
//   sign: (CODE: number, SUBCODE: number) => void;
//   codeInternal: number;

// }

const TeudaItem = ({
  GroupDesc, Delivery_Date, Delivery_Num, Delivery_Sub, Customer_Name,
  print, sign, isMashgiah, Mashgiah, item, isAllowToPrint=true, SellerDesc,
  sendSms, isAllowToSendSms
}) => {
  const [t] = useTranslation('teudot')
  const formatedDate = convertToDate(Delivery_Date);
  const dispatch = useDispatch()

  const handlePrint = (e) => {
    e.stopPropagation();
    print(Delivery_Num, Delivery_Sub);
  };
  const handleSign = (mashgiah) => {
    sign([{
      Delivery_Num: Delivery_Num, 
      Delivery_Sub: Delivery_Sub
    }], mashgiah)
  }
  const handleSendSms = (e) => {
    if(!isAllowToSendSms) return
    e.stopPropagation();
    sendSms({
      Delivery_Num: Delivery_Num, 
      Delivery_Sub: Delivery_Sub
    })
    
  }
  const history = useHistory()
  const selectFunc = () => {
    dispatch({type: SELECT_TEUDA_TITLE, payload: item})
    history.push(`${LINKS.teudot.teudotMishloah.createByID}${Delivery_Num}/${Delivery_Sub}`)
  }
  return (

      <MainWrapper>
      <SwipeableListItem
        swipeRight={{
          content: <SwipeContent>
            {t('teudot_main_element_swipe_edit')}
          </SwipeContent>,
          action: () => selectFunc(),
        }}
        threshold={0.3}
        >
          <PrintContainer>
            {
              isAllowToPrint && 
                <PrintContainerItem onClick={handlePrint}>
                  <PrintIcon />
                </PrintContainerItem>
            }
            
             
                <PrintContainerItem onClick={handleSendSms} disabled={!isAllowToSendSms}>
                  <PhoneIcon />
                </PrintContainerItem>
            
            {/* 
              if user - OFFICE, ADMIN, DEV, other user with code_internal = 0
              and Mashgiah(userID who made stamp) = 0 - hide icon, if Mashgiah != 0 - show disabled icon

              otherwise if user code_internal != 0 and Mashgiah = 0 - show enabled button for setting stamp
              or if Mashgiah != 0 - show disabled icon
            */}
            {
              isMashgiah ? 
                Mashgiah === 0 ?
                  <PrintContainerItem onClick={() => handleSign()}>
                    <EditIcon />
                  </PrintContainerItem> 
                    :
                    <PrintContainerItem onClick={() => handleSign(Mashgiah)}>
                      <StampIcon />
                    </PrintContainerItem> 
                      : Mashgiah !== 0 ? 
                        <PrintContainerItem disabled>
                          <StampIcon />
                        </PrintContainerItem> 
                        : ""
            }
          </PrintContainer>
          <ContentWrapper>
            <ContentRow>
              
              <RowItem>
                {formatedDate}
                <CalendatDay />
              </RowItem>
              <RowItem>
                {Delivery_Num}
                <InvoiceIcon />
              </RowItem>
            </ContentRow>
            <ContentRow>
              <RowItem>
                {Customer_Name?.trim()}
                <UserIcon />
              </RowItem>
            </ContentRow>
            {
              SellerDesc.trim() && (
                <ContentRow>
                  <RowItem>
                    {`${SellerDesc?.trim()} - משווק`}
                    <DollarIcon />
                  </RowItem>
                </ContentRow>

              )
            }
            {GroupDesc && (
              <ContentRow>
                <RowItem>
                  <KwucaBlock isTriim={GroupDesc.trim() === 'טריים'}>{GroupDesc}</KwucaBlock>
                </RowItem>
              </ContentRow>
              )}
            
          </ContentWrapper>
      </SwipeableListItem>
      </MainWrapper>
  );
};

export default TeudaItem;


const MainWrapper = styled.div`
  width: 100%;
  min-height: 5em;
  background: #fff;
  border-radius: 20px;
  display: flex;
  color: #75759e;
  padding: 0.4em 0.3em;
  box-sizing: border-box;
  box-shadow:  4px 4px 10px #c1c1c1,
             -7px -7px 10px #ffffff;
  cursor: grab;
  .swipeable-list-item {
    border-radius: 20px;
    /* min-height: 4em; */
  }
  @media screen and (max-width: 600px) {
    min-height: 5.5em;
  }
`;
const PrintContainer = styled.div`
  min-width: 4em;
  height: 100%;
  /* background: blue; */
  display: flex;
`;
const PrintContainerItem = styled.div`
  width: 100%;
  height: 100%;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  opacity: ${props => props.disabled ? 0.5 : 1};
  cursor: pointer;
  :hover i {
    ${
      props => !props.disabled ? 
      {
        color: "tomato",
        transform: "scale(1.3)"
      } : ''
    }
  }
`;
const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* background: yellow; */
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 0.2em;
  padding: 0.2em 0.5em;
`;
const ContentRow = styled.div`
  width: 100%;
  padding: 0 0.5em;
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
  /* background: purple; */
  
`;

const RowItem = styled.div`
  display: flex;
  gap: 0.4em;
`;
const KwucaBlock = styled.div`
  padding: 0.1em 0.5em;
  background: ${props => props.isTriim ? '#d3ffce' : '#faceff'} ;
  border-radius: 5px;
  transform: translateY(-0.1em);
`;

const SwipeContent = styled.div`
  background: #7ab0ab;
  height: 4em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  padding: 0 0.5em;
`
