import React from 'react'
import { ICustomerGroup } from '../../../../../../modules/API/Defaults/models/customersGroup'
import { ITableOfTableItem } from '../../../../../../modules/API/Defaults/models/tableOfTables'
import { ITransportAreaItem } from '../../../../../../modules/API/Defaults/models/transportAreas'
import styled from 'styled-components'
import SearchCustomers from '../../../forcasts/Supply/Components/SearchCustomers'
import SearchCustomerGroupList from './SearchCustomerGroupList'
import SearchBlockWithTitle from '../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import SearchTransportAreas from './SearchTransportAreas'
import SearchMarketingArea from './SearchMarketingArea'

type Props = {
  customerGroupList: ICustomerGroup[]
  marketAreaList: ITableOfTableItem[]
  transportAreas: ITransportAreaItem[]
  selectedCustomerGroup: number
  selectedMarketArea: number
  selectedTransportArea: number
  setSelectedCustomerGroup: React.Dispatch<React.SetStateAction<number>>
  setSelectedMarketArea: React.Dispatch<React.SetStateAction<number>>
  setSelectedTransportArea:React.Dispatch<React.SetStateAction<number>>
}

const ProductChangeFilterModal = ({
  customerGroupList,
  marketAreaList,
  transportAreas,
  selectedCustomerGroup,
  selectedMarketArea,
  selectedTransportArea,
  setSelectedCustomerGroup,
  setSelectedMarketArea,
  setSelectedTransportArea

}: Props) => {


  return (
    <MainWrapper>
      <FilterWrapper>
        <SearchBlockWithTitle
          title='קבוצת לקוחות'
        >
          <SearchCustomerGroupList 
            list={customerGroupList}
            defaultID={selectedCustomerGroup}
            setCustomerGroupID={setSelectedCustomerGroup}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title="קו"
        >
          <SearchTransportAreas 
            list={transportAreas}
            defaultID={selectedTransportArea}
            setTransportAreaID={setSelectedTransportArea}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title="אזור שיווק"
        >
          <SearchMarketingArea 
            list={marketAreaList}
            defaultID={selectedMarketArea}
            setMarketingAreaID={setSelectedMarketArea}
          />
        </SearchBlockWithTitle>
        
      </FilterWrapper>
    </MainWrapper>
  )
}
  const MainWrapper = styled.div`
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  `
  const FilterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  `

export default ProductChangeFilterModal