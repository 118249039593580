import React from 'react'
import styled from 'styled-components'

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children: string | React.ReactNode
  isWarrning?: boolean
}

const TextField = ({children, isWarrning, ...rest}: Props) => {
  return (
    <Text
      isWarrning={isWarrning}
      {...rest}
    >
      {children}
    </Text>
  )
}
const Text = styled.p<{isWarrning?: boolean}>`
  color: ${props => props.isWarrning ? 'tomato' : '#9E9F9F'};

`
export default TextField