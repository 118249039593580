import React, { useMemo, useState } from 'react'
import { IProductCustomerItem } from '../../../../../../modules/API/Orders/models/detailsProductCustomer'
import styled from 'styled-components'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { AppleIcon, PackageIcon } from '../../../../../../generic/icons/Icons'
import ProductImage from '../../../queries/TeudotDetails/Components/ProductImage'
import ProductDetailsItem from './ProductDetailsItem'
import useLongPress from '../../../../../../generic/hooks/useLongPress'
import SearchInput from '../../../../../../generic/inputs/inputSearch'
import { vibrationFunc } from '../../../../../../modules/vibrationModule'

interface Props extends IProductCustomerItem {
  handleProductModal: (item: IProductCustomerItem, recID?: number) => void
  isMobile?: boolean
  item: IProductCustomerItem
}

const ProductItem = ({
    Product, OrderDetails, handleProductModal, isMobile, item
}: Props) => {
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const [input, setInput] = useState('')

  const handleControlDetails = () => {
    setIsOpenDetails(!isOpenDetails)
  }
  const handleProduct = (e: React.MouseEvent) => {
    e.stopPropagation()
    vibrationFunc()
    handleProductModal(item)
  }
  const handleNestedProduct = (recID: number) => {
    handleProductModal(item, recID)
    vibrationFunc()
  }
  const summary = useMemo(() => {
    return OrderDetails.reduce((acc, item) => {
      return acc + item.OrderPacks
    }, 0)
  }, [OrderDetails])

  const longPress = useLongPress(handleProduct, handleControlDetails)

  const render = useMemo(() => {
    if(!OrderDetails) return []
    return OrderDetails.filter(item => 
      item.Customer_Name.trim().toLocaleLowerCase().includes(input.toLocaleLowerCase())
    )
  }, [OrderDetails, input])
  return (
    <MainWrapper
      onClick={isMobile ? undefined : handleControlDetails}
      onMouseDown={isMobile ? longPress.onMouseDown : undefined}
      onTouchStart={isMobile ? longPress.onTouchStart : undefined}
      onMouseUp={isMobile ? longPress.onMouseUp : undefined}
      onMouseLeave={isMobile ? longPress.onMouseLeave : undefined}
      onTouchEnd={isMobile ? longPress.onTouchEnd : undefined}
    >
      <TitleLayout
        
      >
        <ImageWrapper
          onClick={handleProduct}
        >
          <ProductImage 
            id={Product.GeneralProductID}
          />
        </ImageWrapper>
        <TitleRow>
          <TitleRowItem 
            name={`${Product.ProductDesc.trim()} ${Product.VarietyDesc.trim()} ${Product.SizeID}`}
            icon={<AppleIcon />}
          />
        </TitleRow>
        <TitleRow>
          <TitleRowItem 
            name={` במלאי: ${Product.MlaiPacks}`}
            icon={<PackageIcon />}
            iconColor={Product.MlaiPacks < 0 ? 'tomato' : ""}
          />
          <TitleRowItem 
            name={summary}
            icon={<PackageIcon />}
          />
        </TitleRow>
      </TitleLayout>
      {
        isOpenDetails && OrderDetails.length > 0 && (
          <MainWrapper
            isPadding
            onClick={e => e.stopPropagation()}
          >
            {
              OrderDetails.length > 5 && (
                <SearchInput 
                value={'חיפוש לקוח'}
                input={input}
                setInput={setInput}
                />
              )

            }
            {
              render.map(item => (
                <ProductDetailsItem 
                  key={item.RecID}
                  handleNestedProduct={handleNestedProduct}
                  {...item}
                />
              ))
            }
          </MainWrapper>
        )
      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div<{isPadding?: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  background: rgba(129,180,175, 0.1);
  border-radius: 20px;
  padding: ${props => props.isPadding ? '0.5em' : '0'};
  cursor: pointer;
`
const ImageWrapper = styled.div`
  position: absolute;
  left: 1em;
  width: 4em;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
`
export default ProductItem