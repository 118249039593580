import React, {useMemo} from 'react'
import { IReportQ10Meta } from '../../../../../../modules/API/Reports/models/q10'
import TableHead from '../../Q8/Layouts/Table/TableHead'
import TableHeadCell from '../../Q8/Layouts/Table/TableHeadCell'

type Props = {
  data: IReportQ10Meta
}

const HeadQ10 = ({
  data
}: Props) => {
  const formatedHead = useMemo(() => {
    return Object.entries(data).sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) => value).filter(item => item.trim() !== "")
  }, [data])
  console.log(formatedHead)
  return (
    <TableHead>
        {
          formatedHead.map((item, index) => 
              <TableHeadCell
                key={index}
              >
                {item}
              </TableHeadCell>
          )
        }
    </TableHead>
  )
}

export default HeadQ10