import React, { useCallback, useMemo, useState } from 'react'
import TableRow from '../../procedures/Q8/Layouts/Table/TableRow'
import TableBodyCell from '../../procedures/Q8/Layouts/Table/TableBodyCell'
import TableCellInput from './TableCellInput'
import { GrowerDetail, ISellerTableProductItem } from '../../../../../modules/API/Seller/models/tableData'
import styled from 'styled-components'
import { CaretDownIcon } from '../../../../../generic/icons/Icons'
import { ISellerChangeOrderProps } from '../types/handlerChangeOrder'
import SellerBodyBlockElement from './SellerBodyBlockElement'

interface Props extends ISellerTableProductItem {
  handleChangeOrder: (props: ISellerChangeOrderProps) => void
}

const SellerBodyBlock = ({
  id, 
  Growers, 
  Customers, 
  Product,
  handleChangeOrder
}: Props) => {
  const [isOpenBlock, setIsOpenBlock] = useState(false)



  const handleBlockClick = () => {
    setIsOpenBlock(!isOpenBlock)
  }

  const handleTopOrderChange = (e: React.ChangeEvent<HTMLInputElement>, customer: number) => {
    handleChangeOrder({
      id: id || '',
      qty: +e.target.value,
      customerTopID: customer
    })
  }

  const handleInnerOrderChange = (e: React.ChangeEvent<HTMLInputElement>, grower: number, customer: number) => {
    handleChangeOrder({
      id: id || '',
      qty: +e.target.value,
      growerID: grower,
      customerInnerID: customer
    })
  }

  const handleInputForecastChange = (e: React.ChangeEvent<HTMLInputElement>, growerID: number) => {
    handleChangeOrder({
      id: id || '',
      growerID: growerID,
      qty: +e.target.value,
      isForecast: true
    })
  }

  const culcTotalByTopCustomers = useMemo(() => {
    return Customers.reduce((acc, cur) => {
      return acc + cur.OrderDetail.Order_Pallets
    }, 0)
  }, [Customers])

  const culcTotalByGrowers = useCallback((grower: GrowerDetail) => {
    return grower.Customers.reduce((acc, cur) => {
      return acc + cur.OrderDetail.Order_Pallets
    }, 0)
  }, [])

  const culcTotalTahazit = useMemo(() => {
    return Growers.reduce((acc, cur) => {
      return acc + cur.Forecast.ForecastPallets
    }, 0)
  }, [Growers])
  
  const color = 
  culcTotalByTopCustomers === 0 && culcTotalTahazit === 0 ? "#E9F3F2" :
  culcTotalByTopCustomers - culcTotalTahazit === 0 ? ""
    :  culcTotalTahazit - culcTotalByTopCustomers < 0 ? '#B4AF81'
    : '#B48186' 
  
  return (
    <React.Fragment>
      <TableRow>
        <TableBodyCell
          onClick={handleBlockClick}
          background='#E9F3F2'
        >
          <IconWrapper
            isRotated={isOpenBlock}
          >
            <CaretDownIcon />
          </IconWrapper>
          {`${Product.ProductDesc.trim()} ${Product.VarietyDesc.trim()} ${Product.SizeID.trim()}`}
        </TableBodyCell>
        <TableBodyCell
          background='#E9F3F2'

        >
          {culcTotalTahazit}
        </TableBodyCell>
        <TableBodyCell
          background={color}

        >
          {culcTotalByTopCustomers}
        </TableBodyCell>
        {
          Customers.map((customer, index) => (
            <SellerBodyBlockElement
              Growers={Growers}
              {...customer}
              index={index}
              handleTopOrderChange={handleTopOrderChange}
            />
            // <TableBodyCell
            //   key={index}
            //   background='#E9F3F2'
            // >
            //   <IconWrapper
            //     transparant
            //   >
            //     {culcGivenCustomerPalletByGrowers(index)}
            //   </IconWrapper>
            //   <TableCellInput
            //     value={customer.OrderDetail.Order_Pallets}
            //     onChange={(e) => handleTopOrderChange(e, customer.Customer.CustomerID)}
                
            //   />
            // </TableBodyCell>
          ))
        }
      </TableRow>
      {
        isOpenBlock &&
        Growers.map((grower) => (
          <TableRow
            key={grower.Grower.GrowerID}
          >
            <TableBodyCell>
              {grower.Grower.GrowerName}
            </TableBodyCell>
            <TableBodyCell>
              <TableCellInput 
                value={grower.Forecast.ForecastPallets}
                onChange={(e) => 
                  handleInputForecastChange(e, grower.Grower.GrowerID)
                }
              />
            </TableBodyCell>
            <TableBodyCell>
              {culcTotalByGrowers(grower)}
            </TableBodyCell>
            {
              grower.Customers.map((customer, index) => (
                <TableBodyCell
                  key={index}
                >
                  <TableCellInput 
                    value={customer.OrderDetail.Order_Pallets}
                    onChange={(e) => handleInnerOrderChange(e, grower.Grower.GrowerID, customer.Customer.CustomerID)}
                  />
                </TableBodyCell>
              ))
            }
          </TableRow>
        ))
      }
    </React.Fragment>
  )
}

const IconWrapper = styled.div<{isRotated?: boolean, transparant?: boolean}>`
  position: absolute;
  left: 0.3em;
  top: 0.35em;
  background-color: ${props => props.transparant ? "transparent" : '#E9F3F2'} ;
  padding: 0 0.2em;
  i {
    transform: ${props => props.isRotated ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.3s;
    font-size: 1.1em;
  }
`
export default SellerBodyBlock