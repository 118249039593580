export interface IPackPalletSummaryReqestProps {
  // EntryNum=2&Delivery_Num=3&Delivery_Sub=4&OrderID=1
  type: 'entry' | 'delivery' | 'order',
  value: string | number
}

export const TYPES = {
  entry: 'EntryNum=',
  delivery: 'Delivery_Num=',
  order: 'OrderID='
}

export interface IPackPalletSummaryItem {
    Packages: IPackSummaryItem[],
    Pallets: IPalletSummaryItem[]
}

export interface IPackSummaryItem {
    PackID: number
    Pack_Name: string
    Quantity: number
}

export interface IPalletSummaryItem {
    Pallete_Desc: string
    Pallete_Type: number
    Quantity: number
}
