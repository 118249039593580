import { useEffect, useState, useContext, useMemo } from 'react'
import CloseModalAzmanot from './buttons/closeModalAzmanot'
import SaveAzmanotBtn from './buttons/saveAzmanotBtn'
import ModalEditGoodsList from './editazmanot/goodslistEdit'
import { useDispatch, useSelector } from 'react-redux'
import FilterGoodsList from './filterGoods/filterGoodsList'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { convertToDate } from '../../../../../modules/convertDateString'
import styled from 'styled-components'
import { format } from 'date-fns'
import { SelectPanelWrapper } from '../azmanotmain/SelectBar'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { ControlPanelWrapper } from './modalAzmanot'
import { BasketIcon, CloseIcon, ErrorIcon } from '../../../../../generic/icons/Icons'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { UPDATE_ORDER_HISTORY } from '../../../../../modules/API/settings'
import DetailesModal from './editazmanot/DetailesModal'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'
import { useHistory, useParams } from 'react-router-dom'
import { Orders } from '../../../../../modules/API/Orders/orders'
import { LINKS } from '../../../../../Routs/config'
import { addIDForTheList } from '../../../../../generic/utils/addUIDForTheList'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'


const MainSection = styled.div`
position: fixed;
    left: 0;
    top: 0;
    background: #e9f0f0;
    padding: 0.5em;
    width: 100%;
    height: 100%;
    z-index: 2;
    @media screen and (max-width: 450px) {
        padding: 0;
    }
`
const InnerWrapper = styled.div`
    position: relative;
    max-width: 1000px;
    height: 100%;
    background: #e9f3f2;
    margin: 0 auto;
    padding: 0 0.5em;
    display: flex;
    flex-flow: column;
    gap: 0.1em;
    overflow: hidden;
    -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
    box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
    @media screen and (max-width: 450px) {
        padding: 1em 0.5em;
    }
`
const SelectWrapper = styled(SelectPanelWrapper)`

`
const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 0.5em;
    overflow: hidden;
    transform: translateY(-0.5em);

`
const DetalisPanel = styled(SelectPanelWrapper)`
    max-width: 89%;
    border-radius: 0 0 30px 30px;
    
`
const WrapperGoodType = styled.div`
    /* background: red; */
    min-width: 100px;
    height: 3.5em;
    border-radius: 30px;
    position: relative;
    background: #eff3f4;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #297f76;
`
const Button = styled.button`
    z-index: 3;
    background: none;
    outline: none;
    border: none;
    background: #eff3f4;
    /* padding: 0.45em 0.5em; */
    border-radius: 50%;
    cursor: pointer;
    color: #297f76;
    min-width: 4em;
    max-height: 4em;
    min-height: 4em;
    
`
const DeleteBtn = styled(Button)`
    /* background: #fff; */
    border: none;
    color: #5a5a5a;
    height: 100%;
    width: 2em;
    margin-left: 0;
    position: absolute;
    /* background: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    :focus, :active{
        outline: none;
    }
`
const BrodedFilter = styled.div`
    min-height: 100%;
    background: none;
    outline: none;
    border: 2px solid #80e081;
    width: 70px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
    color: #80e081;
`
const ButtonWrapper = styled.div`
    position: relative;
`
const Counter = styled.div`
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    padding: 0.2em;
    background: #80e081;
    color: #fff;
    border-radius: .5em;
`
// import { SwipeableList, SwipeableListItem } from '@sandstreamdev/react-swipeable-list';
// import '@sandstreamdev/react-swipeable-list/dist/styles.css';


export default function ModalEditAzmana() {
    const axios = require('axios')
    const user = useSelector(token => token.mainReducer.user);
    const defaults = useSelector(e => e.defaults?.defaults);
    const clienthash = useSelector(e => e.defaults?.clientList?.hashTable);
    const history = useHistory();
    
    // NEW
    const {id} = useParams()
    const packegeList = useSelector(packages => packages?.defaults?.packageList);
    
    // TODO: realise for what is this
    const [productEditList, setProdactEditList] = useState([])
    const [fileterGoodsDefault, setFilterGoodsDefault] = useState([])
    const [editGoodsDefault, setEditGoodsDefault] = useState([])
    const [editGoods, setEditGoods] = useState([])
    const [elementAzmanot, setElementAzmanot] = useState({})
    const getMlai = selectBrodMigdal(defaults, 546, 'Logical_Value');
    const getMhir = selectBrodMigdal(defaults, 547, 'Logical_Value');
    const {
        data: productGroupList
    } = useDataFetcher(
        Defaults.getProductGroup, "data"
    )
    // ! NEW


    const getTimeBrod = selectBrodMigdal(defaults, 342, 'Time_Value');
    const isEditArizot = selectBrodMigdal(defaults, 584, 'Logical_Value');
    // const neededStatusPritim = selectBrodMigdal(defaults, 549, 'Logical_Value');
    const neededStatusPritim = clienthash[elementAzmanot.CustomerID]?.NeedGrowthGroup !== 0;
    const [imputGoods, setInputGoods] = useState('');
    const [productNonOrder, setProductNonOrder] = useState([])
    const [modal, setModal] = useContext(ContextModal)
    const [statusFilter, setStatisFilter] = useState(true)
    const [filteredExist, setFilteredExist] = useState([])
    const currentTime = format(new Date(), "HHmm00");
    const [filterGoodsValue, setFilterGoodsValue] = useState(null)

    const [filterToggleStatus, setFilterToggleStatus] = useState(false)
    const dispatch = useDispatch();

    const { Status, Remarks, OrderID, Delivery_Date, CustomerID } = elementAzmanot
    
    const showEditAzmana = () => {
        //clear all arrays who collected preveose data, before open new one, that needs for keep uniq empty fields
        history.push(LINKS.orders.clientOrders.mainUrl)
    }
    
    // NEW
    const insertRecomenInArr = (arr, recArr) => {
        for (let i in arr) {
            for (let j in recArr) {
                if (arr[i].ProductID === recArr[j].ProductID && arr[i].VarietyID === recArr[j].VarietyID) {
                    arr[i].RecommendQty = recArr[j]
                }
            }
        }
        return arr
    }
  
    const testAdd = () => { // sort azmanot by packs value
        let newArrAdded = []
        editGoods.forEach(element => {
            for (let i = 0; i < productEditList.length; i++) {
                console.log(element)
                if (productEditList[i].desc === element.desc) {
                    element.packs = productEditList[i].packs
                }
                if (element.packs === undefined) {
                    element.packs = '0'
                }
            }
            newArrAdded.push(element)
        });

        setEditGoods(newArrAdded)
    }

    useEffect(() => { 
        testAdd() 
    }, [elementAzmanot])

    useEffect(() => {
        if(!id) return
        const getOrder = async (id) => {
            dispatch({ type: LOAD });
            try {
                let res = await Orders.getOrder(id);
                const title = res.orderTitle
                setElementAzmanot(res.orderTitle)
                const formatedOrder =  addIDForTheList(title.Status === 0 ? res.recommendations.concat(res.orderDetails) : res.orderDetails)
                
                const dataWithRecs = insertRecomenInArr(formatedOrder, res.recomWithQtyBySales).sort((a,b) => (b.OrderPacks || 0) - (a.OrderPacks || 0))
               
                const filtered = (dataWithRecs).filter(item => {
                    return item.GroupArray.includes(title.GroupID)
                })
                const isDividedByKwucot = clienthash[res.orderTitle.CustomerID]?.NeedGrowthGroup !== 0
                if (isDividedByKwucot) {
                    setEditGoodsDefault(filtered)
                    setEditGoods(filtered)
    
                } else {
                    setEditGoodsDefault(dataWithRecs)
                    setEditGoods(dataWithRecs)
    
                }
    
            } catch (err) {
    
               
            } finally {
            dispatch({ type: LOAD_END });
    
            }
        }
        // TODO: add groupID
        getOrder(id)

    }, [id])

    // ! NEW

    const updateLog = async (data) => {
        try {
            let res = await axios({
                method: 'patch',
                url: UPDATE_ORDER_HISTORY,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {
                    ...data
                }
            })
        } catch (err) {
            console.log(err)
        }
    }

    // <--     collect and fetch data for edit azmana

    const editAzmana = async () => {
        dispatch({ type: LOAD })
        const reqData = {
            "Remark": Remarks.trim(),
            "Products": productNonOrder,
            "Status": Status
        }
        try {
            let res = await Orders.editOrder(OrderID, reqData);
            let curData = {
                orderID: OrderID,
                user: user,
                data: {
                    date: new Date(), // .toLocaleString() - shows current date / time
                    product: productNonOrder
                },
            }
            updateLog(curData);
            setModal({
                open: true,
                value: res.message,
                mood: true
            })
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'

                })
            }, 3000)
            history.push(LINKS.orders.clientOrders.mainUrl);
        } catch (err) {
            setModal({
                open: true,
                value: err.response.data.message,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '2'

                })
            }, 3000)
        } finally {
            dispatch({ type: LOAD_END })
        }
    }

    // filter by name
    const filterGoods = async (imputGoods) => {
        if (statusFilter) {
            const filtered = editGoodsDefault.filter(goods => {
                return goods.ProductDesc.toLowerCase().includes(imputGoods.toLowerCase())
            })
            setInputGoods(imputGoods);
            setEditGoods(filtered);
        }
        if (!statusFilter) {
            const filtered = filteredExist.filter(goods => {
                return goods.ProductDesc.toLowerCase().includes(imputGoods.toLowerCase())
            })
            setInputGoods(imputGoods);
            setEditGoods(filtered);
        }
    }

    const filterExist = () => {
        const filtered = editGoods.filter(goods => {
            if (goods.Packs > 0 || goods.OrderPacks) {
                return goods.Packs > 0 || goods.OrderPacks > 0
            }
        })
        if (filtered.length > 0) {
            setEditGoods(filtered);
            setStatisFilter(false)
            setFilteredExist(filtered)
        }
    }
    const getGoodPrice = async (prodID, varietyID, sizeID, packID, id, item) => {
        try {
            let res = await Orders.getGoodPrice(Delivery_Date, CustomerID, prodID, varietyID, sizeID, packID);
            let tempDef = [...editGoodsDefault]
            let temp = [...editGoods]
            let editedDef = tempDef.map((item) => {
                if (item.id === id) {
                    item.Price = res[0].CustPrice
                    item.SalePrice = res[0].CustPrice
                    item.MinPrice = res[0].MinimumPrice
                    item.isPriced = true
                }
                return item
            })
            setEditGoodsDefault(editedDef)

            let edited = temp.map((item) => {
                if (item.id === id) {
                    item.Price = res[0].CustPrice
                    item.SalePrice = res[0].CustPrice
                    item.MinPrice = res[0].MinimumPrice
                    item.isPriced = true
                }
                return item
            })
            setEditGoods(edited)
        } catch (err) { }
    }
    console.log(editGoods)
    // merge keys of Fetched data and setproduct data, merge orderPacks with packs keytypes. Collect new sorted data
    const filterExistDef = async () => {
        let newArr = [...editGoodsDefault]
        for (let i = 0; i < editGoodsDefault.length; i++) {
            for (let j = 0; j < productNonOrder.length; j++) {
                if (productNonOrder[j].id === editGoodsDefault[i].id) {
                    newArr[i].Packs = productNonOrder[j].Packs
                    newArr[i].OrderPacks = productNonOrder[j].Packs
                    newArr[i].Price = productNonOrder[j].Price
                    newArr[i].Mlai = productNonOrder[j].Mlai
                }
            }
        }
        setEditGoodsDefault(newArr)
    }

    useEffect(() => { filterExistDef() }, [productNonOrder])

    const filterClose = async () => {
        const sorted = editGoodsDefault.sort(function (a, b) {
            return a.Packs - b.Packs
        })
        const sortedFiltered = fileterGoodsDefault.sort(function (a, b) {
            return a.Packs - b.Packs
        })
        const sortedReverse = sorted.reverse()
        const sortedFilteredReverse = sortedFiltered.reverse()
        // check filter status and set exist values
        if (!statusFilter && !filterToggleStatus) {
            setEditGoods(sortedReverse)
            setStatisFilter(true)
        }
        if (!statusFilter && filterToggleStatus) {
            setEditGoods(sortedFilteredReverse)
            setStatisFilter(true)
        }
    }

    const filterProblemGoods = () => {
        const filtered = editGoodsDefault.filter(item =>
            item.OrderPacks !== item.SupplyQty || item.ChangeReason !== 0
        )
        setStatisFilter(false);
        setEditGoods(filtered);
    }

    const filterClear = async () => {
        setInputGoods('')
        if (!statusFilter) {
            setEditGoods(filteredExist)
        }
        // check filter status and set exist values
        if (statusFilter && !filterToggleStatus) {
            setEditGoods(editGoodsDefault)
        }
        if (statusFilter && filterToggleStatus) {
            setEditGoods(fileterGoodsDefault)
        }
    }

    const countOrder = useMemo(() => {
        if(editGoods.length === 0 || !editGoods )  return 0
        const merged = editGoods.map((item) => {
            const found = productNonOrder.find((prod) => prod.id === item.id)
            if (found) {
                return {
                    ...item,
                    ...found
                }
            }
            return item
        })
        console.log(productNonOrder)
       return merged.reduce((acc, item) => {
            return acc + ((item?.OrderPacks || 0) || (item?.Packs || 0))
       }, 0)
    }, [editGoods, productNonOrder])
    
    let filterToggle = neededStatusPritim
        ? <WrapperGoodType>{elementAzmanot?.GroupDesc?.trim()}</WrapperGoodType>
        : <FilterGoodsList
            fileterGoods={productGroupList?.productsGroups || []}
            setFilterGoodsValue={setFilterGoodsValue}
            // filterProdTypes={filterProdTypes}
            goodsListDefault={editGoodsDefault}
            setGoodsList={setEditGoods}
            setFilterToggleStatus={setFilterToggleStatus}
            setFilterGoodsDefault={setFilterGoodsDefault}
            setFilteredExist={setFilteredExist}


        />
    let trimmed = elementAzmanot?.Customer_Name ? elementAzmanot?.Customer_Name.trim() : ''
    let convertedDate = elementAzmanot?.Delivery_Date ? convertToDate(elementAzmanot.Delivery_Date) : 'no-data'
    let showBtnClearInput = imputGoods.length > 0 ? <DeleteBtn onClick={() => { filterClear() }}><i style={{ zIndex: '2' }} className="fas fa-times"></i></DeleteBtn> : '';
    
    let disabelSave = 
        Status !== 0
        || (elementAzmanot?.To_PickupTime !== '000000' && +elementAzmanot?.To_PickupTime < +currentTime)
        || (elementAzmanot?.To_PickupTime === '000000' && +getTimeBrod < +currentTime) ? true : false;
    
    let checkButtoFilter = statusFilter ?
    disabelSave ?
        <Button onClick={() => { filterProblemGoods() }}>
            <ErrorIcon />
        </Button>
        :
        <Button onClick={() => { filterExist() }}>
            <BasketIcon />
        </Button>
    : <Button onClick={() => { filterClose() }}>
        <CloseIcon />
    </Button>;

    const [dataForDetailes, setDataForDetailes] = useState(null);
    const [isOpenDetailes, setIsOpenDetailes] = useState(false);

    const showDetailes = (disabelSave, ChangeReason) => {
        if (disabelSave && ChangeReason === 5) {
            setIsOpenDetailes(!isOpenDetailes)
        }
    }

    return (
        <MainSection >
            <InnerWrapper >
                <DetailesModal
                    open={isOpenDetailes}
                    close={() => { setIsOpenDetailes(false) }}
                    data={dataForDetailes?.data}
                    title={dataForDetailes?.title}
                />
                <SelectWrapper >
                    <SearchInput value={convertedDate} disabled={true} iconHide />
                    <SearchInput value={trimmed} disabled={true} iconHide />
                </SelectWrapper>
                <ContentWrapper >
                    <DetalisPanel >
                        {showBtnClearInput}
                        <ButtonWrapper>
                        <Counter>
                            {countOrder}
                        </Counter>
                            {checkButtoFilter}
                        </ButtonWrapper>
                        {filterToggle}
                        <SearchInput input={imputGoods} setInput={filterGoods} />
                    </DetalisPanel>
                    <ModalEditGoodsList
                        productNonOrder={productNonOrder}
                        setProductNonOrder={setProductNonOrder}
                        setEditGoods={setEditGoods}
                        editGoods={editGoods}
                        productEditList={productEditList}
                        packegeList={packegeList}
                        setProdactEditList={setProdactEditList}
                        disabelSave={disabelSave}
                        getMlai={getMlai}
                        getMhir={getMhir}
                        showDetailes={showDetailes}
                        setDataForDetailes={setDataForDetailes}
                        isEditArizot={isEditArizot}
                        date={elementAzmanot.Delivery_Date}
                        getGoodPrice={getGoodPrice}
                    />
                </ContentWrapper>
                <ControlPanelWrapper >
                    <CloseModalAzmanot closeModalAzmanot={showEditAzmana} setStatisFilter={setStatisFilter} />
                    <SaveAzmanotBtn createAzmana={editAzmana} setStatisFilter={setStatisFilter} disabelSave={disabelSave} />
                </ControlPanelWrapper>
            </InnerWrapper>
        </MainSection>
    );
}