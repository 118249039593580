import React from 'react'
import { IEntrie } from '../../../../../modules/API/Knisot/models/entrie'
import TitleLayout from '../../kosherTeudot/generic/TitleLayout'
import styled from 'styled-components'
import TitleRow from '../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../kosherTeudot/generic/TitleRowItem'
import { BarCodeIcon, CalendatDay, EditIcon, InvoiceIcon, PrintIcon, TimeIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { stringToTime } from '../generic/helpers/convert'
import { convertToDate } from '../../../../../modules/convertDateString'
import { useHistory } from 'react-router-dom'
import { LINKS } from '../../../../../Routs/config'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeContent from './SwipeContent'
import { useTranslation } from 'react-i18next'

interface IEntrieItem extends IEntrie {
    handlePrintKnisa: (entryNum: number | string) => void
}

const EntrieItem = ({ 
    EntryNum, OrderNum, 
    EntryTime, EntryDate, 
    GrowerName, Reference,
    handlePrintKnisa
}: IEntrieItem) => {
    const [t] = useTranslation('knisa')
    const history = useHistory()

    const formatedTime = stringToTime(EntryTime)
    const formatedDate = convertToDate(EntryDate)

    const handleEdit = () => {
        history.push(`${LINKS.knisot.teudot.editByID}${EntryNum}`)
    }
    const handlePrint = () => {
        handlePrintKnisa(EntryNum)
    }
  return (
      
      
      <TitleLayout isDraggable>
        <SwipeableListItem
            swipeRight={{
                content: <SwipeContent text={t("knisa_create_element_swipe_edit")}  />,
                action: handleEdit
            }}
            threshold={0.3}
        >
        <MainWrapper>
            <SideWrapper
                onClick={handlePrint}
            >
                <PrintIcon />
            </SideWrapper>
            <BodyTitle>
                <TitleRow>
                    
                    <TitleRowItem 
                        name={Reference.toString()}
                        icon={<InvoiceIcon />}
                        />
                    <TitleRowItem 
                        name={EntryNum.toString()}
                        icon={<BarCodeIcon />}
                        />

                </TitleRow>
                <TitleRow>
                    <TitleRowItem 
                        name={formatedTime}
                        icon={<TimeIcon />}
                        />
                    <TitleRowItem 
                        name={formatedDate}
                        icon={<CalendatDay />}
                        />

                </TitleRow>
                <TitleRow>
                    <TitleRowItem 
                        name={GrowerName.trim()}
                        icon={<UserIcon />}
                        />
                </TitleRow>

            </BodyTitle>
            
        </MainWrapper>
    </SwipeableListItem>
    </TitleLayout>
  )
}
const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    /* background: red; */
`
const ControlWrapper = styled.div`
    min-height: 100%;
    min-width: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    /* background: red; */
    color: #80e081;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
        transform: scale(1.2);
        color: tomato;
    }
`
const BodyTitle = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 0.5em;

`
const SideWrapper = styled.div`
    position: absolute;
    width: 4em;
    height: 4em;
    left: 0.5em;
    top: calc(50% - 2em);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: red; */
    transition: 0.3s ease-in-out;
    color: #297F76;
    cursor: pointer;
    :hover {
        color: tomato;
    }
`
export default EntrieItem