import React from 'react'
import styled from 'styled-components'

type Props = {
    children: React.ReactNode | React.ReactNode[];
    onClick?: () => void;
    minWidth?: string;
}

const TableHeadCell = ({
    children, onClick, minWidth
}: Props) => {
  return (
    <TableHeadCellStyle
      isClickAllow={!!onClick}
      onClick={onClick}
      minWidth={minWidth}
    >
        {children}
    </TableHeadCellStyle>
  )
}
const TableHeadCellStyle = styled.th<{isClickAllow?: boolean, minWidth?: string}>`
    min-width: ${props => props.minWidth ? props.minWidth : '60px'};
    height: 1em;
    border-radius: 15px 15px 0 0;
    padding: 0.2em;
    background: #81B4AF;
    color: #fff;
    /* text-align: right; */
    white-space: nowrap;
    cursor: ${props => props.isClickAllow ? 'pointer' : 'default'};
`
export default TableHeadCell