import { requests } from "../config";
import { GET_CEFI_PRODUCTS_ADDSELF, GET_CEFI_VARIETIES, GET_CUSTOMER_GROUP, GET_DELIVERYCODES, GET_PRODUCT_VARIETY_SIZE } from "../settings";
import { ICustomerPutData } from "./models/customers";
import { IPrinterData } from "./models/printSticker";
import { IProductGroupNodeResponse } from "./models/productGroup";
import { ISellerResponse } from "./models/seller";
import { ITeudotTypeItem } from "./models/teudotTypes";

export const Defaults = {
    getProductListNode: (data: string) => requests.get('product', 'node'),
    getSellerList: (data: string): Promise<ISellerResponse> => requests.get('seller', 'node'),
    getDataFromTableOfTables: (id: number | string) => requests.get(`table?MainCode=${id}`, 'go'),
    getCarList: () => requests.get('car', 'node'),
    getClientList: (data?: string) => requests.get('/client/pairing', 'node'),
    getClientsGo: (data?: string) => requests.get(`client${data || ''}`, 'go'),
    getAvailableModules: () => requests.get('module', 'go'),
    getStandartTableItems: (id: number | string) => requests.get(`table/standart?MainCode=${id}`, 'go'),
    getDeliveryCodeList: () => requests.get(GET_DELIVERYCODES, 'node'),
    getCustomersGroup: () => requests.get(GET_CUSTOMER_GROUP, 'node'),
    getPalletTypes: (data?: string) => requests.get(`/pallet`, 'node'),
    getGrowerList: (data: string) => requests.get(`grower`, 'node'),
    getFullProductsNameList: (data: string) => requests.get(GET_PRODUCT_VARIETY_SIZE, 'node'),
    getProductsList: (data: string) => requests.get(GET_CEFI_PRODUCTS_ADDSELF, 'node'),
    getVarietyByProductID: (productID: string | number) => requests.get(`${GET_CEFI_VARIETIES}/${productID}`, 'node'),
    getWarhouseList: (data: string) => requests.get('tbl/Tbl_Warehouse', 'go'),
    getProductsWithVariety: (data: string) => requests.get('product/with_varieties', 'go'),
    getDefaults: (data: string) => requests.get('defaults', 'node'),
    getCustomers: (data: string) => requests.get('customer', 'node'),
    editCustomer: (id: number | string, data: ICustomerPutData) => requests.put(`customer/${id}`, 'go', data),
    getWorkers: (data: string) => requests.get('worker', 'node'),
    getPackages: (data: string) => requests.get('package', 'node'),
    getTransportAreas: (data: string) => requests.get('client/transportareas', 'node'),
    getBankList: (data: string) => requests.get('tbl/Tbl_Banks', 'go'),
    getPrinters: (data: string) => requests.get('likut/printer', 'node'),
    getTblFreightCompany: (data: string) => requests.get('tbl/Tbl_FreightCompany', 'go'),
    printSticker: (printerNum: string | number, data: IPrinterData) => requests.put(`likut/printer/${printerNum}`,"node", data),
    // @params data: כ | ..}
    getTblTeudotTypes: (data: string): Promise<ITeudotTypeItem[]> => requests.get(`tbl/Tbl_TeudotTypes?TeudaType=${data}`, 'go'),
    // getPrinters: (data: string) => requests.get('likut/printer', 'node'),
    getProductGroup: (data: string): Promise<IProductGroupNodeResponse> => requests.get('product/group', 'node'),
}