import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
   
    LOGIN_SUCCESS,
    SET_XTOTAL_TEUDOT_DETAILS,
} from "../../../store/actions/actionsTypes";
import store from "../../../store/store";
import {
    STATIC_MONGO,
    STATIC_PATH,
    STATIC_PATH_V2,
    STATIC_PATH_V2_TEST,
    STATIC_PATH_WSPALLETS,
} from "./settings";
import { resetState } from "../../../store/actions/resetState";

type TReqestsInstanceVariables = "go" | "mongo" | "node" | "wspallets";

const getToken = () => {
    const token = localStorage.getItem("user");
    if (!token) return;
    return JSON.parse(token);
};

const staticInstance = {
    timeout: 120000,
    headers: {
        Authorization: "Bearer " + getToken(),
    },
};

// This code exports an object called `instance`, which is an object containing three different Axios instances,
// one for each of the specified routes: `mongo`, `go`, and `node`. These Axios instances are used to make HTTP requests
// to the corresponding routes.
export const instance = {
    mongo: axios.create({
        baseURL: STATIC_MONGO,
        ...staticInstance,
    }),
    go: axios.create({
        ...staticInstance,
        // PROD
        baseURL: STATIC_PATH_V2,
        // TEST
        // baseURL: STATIC_PATH_V2_TEST
    }),
    node: axios.create({
        ...staticInstance,
        baseURL: STATIC_PATH,
    }),
    wspallets: axios.create({
        ...staticInstance,
        baseURL: STATIC_PATH_WSPALLETS,
    }),
    // The `instance` object also has a getter called `state` that returns an object containing the three Axios instances.
    get state() {
        return {
            mongo: this.mongo,
            go: this.go,
            node: this.node,
            wspallets: this.wspallets,
        };
    },
    // It also has a setter called `updateToken` which takes a string `token` as its argument.
    // This setter updates the `Authorization` header for each of the Axios instances with the provided `token`.
    set updateToken(token: string) {
        this.mongo.defaults.headers.Authorization = `Bearer ${token}`;
        this.go.defaults.headers.Authorization = `Bearer ${token}`;
        this.node.defaults.headers.Authorization = `Bearer ${token}`;
        this.wspallets.defaults.headers.Authorization = `Bearer ${token}`;
    }
};

// The code sets up response interceptors for the `go` and `node` Axios instances to catch any errors that may
// occur during the request. If the error message is `jwt malformed`, `jwt expired`, or the status is
instance.go.interceptors.response.use(
    (response: AxiosResponse) => {
        store.dispatch({
            type: SET_XTOTAL_TEUDOT_DETAILS,
            payload: response.headers["x-total-count"],
        });
        return response;
    },
    (err: any) => {
        if (
            err.response.status === 401
            // err.response?.data.message  "jwt expired" ||
        ) {
            return retryRequest(err.config); // Call
        }
        return Promise.reject(err);
    }
);

instance.node.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (err: any) => {
        if (
            err.response?.data.message === "jwt expired" ||
            err.response?.data.message === "jwt malformed"
        ) {
            return retryRequest(err.config); // Call
        }
        return Promise.reject(err);
    }
);
instance.wspallets.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (err: any) => {
        if (
            err.response?.data.message === "jwt expired" ||
            err.response?.data.message === "jwt malformed"
        ) {
            return retryRequest(err.config); // Call
        }
        return Promise.reject(err);
    }
);

export const refreshAccessToken = async (refreshToken: string) => {
    try {
        const response = await axios.post(STATIC_PATH_V2 + "/auth/refresh_token", {
            RefreshToken: refreshToken,
        });
        localStorage.setItem('user', JSON.stringify(response.data.token));
        store.dispatch({ type: LOGIN_SUCCESS, payload: response.data });
        return response.data.token;

    } catch (error) {
        // Handle refresh token error, e.g., log the user out.
        throw error;
    }
};

const retryRequest = async (originalRequest: AxiosRequestConfig) => {
    try {
        const refreshToken = store.getState().mainReducer.refresh_token;
        const accessToken = await refreshAccessToken(refreshToken);

        instance.mongo.defaults.headers.Authorization = `Bearer ${accessToken}`;
        instance.go.defaults.headers.Authorization = `Bearer ${accessToken}`;
        instance.node.defaults.headers.Authorization = `Bearer ${accessToken}`;
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        // Retry the original request
        return axios(originalRequest)
    } catch (error: any) {
        if (error.response.status === 403) {
            store.dispatch(resetState())    
        
        }
        throw error;
    }
};

const responseBody = (response: AxiosResponse) => response.data;
// The code also exports an object called `requests`, which has functions for making different types of HTTP requests
// (`get`, `post`, `put`, `patch`, and `delete`) to the specified route using the corresponding Axios instance from
// the `instance` object. The `responseBody` function is used to extract the data from the response object returned
// by the Axios instance.
export const requests = {
    get: (url: string, route: TReqestsInstanceVariables, config?: AxiosRequestConfig) =>
        instance[route].get(url).then(responseBody),
    post: (url: string, route: TReqestsInstanceVariables, body: any, config?: AxiosRequestConfig) =>
        instance[route].post(url, body).then(responseBody),
    put: (url: string, route: TReqestsInstanceVariables, body: any, config?: AxiosRequestConfig) =>
        instance[route].put(url, body).then(responseBody),
    patch: (url: string, route: TReqestsInstanceVariables, body: any, config?: AxiosRequestConfig) =>
        instance[route].patch(url, body).then(responseBody),
    delete: (url: string, route: TReqestsInstanceVariables, config?: AxiosRequestConfig) =>
        instance[route].delete(url).then(responseBody),
};
