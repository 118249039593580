import { useEffect, useMemo, useRef, useState } from "react"
import selectOnFocus from '../../../../../../modules/autoselect'
import WrapperList from './wrappers/wrappersList'
import { EXIST_PATH } from '../../../../../../modules/API/settings'
import styled from "styled-components"
import { MinusIcon, PackageIcon, PlusIcon, ScalesIcon, ShekelIcon } from "../../../../../../generic/icons/Icons"
import { roundToTwo } from "../../../../../../modules/roundToTwo"
import { convertStringDateToUnix } from "../../../../../../generic/utils/forDate/convertStringDateToUnix"
import { diffDates } from "../../../../../../generic/utils/forDate/diffDates"
import { checkPackType } from "../../../../../../generic/utils/checkPackType"
import { handleNotifications } from "../../../../../../generic/utils/handleNotifications"



export const ItemMainWrapper = styled.div`
    width: 320px;
    min-height: 120px;
    position: relative;
    outline: none;
    /* background: #fdfdfd; */
    display: flex;
    flex-flow: row-reverse;
    /* align-items: center; */
    border-radius: 10px;
    /* overflow: hidden; */
    gap: 0.5em;
    /* filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.41)); */
    
`
export const ImageContainer = styled.div`
    height: 6em;
    width: 6em;
    position: relative;
    border-radius: 50% 50% 25% 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fdfdfd;
    margin-top: 0.7em;
    cursor: pointer;
    -webkit-box-shadow: 2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: 2px 8px 2px -6px rgba(41,127,118,0.71);
    ::after {
        position: absolute;
        left: -1em;
        top: 18%;
        content: '';
        width: 3em;
        height: 4em;
        background: #fdfdfd;
        z-index: -1;
        -webkit-box-shadow: 2px 8px 2px -6px rgba(41,127,118,0.71); 
        box-shadow: 2px 8px 2px -6px rgba(41,127,118,0.71);
    }
`
export const ImageBlock = styled.div`
    height: 5em;
    width: 5em;
   
    border-radius: 50%;
    background-color: #fdfdfd;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fdfdfd;
`
export const ContentWrapper = styled.div`
    position: relative;
    width: 70%;
    min-height: 100%;
    overflow: hidden;
    border-radius: 10px;
    /* background: red; */
    
`
export const FrontWrapper = styled.div`
    position: absolute;
    min-height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    flex-flow: column;
    border-radius: 10px;
    justify-content: flex-start;
    align-items: center;
    gap: 0.2em;
    background: #fdfdfd;
    padding: 0.5em;
    -webkit-box-shadow: 2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: 2px 8px 2px -6px rgba(41,127,118,0.71);
`

export const TitleFrontWrapper = styled.div`
    width: 100%;
    min-height: 2.5em;
    background: rgba(76,148,141, 0.7);
    color: #fdfdfd;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* -webkit-box-shadow: 2px 8px 2px -6px rgba(41,127,118,0.71); 
    box-shadow: 2px 8px 2px -6px rgba(41,127,118,0.71); */
`
export const ControlitemWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* padding: 0.2em; */
    /* background: #eff3f4; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 0.7em;
    
`
const PackageWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    gap: 0.2em;
    /* background-color: red; */
    padding: 0.2em 0;
`
const WeightWrapper = styled.div`
    height: 1.8em;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #297F76;
    padding: 0 0.5em;
    gap: 0.3em;
    border-radius: 30px;
    background-color:#eff3f4;
`
export const CountContainer = styled.div`
    position: relative;
    min-height: 3.5em;
    min-width: 3.5em;
    background: #eff3f4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 2px 7px 7px -6px rgba(41,127,118,0.71); 
    box-shadow: 2px 7px 7px -6px rgba(41,127,118,0.71);
`
export const CountInput = styled.input`
    width: 3em;
    height: 3em;
    background: #fdfdfd;
    border-radius: 50%;
    border: none;
    outline: none;
    padding: 0 0.5em;
    color: #4b938c;
    text-align: center;
    :disabled {
        opacity: 0.5;
    }
    
`
export const Amlaca = styled.div`
    position: absolute;
    background: #fdfdfd;
    color: #4b938c;
    height: 2.2em;
    min-width: 5.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 20px 20px;
    border: none;
    right: 0;
    bottom: 0em;
    z-index: 2;
    cursor: pointer;
    /* -webkit-box-shadow: -0px 3px 10px -3px rgba(41,127,118,0.71); 
    box-shadow: -0px 3px 10px -3px rgba(41,127,118,0.71); */
    @media screen and (max-width: 450px){
        height: 2.4em;
        width: 2.4em;
    }    
`
export const CountButton = styled.button`
    position: absolute;
    background: #fdfdfd;
    color: #4b938c;
    height: 2.2em;
    width: 2.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    right: -0.5em;
    bottom: -0.2em;
    cursor: pointer;
    -webkit-box-shadow: -0px 3px 10px -3px rgba(41,127,118,0.71); 
    box-shadow: -0px 3px 10px -3px rgba(41,127,118,0.71);
    @media screen and (max-width: 450px){
        height: 2.4em;
        width: 2.4em;
    }    
`
export const CountButtonMinus = styled.button`
    position: absolute;
    background: #fdfdfd;
    color: #4b938c;
    height: 2.2em;
    width: 2.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    
    left: -0.5em;
    bottom: -0.2em;
    cursor: pointer;
    -webkit-box-shadow: -0px 3px 10px -3px rgba(41,127,118,0.71); 
    box-shadow: -0px 3px 10px -3px rgba(41,127,118,0.71);
    @media screen and (max-width: 450px){
        height: 2.4em;
        width: 2.4em;
    }
`
export const BackWrapper = styled(FrontWrapper)`
    transition: 0.5s;
    background: #e9f0f0;
    height: 100%;
`
export const BackDataWrapper = styled.div`
    width: 100%;
    min-height: 50%;
    border-radius: 30px;
    background: #eff3f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    padding: 0 0.4em;
`
export const BackItemWrapper = styled.label`
    position: relative;
    height: 80%;
    max-width: 100%;
    border-radius: 30px;
    background: #fdfdfd;
    overflow: hidden;
`
export const BackItemInput = styled.input`
    width: 100%;
    height: 100%;
    border: none;
    text-align: right;
    padding: 0 1em;
    font-size: 1em;
    outline: none;
    z-index: 0;
    color: #4b938c;
`
export const TextField = styled.textarea`
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    padding: 0.5em 1em;
    text-align: right;
    color: #4b938c;
    font-size: 1.05em;
    border-radius: 30px;
    background: #fdfdfd;
    ::-webkit-scrollbar {
        width: 0;
    }
    ::placeholder {
        font-size: 0.9em;
        font-family: Arial, Helvetica, sans-serif;
    }
    
`
export const IconWrapper = styled.div`
    position: absolute;
    /* background: red; */
    height: 100%;
    width: 2.5em;
    color: #4b938c;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    font-size: 0.9em;
    z-index: 1;
    cursor: pointer;
`

const PackText = styled.p`
    width: 100%;
    white-space: nowrap; /* Запрещаем перенос текста на новую строку */
    overflow: hidden; /* Обрезаем текст, если он выходит за границы */
    text-overflow: ellipsis; /* Добавляем троеточие в конце обрезанного текста */
    background: #EFF3F4;
    padding: 0.2em;
    border-radius: 30px;
    color: #297F76;
    direction: rtl;
`

export default function GoodsItem({ id, desc, Packs, pack_id, Price, getMlai, getMhir,
    goodsListDefault, setProduct, product, chengeAkpaca, packegeList, item, ProductCalculationMethod,
    PackID, RecID, ProductDesc, VarietyDesc, ProductID, VarietyID, SizeID, ImageUrl, GroupID, OrderPacks, SalePrice, OrderWeight,
    presetWindow, filterClose, collectDataProduct, GroupArray, filterGoodsValue, ExistingStock, dateForAmlaca, RecommendQty,
    setOrderTotal, orderTotal, AvailablePacks, isEditPrice,
     getGoodPrice, isPriced, PackWeight, isLoadPrice, Pack_Name, MinPrice
}) {

    const [packs, setPacks] = useState(OrderPacks || Packs || +'0');
    const [remark, setRemark] = useState('');
    const packRef = useRef(PackID)

    useEffect(() => {
        const convert = () => {
            let converted = remark.split('\n').join(' ')
            // console.log(converted)
            setRemark(converted)
        }
        convert()
    }, [remark])

    const [packType, setPackType] = useState(PackID || '')
    const [priceGood, setPriceGood] = useState(Price && MinPrice !== undefined ? Math.max(MinPrice, Price).toFixed(2) : 0)
    const [mlai, setMlai] = useState(ExistingStock || +'0')
    const [side, setSide] = useState(false)

    const showPrice = useMemo(() => {
        return priceGood || Price?.toFixed(2)
    }, [Price, priceGood])

    const isGetPriceAllow = useMemo(() => {
        return !isPriced
    }, [isPriced])

    const weightCalc = useMemo(() => {
        let weight = 0
        const packTypeDef = checkPackType(packegeList, PackID)
        const packIcon = packTypeDef === '2' ? <PackageIcon /> : 
            packTypeDef === '1' ? <ScalesIcon /> :
            ProductCalculationMethod === 1 ? <PackageIcon /> : <ScalesIcon />
        if (
            (!packType || 
            packType === '0') ||
            !(packTypeDef === '1' || packTypeDef === '2') 
        ) {
            weight = packs * PackWeight
        } else {
            weight = packs 
        }
        return {weight, packIcon: packIcon}
    }, [
            packType, 
            packs, 
            PackWeight, 
            PackID, packegeList
        ]
    )
    const calculatePrice = (e) => {
        const value = Number(e.target.value)
        if (MinPrice !== undefined && value < Math.min(MinPrice, Price)) {
            console.log(MinPrice, Price)
            console.log(value)
            setPriceGood(value)
            handleNotifications(`המחיר נמוך מהמינימום (${Math.min(MinPrice, Price)})`, {type: "warning"})
        } else {
            setPriceGood(value)
        }
    }
    const groupId = GroupArray.filter(item => item === +filterGoodsValue)

    const plusValue = () => {
        setPacks(packs + 1)
        setOrderTotal(orderTotal += 1)
    }

    const minusValue = (e) => {
        if (packs > 0) {
            setPacks(packs - 1)
            setOrderTotal(orderTotal -= 1)
        }
    }

    //switch good side
    const toggleSide = () => {
        setSide(!side)
        if(isGetPriceAllow) {
            getGoodPrice(ProductID, VarietyID, SizeID, PackID, id, item)
        }
    }
    const dateDiff = diffDates(convertStringDateToUnix(dateForAmlaca.choosen), convertStringDateToUnix(dateForAmlaca.toDay))
    console.log(dateDiff)
    let bacPic = ImageUrl === '' ? 'url("/emptyBox.png") center center no-repeat' : `url("${EXIST_PATH}${ImageUrl}") center center no-repeat`
    // let displayToggle = side ? 'translateY(100%)' : 'translateY(0)';
    let displayToggleBack = !side ? 'translateY(100%)' : 'translateY(0)';
    let counterToggle = packs > 0 || Packs > 0 ? 'block' : 'none'
    // let checkPrice = priceGood === 0 ? 'מחיר' : priceGood

    let mlaiToggle = !getMlai ? 'hidden' : 'visible'
    let mhirToggle = getMhir || isEditPrice ? 'visible' : 'hidden'

    let setlectedPack = product.filter(e => e.id === id)[0]?.PackID
    const prodName = `${ProductDesc?.trim()} ${VarietyDesc?.trim()}`;
    const finalPrice = showPrice < Math.min(MinPrice, Price) ? Math.min(MinPrice, Price) : showPrice

    return (

        <ItemMainWrapper
            id="good-item-select"
            onClick={(e) => {
                
                }}
            // className="modal-azmanot-wrapper-inner__cards-item" 
            tabIndex={0}
            onBlur={() => {
                collectDataProduct(product, setProduct, RecID, id, VarietyID, packs, packType, ProductID, SizeID, remark, mlai, finalPrice, groupId[0], Price, SalePrice, prodName)  
                if(showPrice < Math.min(MinPrice, Price)) {
                    setPriceGood(Math.min(MinPrice, Price))
                }

            }
            }
            title="for more information - tap on the image"
        >
            <Amlaca>
                {
                    dateDiff === 0 && RecommendQty?.AvgCnt1 > 0 ? `${RecommendQty.AvgCnt1}` :
                        dateDiff === 1 && RecommendQty?.AvgCnt2 > 0 ?  `${RecommendQty.AvgCnt2}` :
                            dateDiff === 2 && RecommendQty?.AvgCnt3 > 0 ? `${RecommendQty.AvgCnt3}`  : ''
                }
            </Amlaca>
            <ImageContainer onClick={toggleSide}  >
                <ImageBlock style={{ background: bacPic, backgroundSize: 'contain' }} />
            </ImageContainer>
            <div className="modal-azmanot-wrapper-inner__cards-item__front-counter" style={{ display: counterToggle }}>
                <span>{packs}</span>
            </div>
            <ContentWrapper >
                <FrontWrapper >
                    <TitleFrontWrapper>{ProductDesc} {VarietyDesc} {SizeID}</TitleFrontWrapper>
                    <ControlitemWrapper>
                        <PackageWrapper>
                            {
                                <WrapperList 
                                    packegeList={AvailablePacks} 
                                    setPackType={setPackType} 
                                    PackID={PackID} 
                                    setlectedPack={setlectedPack}  
                                    disableInputs={packRef.current !== 0}     
                                /> 

                            }
                            {/* {
                                PackID === 0 ?
                                <WrapperList 
                                    packegeList={AvailablePacks} 
                                    setPackType={setPackType} 
                                    PackID={PackID} 
                                    setlectedPack={setlectedPack}  
                                    disableInputs={packRef.current !== 0}     
                                /> :
                                <PackText>
                                    {Pack_Name?.trim()}
                                </PackText>

                            } */}
                            <WeightWrapper>
                                {weightCalc.weight}
                                {weightCalc.packIcon}
                            </WeightWrapper>
                        </PackageWrapper>
                        <CountContainer>
                            <CountInput
                                min={0}
                                onClick={selectOnFocus}
                                placeholder="0"
                                max="999"
                                type="number"
                                inputMode="numeric"
                                value={packs}
                                disabled={isLoadPrice}
                                onWheel={(e) => e.currentTarget.blur()}
                                onChange={(e) => {
                                    setPacks(+e.target.value);
                                }}
                            />
                            <CountButtonMinus onClick={minusValue}>
                                <MinusIcon />
                            </CountButtonMinus>
                            <CountButton onClick={plusValue}>
                                <PlusIcon />
                            </CountButton>
                        </CountContainer>
                    </ControlitemWrapper>
                </FrontWrapper>

                <BackWrapper style={{ transform: displayToggleBack }}>
                    <BackDataWrapper>
                        <BackItemWrapper style={{ visibility: mhirToggle }}>
                            <BackItemInput
                                onClick={selectOnFocus}
                                readOnly={!isEditPrice}
                                // type="text"
                                type="number"
                                inputMode="decimal"
                                min={0}
                                placeholder="מחיר"
                                value={showPrice}
                                onChange={calculatePrice}
                            />
                            <IconWrapper><ShekelIcon /></IconWrapper>
                        </BackItemWrapper>
                        <BackItemWrapper style={{ visibility: mlaiToggle }}>
                            <BackItemInput
                                onClick={selectOnFocus}
                                // readOnly={!editMhirMlaiStatus}
                                type="number"
                                placeholder="mlai"
                                value={roundToTwo(mlai)}
                                onChange={(e) => { setMlai(e.target.value) }}
                            />
                            <IconWrapper><PackageIcon /></IconWrapper>
                        </BackItemWrapper>
                    </BackDataWrapper>
                    <TextField
                        // onClick={selectOnFocus}
                        type="text"
                        placeholder="הערות"
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                    />
                </BackWrapper>
            </ContentWrapper>
        </ItemMainWrapper>
    );
}