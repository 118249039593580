import React, {useState, useMemo, useContext} from 'react'
import OrderlikeLayout from '../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../generic/inputs/inputSearch'
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { LINKS } from '../../../../Routs/config'
import useDataFetcher from './hooks/useFetchData'
import { Knisot } from '../../../../modules/API/Knisot/knisot'
import DatePickerModule from '../../../../generic/datePicker/datePicker'
import { IEntrie } from '../../../../modules/API/Knisot/models/entrie'
import BodyScrollContainer from '../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import CustomBlockLoader from './generic/CustomBlockLoader'
import EntrieItem from './Components/EntrieItem'
import TitlesList from '../../../../generic/titlesRowItem/TitlesList'
import { useDispatch, useSelector } from 'react-redux'
import { LOAD, LOAD_END, SELECT_DATA_FOR_ENTRY } from '../../../../../store/actions/actionsTypes'
import { RootState } from '../../../../../store/reducers/rootReducer'
import ButtonLayout from './Layouts/ButtonLayout'
import ControlButton from '../../../../generic/buttons/ControlButton'
import CreateKnisa from './Create/CreateKnisa'
import { IPrinterData } from '../../../../modules/API/Defaults/models/printSticker'
import { ContextModal } from '../../../../modules/modal/modalContext'
import useWorkerInfo from '../../../../generic/hooks/useWorkerInfo'
import { useTranslation } from 'react-i18next'


const KnisotMain = () => {
    const [t] = useTranslation('knisa')
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation();
    const [modal, setModal] = useContext(ContextModal);
    const {user, token} = useSelector((state: RootState) => state.mainReducer)
    const setDefaultDate = useSelector((state: RootState) => state.entryReducer.date)
    const [date, setDate] = useState<string>('')
    const [dateEnd, setDateEnd] = useState<string>('')
    const {path} = useRouteMatch();
    const [input, setInput] = useState<string>('')
    const workerID = user && user.rights.includes('WORKER') ? +user.code_internal : 0
    const {Role_108} = useWorkerInfo({workerID})
   
    const {
        isLoading, 
        data
    } = useDataFetcher<IEntrie[]>(
        Knisot.getEntries, `DateFrom=${date}&DateTo=${dateEnd}`, false, location.pathname);
    
    const selectedDate =  Array.isArray(setDefaultDate) ? setDefaultDate : new Date(setDefaultDate)
    const isAllowToCreate = user?.rights?.includes('OFFICE') || Role_108 === 5

    const handleDate = (e: object | object[]) => {
            dispatch({type: SELECT_DATA_FOR_ENTRY, payload: e}
        )
    } 
     
    const renderData = useMemo(() => {
        if (!data) return []
        return data.filter(item => 
            item.EntryNum.toString().includes(input) ||
                item.GrowerName?.trim().includes(input) ||
                    item.Reference.toString().includes(input)
        )
    }, [data, input])

    const handleCreateNewEntry = () => {
        history.push(`${LINKS.knisot.teudot.create}`)
    }
    const handlePrintKnisa = async (entryNum: number | string) => {
       
        dispatch({type: LOAD})
        try {
            // const res = await Defaults.printSticker(selectedPrinter, printerData)
            const url = `entry/${entryNum}/pdf`
            let response = await fetch(
                `https://my.agroline.co.il/v2/${url}`,
                {
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                }
              );
              const data = await response.blob();
              // Receive PDF Steam as as Blob
              const file = new Blob([data], { type: "application/pdf" }); //Create a Blob from the PDF Stream
              const fileURL = URL.createObjectURL(file); //Build a URL from the file
              window.open(fileURL); //Open the URL on new Window
        } catch {
            setModal({
                open: true,
                value: `כישלון בהדפסת כניסה מספר ${entryNum}`,
                mood: false
            })
            setTimeout(() => {
                setModal({
                    open: false,
                    value: '',
                    mood: false
                })
            }, 3000)    
        } finally {
            dispatch({type: LOAD_END})

        }
    }
    // const handleDate = (e: object) => {
    //     console.log(e)
    //     dispatch({type: SELECT_DATA_FOR_ENTRY, payload: e})
    // }
  return (
    <Switch>
        <Route path={path} exact>
            <OrderlikeLayout label={t("knisa_main_title")}>
                <ControlSearchPannelLayout>
                    <DatePickerModule 
                        // multyDate={false}
                        disabled={false}
                        selected={setDefaultDate ? selectedDate : false}
                        setSelectedDateStart={setDate}
                        setSelectedDateEnd={setDateEnd}
                        defEndDate={false}
                        // saveWithoutFormat={handleDate}
                        large
                        cashFunc={handleDate}
                        multyDate
                        ignorBrod={true}
                        isNeedWithoutFormated={true}
                    />
                    <SearchInput
                        input={input}
                        setInput={setInput}
                        value={t("knisa_main_search_placeholder")}
                        />
                </ControlSearchPannelLayout>
                <BodyScrollContainer>
                    {isLoading ?
                        <CustomBlockLoader />
                        :
                        <TitlesList 
                            list={renderData}
                            uniqueKey={'EntryNum'}
                            Element={EntrieItem}
                            noPadding={false}
                            backgroundColor={'#e9f3f2'}
                            additionalData={{
                                handlePrintKnisa: handlePrintKnisa
                            }}
                        />                    
                    }
                </BodyScrollContainer>
                {
                    isAllowToCreate && 
                        <ButtonLayout>
                            <ControlButton 
                                lable={t("knisa_main_button_new")}
                                handleClick={handleCreateNewEntry}
                            />
                        </ButtonLayout>
                }
            </OrderlikeLayout>
        </Route>
        <Route 
            path={`${LINKS.knisot.teudot.editGeneral}`}
            render={
                () => 
                <CreateKnisa 
                    print={handlePrintKnisa}
                />
            }
        />
        <Route 
            path={`${LINKS.knisot.teudot.create}`}
            render={
                () => 
                <CreateKnisa
                    print={handlePrintKnisa}
                />
            }
        />
        
    </Switch>
  )
}



export default KnisotMain