const teudotMainHE = {
  "teudot_main_title": "תעודות משלוח",
  "teudot_main_search_placeholder": "חיפוש תעודה",
  "teudot_main_button_new": "הוספת משלוח",
  "teudot_main_element_swipe_edit": "עדכון",
}

const teudotCreatePageModalTitleHE = {
  "teudot_create_modal_title_title": "כותרת תעודה",
  "teudot_create_modal_title_client": "בחר לקוח",
  "teudot_create_modal_title_seller": "בחר משווק",
  "teudot_create_modal_title_teudaType": "בחר סוג תעודה",
  "teudot_create_modal_title_car": "מספר רכב",
}

const teudotCreatePageHE = {
  "teudot_create_page_title": "תעודה חדשה",
  "teudot_create_page_title_ids": "תעודה: {{id}}",
  "teudot_create_page_search_placeholder": "סריקה",
  "teudot_create_page_tabs_rest": "מלאי",
  "teudot_create_page_tabs_taken": "נבחרו",
  "teudot_create_page_element_swipe_take": "הוסף",
  "teudot_create_page_element_swipe_cancel": "הסר",
}

const createProductModalHe = {
  "create_product_modal_title_new": "חדש :משטח",
  "create_product_modal_title_created": ":משטח {{id}}",
  "create_product_modal_search": "סריקה",
  "create_product_modal_product": "בחר פריט",
  "create_product_modal_size": "בחר גודל",
  "create_product_modal_grower": "מגדל",
  "create_product_modal_grower_button": "כולם",
  "create_product_modal_grower_package": "בחר אריזה",
  "create_product_modal_grower_qty": "כמות",
  "create_product_modal_calcMethod": "אופן חישוב",
  "create_product_modal_weight": "משקל",
  "create_product_modal_price": "מחיר",
  "create_product_modal_pallet": "סוג משטח",
  "create_product_modal_includeTareWeight": "כולל טרת משטח",
  "create_product_modal_remark": "הערה",
  "create_product_modal_button_save": "שמור פריט",
}

export const teudotTranslationHE = {
  teudot: {
    ...teudotMainHE,
    ...teudotCreatePageModalTitleHE,
    ...teudotCreatePageHE,
    ...createProductModalHe
  }
}