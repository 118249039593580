import React from "react";
import OrderlikeLayout from "../../../../generic/Layouts/OrderlikeLayout";
import KosherTeudotBody from "./KosherTeudotBody";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import TeudotView from "./viewDetails/TeudotView";
import CreateTeuda from "./create/CreateTeuda";
import { LINKS } from "../../../../Routs/config";
import { useTranslation } from "react-i18next";

function KosherTeudot() {
  const [t] = useTranslation("teudot");
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        render={() => (
          <OrderlikeLayout label={t("teudot_main_title")}>
            <KosherTeudotBody />
          </OrderlikeLayout>
        )}
        path={path}
      />
      <Route render={() => <TeudotView />} path={LINKS.teudot.teudotMishloah.editGeneral} />
      <Route path={LINKS.teudot.teudotMishloah.create} render={() => <CreateTeuda />} />
    </Switch>
  );
}

export default KosherTeudot;
