import React from 'react'
import styled from 'styled-components'

interface Props extends React.HTMLAttributes<HTMLTableCellElement> {
    children: React.ReactNode | React.ReactNode[];
    background?: string;
}

const TableBodyCell = ({
    children, background, ...props
}: Props) => {
  return (
    <TableBodyCellStyle
    background={background}
        {...props}
    >
        {children}
    </TableBodyCellStyle>
  )
}
const TableBodyCellStyle = styled.td<{background?: string}>`
    position: relative;
    max-width: max-content;
    direction: ltr;
    text-align: right;
    overflow: hidden;
    padding: 0.2em ;
    background: ${props => props.background ? props.background : '#fff'};
    color: rgba(0,0,0,0.7);
    i {
        margin-left: 0.5em;
        color: #81B4AF;
    }
    
`
export default TableBodyCell