import React from 'react'
import { Customer, ISellerTableDataItem, ISellerTableProductItem } from '../../../../../modules/API/Seller/models/tableData'
import Table from '../../procedures/Q8/Layouts/Table/Table'
import SellerHead from './SellerHead'
import SellerBody from './SellerBody'
import { ISellerChangeOrderProps } from '../types/handlerChangeOrder'

type Props = {
  list: ISellerTableProductItem[],
  meta: Customer[],
  handleChangeOrder: ((props: ISellerChangeOrderProps) => void)
}

const SellerTable = ({
  list,
  meta,
  handleChangeOrder
}: Props) => {
  return (
    <Table>
      <SellerHead
        list={meta}
      />
      <SellerBody
        list={list}
        handleChangeOrder={handleChangeOrder}
      />
    </Table>
  )
}

export default SellerTable