import useDataFetcher from '../../../knisot/hooks/useFetchData'
import { Packages } from '../../../../../../modules/API/Packages/packages'
import { IPackPalletSummaryItem, TYPES } from '../../../../../../modules/API/Packages/models/packPalletSummary'
import styled from 'styled-components'
import CustomBlockLoader from '../../../knisot/generic/CustomBlockLoader'
import SearchBlockWithTitle from '../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import TitleLayout from '../../../kosherTeudot/generic/TitleLayout'
import TitleRow from '../../../kosherTeudot/generic/TitleRow'
import TitleRowItem from '../../../kosherTeudot/generic/TitleRowItem'
import { PackageIcon, PalletIcon } from '../../../../../../generic/icons/Icons'
import { useDispatch } from 'react-redux'
import { SAVE_CLIENTNAME, SELECT_AZMANA, SELECT_CURRENT_ORDER } from '../../../../../../../store/actions/actionsTypes'
import { LINKS } from '../../../../../../Routs/config'
import { Link } from 'react-router-dom'
import { IOrderTitleItem } from '../../../../../../modules/API/Orders/models/orders'
import ControlButton from '../../../../../../generic/buttons/ControlButton'

export type Props = {
  order: IOrderTitleItem
}

const PackagePalletSummaryModal = ({
  order
}: Props) => {
  const dispatch = useDispatch()
  const {
    data: list,
    isLoading
  } = useDataFetcher<IPackPalletSummaryItem>(
    Packages.getPackagePalletSummary, `${TYPES.order}${order.OrderID}`
  )

  const redirectToPallets = () => {
    dispatch({ type: SELECT_CURRENT_ORDER, payload: order })
    dispatch({ type: SELECT_AZMANA, payload: order.OrderID, group: order.GroupID, status: order.Status, client: order.CustomerID })
    dispatch({ type: SAVE_CLIENTNAME, payload: order.Customer_Name })
  }


  return (
    <MainWrapper>
      {
        isLoading ? 
        <CustomBlockLoader /> :
        <InnerScrollContainer>
          <SearchBlockWithTitle
            title='אריזות'
          >
            <Column>
              {
                list?.Packages.map(item => (
                  <TitleLayout>
                    <TitleRow>
                      <TitleRowItem
                        name={item.Pack_Name.trim()}
                        icon={<></>}
                      />
                      <TitleRowItem 
                        name={item.Quantity}
                        icon={<PackageIcon />}
                      />
                     
                    </TitleRow>
                  </TitleLayout>
                ))
              }
            </Column>
          </SearchBlockWithTitle>
          <SearchBlockWithTitle
            title='משטחים'
          >
            <Column>
              {
                list?.Pallets.map(item => (
                  <TitleLayout>
                    <TitleRow>
                      <TitleRowItem
                        name={item.Pallete_Desc.trim()}
                        icon={<></>}
                      />
                      <TitleRowItem 
                        name={item.Quantity}
                        icon={<PalletIcon />}
                      />
                    </TitleRow>
                  </TitleLayout>
                ))
              }
            </Column>
          </SearchBlockWithTitle>
    
        </InnerScrollContainer>
      }
      <Link
        onClick={redirectToPallets}
        to={LINKS.likut.likutExperess.palletSection}
        target='_blank'
        >
        
      <ControlButton 
        lable={'שינוי'}
        // handleClick={redirectToPallets}
        />
      </Link>
    </MainWrapper>
  )
}

const MainWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`
const InnerScrollContainer = styled.div`
  width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 0;
    }
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    justify-content: center;
    align-items: center;
    width: 100%;
`
export default PackagePalletSummaryModal