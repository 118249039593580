import React, {useContext, useMemo, useState} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import ProductChangeFilterModal from './Components/ProductChangeFilterModal'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { FilterIcon } from '../../../../../generic/icons/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { ITableOfTableItem } from '../../../../../modules/API/Defaults/models/tableOfTables'
import useDataFetcher from '../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { ICustomerGroup } from '../../../../../modules/API/Defaults/models/customersGroup'
import { ITransportAreaItemResponse } from '../../../../../modules/API/Defaults/models/transportAreas'
import ProductItem from './Components/ProductItem'
import { IProductCustomerItem, OrderDetail } from '../../../../../modules/API/Orders/models/detailsProductCustomer'
import DatePickerISO from '../../forcasts/Distribution/Components/DatePickerISO'
import { Orders } from '../../../../../modules/API/Orders/orders'
import { format } from 'date-fns'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import styled from 'styled-components'
import ProductModal from './Components/ProductModal'
import CustomBlockLoader from '../../knisot/generic/CustomBlockLoader'
import { IProductListNodeResponse } from '../../../../../modules/API/Defaults/models/productListNode'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import selectBrodMigdal from '../../../../../generic/utils/selectBrodMigdal'

const ProductChangesMain = () => {
  const dispatch = useDispatch()
  const {defaults, tableOfTables, packageList} = useSelector((e: RootState) => e.defaults)
  const dayJump = selectBrodMigdal(defaults, 338, "Num_Value")  
  
  const defaultDateInISO = () => {
    const date = new Date()
    date.setDate(date.getDate() + dayJump)
    return date
  }

  const [modal, setModal] = useContext(ContextModal)
  const [date, setDate] = useState(() => defaultDateInISO())
  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false)
  const [isOpenProductModal, setIsOpenProductModal] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [selectedCustomerGroup, setSelectedCustomerGroup] = useState<number>(0)
  const [selectedMarketArea, setSelectedMarketArea] = useState<number>(0)
  const [selectedTransportArea, setSelectedTransportArea] = useState<number>(0)

  const [selectedProduct, setSelectedProduct] = useState<IProductCustomerItem | null>(null)

  const marketAreaList = useMemo(() => {
    return tableOfTables.filter((e: ITableOfTableItem) => e.MainCode === 300 && e.SecondCode !== 0)
  }, [tableOfTables])
  const {
    data: prductList,
    setData: setProductList,
    isLoading
  } = useDataFetcher<IProductCustomerItem[]>(
    Orders.getDetailsByProductCustomer, format(date, 'yyyyMMdd')
  )
  const {
    data: customerGroupList
  } = useDataFetcher<{groups: ICustomerGroup[]}>(
    Defaults.getCustomersGroup, 'data'
  )

  const {
    data: productTypeList
  } = useDataFetcher<IProductListNodeResponse>(
    Defaults.getProductListNode, "data"
  )

  const {
    data: transportAreas
  } = useDataFetcher<ITransportAreaItemResponse>(
    Defaults.getTransportAreas, 'data'
  )
 
  const handleSaveFunc = async (
    OriginalItem: IProductCustomerItem, 
    resultItem: IProductCustomerItem
  ) => {
    console.log('OriginalItem', OriginalItem)
    console.log('resultItem', resultItem)
    if(!prductList) return
    dispatch({type: LOAD})
    const newItem = [...prductList]
    try {

      const res = await Orders.updateProductCustomer(resultItem.OrderDetails)
      if(
        OriginalItem.Product.ProductID === resultItem.Product.ProductID
        && OriginalItem.Product.VarietyID === resultItem.Product.VarietyID
        && OriginalItem.Product.SizeID === resultItem.Product.SizeID
      ) {

        const index = newItem.findIndex(e => 
          e.Product.ProductID === resultItem.Product.ProductID
          && e.Product.VarietyID === resultItem.Product.VarietyID
          && e.Product.SizeID === resultItem.Product.SizeID
        )
         res[0].OrderDetails.forEach((e: OrderDetail) => {
          const indexElem = newItem[index].OrderDetails.findIndex(i => i.RecID === e.RecID)
          if(indexElem === -1) {
            newItem[index].OrderDetails.push(e)
          } else {
            newItem[index].OrderDetails[indexElem] = e
          }
        })
        
        setProductList(newItem)
      } else {
        const index = newItem.findIndex(e => 
          e.Product.ProductID === OriginalItem.Product.ProductID
          && e.Product.VarietyID === OriginalItem.Product.VarietyID
          && e.Product.SizeID === OriginalItem.Product.SizeID
        )
        const indexNew = newItem.findIndex(e => 
          e.Product.ProductID === resultItem.Product.ProductID
          && e.Product.VarietyID === resultItem.Product.VarietyID
          && e.Product.SizeID === resultItem.Product.SizeID
          
        )
        // DELETE PREV ARRAY ITEMS
        newItem[index] = {
          ...newItem[index],
          OrderDetails: newItem[index].OrderDetails.filter(e => 
            e.ProductID === resultItem.Product.ProductID
            && e.VarietyID === resultItem.Product.VarietyID
            && e.SizeID === resultItem.Product.SizeID
          )
        }
        // ADD NEW ARRAY ITEMS OR UPDATE IF EXIST
        if (indexNew === -1) {
          newItem.push(res[0])
        } else {
          res[0].OrderDetails.forEach((e: OrderDetail) => {
            const indexElem = newItem[indexNew].OrderDetails.findIndex(i => i.RecID === e.RecID)
            // ADD NEW ITEM IF NOT EXIST OR PACKAGE ID NOT MATCH
            if(indexElem === -1 || e.PackID !== newItem[indexNew].OrderDetails[indexElem].PackID) {
              newItem[indexNew].OrderDetails.push(e)
            } else {
              newItem[indexNew].OrderDetails[indexElem] = e
            }
          })

        }
        setProductList(newItem)
      }

      setModal({
        open: true,
        value:  'המוצר עודכן בהצלחה',
        mood: true
      })
      setTimeout(() => {
          setModal({
              ...modal,
              open: false
          })
      }, 3000)

      handleProductModal()
    } catch (error) {
      setModal({
        open: true,
        value: 'שגיאה בעדכון המוצר',
        mood: false
    })
    setTimeout(() => {
        setModal({
            ...modal,
            open: false
        })
    }, 3000)
    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const handleFilterModal = () => {
    setIsOpenFilterModal(!isOpenFilterModal)
  }

  const handleProductModal = () => {
    setIsOpenProductModal(!isOpenProductModal)
  }

  const selectProduct = (item: IProductCustomerItem, recID?: number) => {
    if(recID) {
      const orderDetails = item.OrderDetails.find(e => e.RecID === recID)
      if(orderDetails) {
        setSelectedProduct({
          ...item,
          OrderDetails: [orderDetails]
        })
      }
      handleProductModal()
      return

    } 
    setSelectedProduct(item)
    handleProductModal()
  }

  const productModalProps = {
    controlFunc: handleProductModal,
    product: selectedProduct,
    packages: packageList || [],
    products: productTypeList?.products || [],
    handleSaveFunc: handleSaveFunc
  }
  
  const isMobile = window.innerWidth < 768

  const filterProps = {
    controlFunc: handleFilterModal,
    customerGroupList: customerGroupList?.groups || [],
    marketAreaList: marketAreaList,
    transportAreas: transportAreas?.transportAreas || [],
    selectedCustomerGroup: selectedCustomerGroup,
    selectedMarketArea: selectedMarketArea,
    selectedTransportArea: selectedTransportArea,
    setSelectedCustomerGroup: setSelectedCustomerGroup,
    setSelectedMarketArea: setSelectedMarketArea,
    setSelectedTransportArea: setSelectedTransportArea
  }

  const productProps = {
    handleProductModal: selectProduct,
    isMobile
  }

  const render: IProductCustomerItem[] = useMemo(() => {
    if(!prductList) return []
    return prductList.filter(item => (
      `${item.Product.ProductDesc.trim()} ${item.Product.VarietyDesc.trim()} ${item.Product.SizeID}`
        .toLowerCase()
          .includes(inputValue.toLowerCase())
    )).map(item => {
      return {
        ...item,
        OrderDetails: item.OrderDetails.filter(e => (
          (selectedCustomerGroup !== 0 ? e.Customer_GroupID === selectedCustomerGroup : true) &&
          (selectedMarketArea !== 0 ? e.Marketing_AreaID === selectedMarketArea : true) &&
          (selectedTransportArea !== 0 ? e.TransferZone === selectedTransportArea : true)
        ))
      }
    }).filter(item => item.OrderDetails.length > 0)
  }, [inputValue, prductList, selectedCustomerGroup, selectedMarketArea, selectedTransportArea])

  return (
    <OrderlikeLayout
      label='עדכון שורות הזמנות לקוח'
    >
      {
        isOpenFilterModal && (
          <ColumModal 
            title='הגדרות'
            controlFunc={handleFilterModal}
            childrenProps={filterProps}
            Children={ProductChangeFilterModal}
          />
        )
      }
      {
        isOpenProductModal && selectedProduct && (
          <ColumModal 
            title='עריכת פריט'
            controlFunc={handleProductModal}
            childrenProps={productModalProps}
            Children={ProductModal}
          />
        )
      }
      <ControlSearchPannelLayout>
        <DatePickerISO 
          selected={date}
          onChange={setDate}
        />
        <SearchInput 
          input={inputValue}
          setInput={setInputValue}
        />  
        <CircleActiveFilterBtn
          func={handleFilterModal}
        >
          <FilterIcon />
        </CircleActiveFilterBtn>
      </ControlSearchPannelLayout>
      <BodyScrollContainer>

        {
          isLoading ?
          <CustomBlockLoader />
          :
          <MainWrapper>
            {
              render.map((item, index) => (
                <ProductItem 
                  key={`${item.Product.ProductID} ${item.Product.VarietyID} ${item.Product.SizeID}`}
                  {...item}
                  {...productProps}
                  item={item}
                />
              ))
            }
          </MainWrapper>
        }
      </BodyScrollContainer>
    </OrderlikeLayout>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  padding: 1em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
export default ProductChangesMain