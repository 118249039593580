import React, { useState, useEffect, useMemo } from 'react'
import ModalDelete from './modalDelete/modalDelete'
import { SwipeableListItem, ActionAnimations } from '@sandstreamdev/react-swipeable-list';
import ConfermDelete from './modalDelete/confermDelete'
import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import styled from 'styled-components';
import { AkpacaContainer } from './modalAzmanot';
import { SpeedIcon } from '../../../../../generic/icons/Icons';
import { statusConverter } from '../../../../../generic/artcoded/statusAzmanot';
import { useHistory } from 'react-router-dom';
import { LINKS } from '../../../../../Routs/config';

const GroupWrapper = styled.div`
    position: absolute;
    background: #ced3ff;
    border-radius: 5px;
    padding: 0.2em 0.5em;
    right: 0;
    top: -0.2em;
    /* border-radius: 5px; */
    font-size: 0.8em;


`
export const ItemWrapper = styled.div`
    width: 100%;
    /* margin-bottom: 0.5em; */
    background: #eff3f4;
    border-radius: 10px;

    
`
export const InnerContainer = styled.div`
    position: relative;
    width: 100%;
    min-height: 5em;
    border-radius: 10px;
    background: #eff3f4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s;
    padding: 0 0.5em 0 0;
    cursor: pointer;
    transition: 0.3s;
    :active {
        border-radius: 0;
    }
`
const ItemContainer = styled.div`
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    /* background: red; */
    width: 50%;
    color: #838484;
    @media screen and (max-width: 450px){
        font-weight: 400;
    }
    :nth-of-type(5) {
        width: 100%;
    }
`
export const ItemStatus = styled(ItemContainer)`
    min-height: 5em;
    justify-content: center;
    max-width: 20%;
    border-radius: 10px;
    
`
const Decorator = styled.div`
    position: absolute;
    height: 100%;
    width: 0;
    background: red;  
    border-radius: 10px;     
    z-index: 0;
    transition: 0.5s ease;
    /* transform: translateX(-100%); */
`
const AkpazaItemWrapper = styled(AkpacaContainer)`
    right: 0em;
    bottom: 0;
    background: none;
    color: tomato;
`
export default function AzmanotItem({
    removeAzmanot,
    Customer_Name,
    OrderID, 
    Delivery_Date, 
    Remarks, 
    Type, 
    Status, 
    GroupDesc,
    setInnerTime, 
    To_PickupTime

}) {

    const [formedDate, setFormedDate] = useState('');
    const [confDelete, setConfDelete] = useState(false);
    // const [color, setColor] = useState('#fdfdfd');
    const [backgroundColor, setBackgroundColor] = useState('#eff3f4')
    const history = useHistory();

    const transformDate = () => {

        let year = ''
        let month = ''
        let day = ''

        if (Delivery_Date !== undefined) {
            for (let i = 0; i < Delivery_Date.length; i++) {
                if (i < 4) {
                    year += Delivery_Date[i]
                }
                else if (4 <= i && i <= 5) {
                    month += Delivery_Date[i]
                }
                else if (5 < i && i <= 7) {
                    day += Delivery_Date[i]
                }
            }
            setFormedDate(day + '/' + month)
        }
    }
    const {modalEditText, status, color, blockSwipe } = useMemo(() => {
        const modalEditText = Status === 0 ? "עדכון" : 'עיון'
        const {status, color} = statusConverter(Status)
        const blockSwipe = Status !== 0 ? true : false
        return {modalEditText, status, color, blockSwipe}
    }, [Status])
    
    useEffect(transformDate, [])


    // let blockSwipe = Status !== 0 ? true : false
    let deleteVisibility = confDelete ? <ConfermDelete OrderID={OrderID} removeAzmanot={removeAzmanot} /> : "";
    let showAcpaca = Type === 1 ? <SpeedIcon color={'#fdfdfd'} /> : <SpeedIcon color={'tomato'} /> 
    let showDecorator = backgroundColor === '#eff3f4' ? '0' : '100%';

    // console.log(statusConverter(Status))
    let groupDescToggle = GroupDesc?.trim().length > 0 ? 'block' : 'none';


    const handleRightAction = () => {
        setConfDelete(false)
        history.push(`${LINKS.orders.clientOrders.editByID}${OrderID}`)
    }
    return (
        <ItemWrapper >
            <SwipeableListItem
                swipeLeft={!blockSwipe ? {

                    content: <ModalDelete
                        background={"tomato"}
                        callModal={!blockSwipe ? "? מחק" : ''}
                        flex={"flex-end"} />,

                    action: () => {
                        if (!blockSwipe) {
                            setConfDelete(!confDelete)
                        }

                    },
                    ActionAnimations

                } : null}


                swipeRight={{
                    content: <ModalDelete callModal={modalEditText} customerid={OrderID} background={'#7ab0ab'} />,
                    action: handleRightAction
                }}

                // onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
                threshold={0.3}
            // blockSwipe={blockSwipe}

            >
                <InnerContainer
                    onMouseEnter={() => { setBackgroundColor('#fdfdfd') }}
                    onMouseLeave={() => { setBackgroundColor('#eff3f4') }}
                    id="1"
                    
                >
                    <Decorator style={{ background: backgroundColor, width: showDecorator }} />
                    <GroupWrapper className={'group'} style={{ display: groupDescToggle }}>{GroupDesc?.trim()}</GroupWrapper>
                    <ItemStatus style={{ background: color, padding: '0.5em 1em' }}>{status}</ItemStatus>
                    <ItemContainer className="second-mainbar-wrapper__header-item">{Remarks}</ItemContainer>
                    {/* <ItemContainer className="second-mainbar-wrapper__header-item">{showAcpaca}</ItemContainer> */}
                    <ItemContainer style={{maxWidth: '15%'}} className="second-mainbar-wrapper__header-item">{formedDate}</ItemContainer>
                    <ItemContainer style={{minWidth: '45%'}} className="second-mainbar-wrapper__header-item">{Customer_Name}</ItemContainer>
                    <ItemContainer style={{maxWidth: '15%'}} >{OrderID}</ItemContainer>
                    <AkpazaItemWrapper>{showAcpaca}</AkpazaItemWrapper>
                </InnerContainer>
                {deleteVisibility}
            </SwipeableListItem>
        </ItemWrapper>


    );
}