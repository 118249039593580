import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ArizotList from './ArizotList'
import PackageImage from '../../../../../../generic/assets/package.png'
import { CloseIcon } from '../../../../../../generic/icons/Icons'
import selectOnFocus from '../../../../../../modules/autoselect'
import useFocus from '../../../../../../generic/hooks/useFocus'
import { useTranslation } from 'react-i18next'
export const MainWrapper = styled.div`
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    display: ${props => props.open ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    z-index: 3;

`
export const InnerWrapper = styled.div`
    position: relative;
    width: 300px;
    height: 500px;
    background: #eff3f4;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    justify-content:flex-end;
    align-items: center;
    gap: 0.5em;
    padding: 0.5em;
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
`
export const Title = styled.div`
    width: 100%;
    height: 3em;
    background: #fff;
    color: #8a8aad;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    user-select: none;
`
export const ImageWrapper = styled.div`
    width: 100%;
    height: 250px;
    /* background: yellow; */
    display: flex;
    justify-content: center;
    align-items: center;

`
export const BodyWrapper = styled.div`
    width: 100%;
    height: 9em;
    background: #fff;
    border-radius: 30px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    padding: 0.3em 0.5em;
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));

`
const SelectQntWrapper = styled.div`
    width: 100%;
    height: 3.5em;
    background: #eff3f4;
    border-radius: 30px;
    padding: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
`
const QntyInput = styled.input`
    direction: rtl;
    height: 100%;
    width: 30%;
    padding: 0 1em;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    font-size: 1.1em;
    color: #8a8aad;
    border: none;
    border-radius: 30px;
    cursor: pointer;
`
const CounterBtn = styled.button`
    height: 2em;
    width: 2em;
    padding: 0 1em;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    font-size: 1.1em;
    color: #8a8aad;
    border: none;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`


const ButtonWrapper = styled.div`
    width: 100%;
    height: 3em;
    background: #7ab0ab;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    
`
export const Img = styled.img`
    min-width: 270px;

`
export const CloseWindow = styled.div`
    position: absolute;
    top: .5em;
    right: .5em;
    width: 3em;
    height: 3em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    color: #7ab0ab;
    cursor: pointer;
    -webkit-filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    filter: drop-shadow(2px 2px 3px rgba(41,127,118,0.41));
    z-index: 1;

`
export default function ArizotModal({show, setShow, list, saveFunc, defaultID}) {
    const [t] = useTranslation(['likut', 'buttons']);

    const [arizaID, setArizaID] = useState(defaultID);
    const [arizaQnty, setArizaQnty] = useState(0);
    const [selectFocus, setSelectFocuse] = useFocus();
    const minus = () => {
        setArizaQnty(arizaQnty - 1)
    }

    
  return (
    <MainWrapper open={show}>
        <InnerWrapper>
            <CloseWindow onClick={() => {setShow(!show)}}>
                <CloseIcon />
            </CloseWindow>
            <Title onClick={() => setSelectFocuse()}>
                {t('likut_modal_addpackage_title')}
            </Title>
            <ImageWrapper>
                <Img src={PackageImage}  alt='package'/>
            </ImageWrapper>
            
            
            <BodyWrapper>
                <ArizotList 
                    defaultID={arizaID || defaultID}
                    refSelect={selectFocus} 
                    list={list} setArizaID={setArizaID} 
                    placeholder={t('likut_modal_addpackage_selector_title')}
                />
                <SelectQntWrapper>
                    <CounterBtn onClick={minus}>-</CounterBtn>
                        <QntyInput onFocus={selectOnFocus} type={'number'}  value={arizaQnty} onChange={e => {setArizaQnty(+e.target.value)}} />
                    <CounterBtn onClick={() => {setArizaQnty(arizaQnty + 1)}}>+</CounterBtn>
                </SelectQntWrapper>
            </BodyWrapper>
            <ButtonWrapper onClick={() => {
                saveFunc(arizaQnty, arizaID);
                setShow(!show)
            }}>
                {t('save', {ns: 'buttons'})}
            </ButtonWrapper>
        </InnerWrapper>
    </MainWrapper>
  )
}

ArizotModal.propTypes = {
    isOpenArizotModal: PropTypes.bool,
    setShow: PropTypes.func,
    list: PropTypes.array
}
