
// reference
// import React, {useMemo, useState} from 'react'
// import { ICustomerGroup } from '../../../../../../modules/API/Defaults/models/customersGroup'
// import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

// type Props = {
//     defaultID: number
//     setCustomerGroupID: React.Dispatch<React.SetStateAction<number>>
//     list: ICustomerGroup[]
//     placeholder?: string
//     required?: boolean

// }

// const SearchCustomerGroupList = ({
//     defaultID, setCustomerGroupID, list, placeholder, required
// }: Props) => {
//     const defaultCustomerGroup = list.filter(item => item.GroupID === defaultID)[0]
//     const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
//     const [search, setSearch] = useState<string>(() => defaultCustomerGroup?.GroupDesc.trim() || '')

//     const handleSearch = (e: string) => {
//         if(e === '') {
//             setIsOpenDropDown(false)
//             setSearch('')
//             setCustomerGroupID(0)
//             return
//         }
//         setIsOpenDropDown(true)
//         setSearch(e)
//     }
//     const handleSelect = (item: ICustomerGroup) => {
//         setSearch(item.GroupDesc.trim())
//         setIsOpenDropDown(false)
//         setCustomerGroupID(item.GroupID)
//     }
//     const render = useMemo(() => {
//         if(!list) return []
//         return list.filter(item => 
//             item.GroupDesc.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
//         )
//     }, [search, list])
//     return (
//         <SearchFromTheList
//             isOpenList={isOpenDropDown}
//             placeHolder={placeholder || 'חפש קבוצת לקוחות'}
//             list={render}
//             selectFunc={handleSelect}
//             searchFunc={handleSearch}
//             isNeedFullObject
//             input={search}
//             itemDisplayName='GroupDesc'
//             nameIdElement='GroupID'
//             required={required}
//             size={'full'}
//         />
//     )
// }

// export default SearchCustomerGroupList

import React, {useMemo, useState} from 'react'
import { ITableOfTableItem } from '../../../../../../modules/API/Defaults/models/tableOfTables'
import SearchFromTheList from '../../../../../../generic/customSearchComponents/SearchFromTheList'

type Props = {
    defaultID: number
    setMarketingAreaID: React.Dispatch<React.SetStateAction<number>>
    list: ITableOfTableItem[]
    placeholder?: string
    required?: boolean
}

const SearchMarketingArea = ({
    defaultID, list, placeholder, required, setMarketingAreaID
}: Props) => {
    console.log(list)
    const defaultMarketingArea = list.filter(item => item.SecondCode === defaultID)[0]
    const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
    const [search, setSearch] = useState<string>(() => defaultMarketingArea?.Description.trim() || '')

    const handleSearch = (e: string) => {
        if(e === '') {
            setIsOpenDropDown(false)
            setSearch('')
            setMarketingAreaID(0)
            return
        }
        setIsOpenDropDown(true)
        setSearch(e)
    }
    const handleSelect = (item: ITableOfTableItem) => {
        setSearch(item.Description.trim())
        setIsOpenDropDown(false)
        setMarketingAreaID(item.SecondCode)
    }
    const render = useMemo(() => {
        if(!list) return []
        return list.filter(item => 
            item.Description.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )
    }, [search, list])
    return (
        <SearchFromTheList
            isOpenList={isOpenDropDown}
            placeHolder={placeholder || 'חפש אזור שיווק'}
            list={render}
            selectFunc={handleSelect}
            searchFunc={handleSearch}
            isNeedFullObject
            input={search}
            itemDisplayName='Description'
            nameIdElement='SecondCode'
            required={required}
            size={'full'}
        />
    )

  return (
    <div>SearchMarketingArea</div>
  )
}

export default SearchMarketingArea