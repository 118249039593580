import React, { useState } from 'react'
import styled from 'styled-components'
import { CalendatDay, PalletIcon, PlusIcon, UserIcon } from '../../../../../generic/icons/Icons'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import { IPalletDetailes, IPalletListWithDetails } from '../../../../../modules/API/Pallets/models/palletList'
import { convertToDate } from '../../../../../modules/convertDateString'
import PalletDetailesItem from './PalletDetailesItem'
import TitleLayout from '../generic/TitleLayout'
import TitleRow from '../generic/TitleRow'
import TitleRowItem from '../generic/TitleRowItem'
import { SwipeableListItem } from '@sandstreamdev/react-swipeable-list'
import SwipeContent from '../../knisot/Components/SwipeContent'
import { useTranslation } from 'react-i18next'

interface IPalletItem extends IPalletListWithDetails {
    isSelected?: boolean,
    index: number,
    handleSelectPallet: (index: number, palletNum: number) => void,
    packageHash?: Record<number, IPackagesItem>,
    isDisabled?: boolean,
    addProduct: (pallet: number) => void;
    activeTab?: string
    handleSelectProduct?: (item: IPalletDetailes) => void
}
interface IMainWrapper {
    isSelected?: boolean
}
interface IDetailesWrapper extends IMainWrapper {
    isEmpty?: boolean,
    isOpen?: boolean
}
const PalletItem = ({
    Pallet_Num, index, handleSelectPallet, isDisabled,
    Pallet_Date, Details, packageHash, Customer_Name,
    addProduct, activeTab, handleSelectProduct
}: IPalletItem) => {
    const [t] = useTranslation("teudot");
    const [move, setMove] = useState(false);
    const handleClick = () => {

        if (isDisabled) return
        setMove(true)
        try {
            setTimeout(() => {
                handleSelectPallet(index, Pallet_Num)
            }, 500)
        } catch (err) {
            setMove(false)
        }
    }
    const handleAddProduct = (e: React.MouseEvent) => {
        e.stopPropagation()
        addProduct(Pallet_Num)
    }

    
    const formatedDate = convertToDate(Pallet_Date)
    const detailesProps = {
        packageHash: packageHash,
    }
    return (
        
        <MainWrapper
            move={move}
        >
        <SwipeableListItem
            swipeLeft={{
                content: <SwipeContent 
                    text={activeTab === t("teudot_create_page_tabs_taken") 
                        ? t("teudot_create_page_element_swipe_cancel") 
                        : t("teudot_create_page_element_swipe_take")
                    }
                    position='right' 
                 />,
                action: handleClick
            }}
            threshold={0.35}
        >
            <TitleLayout
                isDraggable
                disableShadow
            >
                <SideWrapper
                    onClick={handleAddProduct}
                >
                    <PlusIcon />
                </SideWrapper>
                <TitleRow>
                    <TitleRowItem name={Customer_Name?.trim()} icon={<UserIcon />} />
                </TitleRow>
                <TitleRow>
                    <TitleRowItem name={`${formatedDate}`} icon={<CalendatDay />} />
                    <TitleRowItem name={`${Pallet_Num}`} icon={<PalletIcon />} />
                </TitleRow>
                <DetailesWrapper
                    isEmpty={Details.length === 0}
                    isOpen={Details.length !== 0}
                >
                    {
                        Details.map(item =>
                            <PalletDetailesItem
                                key={item.RecID} 
                                {...item} 
                                {...detailesProps} 
                                item={item} 
                                handleSelectProduct={handleSelectProduct}
                            />
                        )
                    }
                </DetailesWrapper>
            </TitleLayout>
            </SwipeableListItem>

        </MainWrapper>

    )
}

export default React.memo(PalletItem)

const MainWrapper = styled.div<{ move: boolean }>`
    position: relative;
    width: 100%;
    div{
        border-radius: 20px;

    }
    -webkit-filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
        filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    transition: all 0.5s ease-in;
    transform: ${props => props.move ? 'translate(-110%)' : 'translate(0)'};
`

const DetailesWrapper = styled.div<IDetailesWrapper>`
    width: 100%;
    border-radius: 20px;
    max-height: ${props => props.isEmpty ? '0' : '8.5em'};
    background: #eff3f4;
    display: ${props => props.isOpen ? 'flex' : 'none'};
    flex-flow: column;
    padding: 0.5em;
    gap: 0.5em;
    overflow: auto;
    transition: 0.5s;
    ::-webkit-scrollbar {
        width: 0;
    }
`

const SideWrapper = styled.div`
    position: absolute;
    top: 0.5em;
    left: 2em;
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #297F76;
    cursor: pointer;
    transition: all 0.3s ease-in;
    :hover {
        color: #7ab0ab;
    }
`
