import React, { useMemo } from 'react'
import styled from 'styled-components'
import TableBodyCell from '../../procedures/Q8/Layouts/Table/TableBodyCell'
import { CustomerOrder, GrowerDetail } from '../../../../../modules/API/Seller/models/tableData'
import TableCellInput from './TableCellInput'

interface Props extends CustomerOrder {
  index: number,
  Growers: GrowerDetail[],
  handleTopOrderChange: (e: React.ChangeEvent<HTMLInputElement>, customer: number) => void
}

const SellerBodyBlockElement = ({
  Growers, index, OrderDetail, handleTopOrderChange, Customer
}: Props) => {


  // NEED MEMOIZATION
  const culcGivenCustomerPalletByGrowers = useMemo(() => {
    return Growers.reduce((acc, cur) => {
      return acc + cur.Customers[index].OrderDetail.Order_Pallets

    }, 0)
  }, [Growers, index])

  const color = 
  OrderDetail.Order_Pallets === 0 && culcGivenCustomerPalletByGrowers === 0 ? "#E9F3F2" :
    OrderDetail.Order_Pallets - culcGivenCustomerPalletByGrowers === 0 ? ""
    : OrderDetail.Order_Pallets - culcGivenCustomerPalletByGrowers < 0 ? '#B4AF81'
    : '#B48186' 
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleTopOrderChange(e, Customer.CustomerID)
  }
  return (
    <TableBodyCell
      background={color}
    >
      <IconWrapper
        transparant
      >
        {OrderDetail.Order_Pallets - culcGivenCustomerPalletByGrowers}
      </IconWrapper>
      <TableCellInput
        value={OrderDetail.Order_Pallets}
        onChange={handleChange}
        
      />
    </TableBodyCell>
  )
}
const IconWrapper = styled.div<{isRotated?: boolean, transparant?: boolean}>`
  position: absolute;
  left: 0.3em;
  top: 0.35em;
  background-color: ${props => props.transparant ? "transparent" : '#E9F3F2'} ;
  padding: 0 0.2em;
  i {
    transform: ${props => props.isRotated ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.3s;
    font-size: 1.1em;
  }
`
export default SellerBodyBlockElement