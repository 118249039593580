import { requests } from "../config";
import { IProductCustomerItem, OrderDetail } from "./models/detailsProductCustomer";
import { IOrderPostData } from "./models/orderPostData";
import { IOrderModifyData, IOrderPutData, IPatchOrderData } from "./models/orderPutData";

export const Orders = {
    getOrders: (date1: string, date2: string) => requests.get(`order?fromDate=${date1}&toDate=${date2}`, 'node'),
    getOrder: (id: number) => requests.get(`order/${id}`, 'node'),
    createOrder: (data: IOrderPostData) => requests.post(`order`, 'node', data),
    createOrderGo: (data: IOrderPostData) => requests.post(`order`, 'go', data),
    editOrder: (id: number, data: IOrderPutData) => requests.put(`order/${id}`, 'node', data),
    deleteOrder: (id: number) => requests.delete(`order/${id}`, 'node'),
    getGoodPrice: (
        date: string, 
        // growerID: number, 
        customerID: number,
        productID: number,
        varietyID: number,
        sizeID: string,
        packID: number
    ) => requests.get(`price?Date=${date}&GrowerID=0&CustomerID=${customerID}&ProductID=${productID}&VarietyID=${varietyID}&Size=${sizeID}&PackID=${packID}&QualityID=0`, 'go'),
    modifyOrder: (
        orderType: number, orderID: number, data: IOrderModifyData
        ) => requests.put(`order/${orderType}/${orderID}/`, 'go', data),
    passNewOrderToWS: (
         data: any
        ) => requests.post(`order/ws`, 'go', data),
    patchOrderDataRemarks: (data: IPatchOrderData) => requests.patch(`delivery/15?`, 'node', data),
    getExistOrdersForClients: (date: string) => requests.get(`customer/with_orders_count?Date=${date}`, 'go'),
    changeOrderStatus: (orderID: number, status: number) => requests.put(`order/${orderID}`, 'node', {Status: status}),
    getOrdersWithDetails: (date: string, statuses: string) => requests.get(`order/orders_with_details?Date=${date}&Statuses=${statuses}`, 'go'),
    getDetailsByProductCustomer: (date: string): Promise<IProductCustomerItem[]> => requests.get(`order/details_by_products_customers?Date=${date}`, 'go'),
    updateProductCustomer: (data: OrderDetail[]): Promise<IProductCustomerItem[]> => requests.put(`order/details`, 'go', data),
    getOrdersGo: (date: string, warehouseID: number) => requests.get(`order/orders?${date}&WarehouseID=${warehouseID}`, 'go'),
}
