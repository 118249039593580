import React from 'react'

type Props = {
  search?: string
}

const Table = ({
  search
}: Props) => {
  return (
    <div>Table</div>
  )
}

export default Table