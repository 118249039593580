import React from 'react'
import styled from 'styled-components'
import selectOnFocus from '../../../../../modules/autoselect'

type Props = {
  value: string | number,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TableCellInput = ({
  value,
  onChange
}: Props) => {
  return (
    <Input 
      type='number'
      value={value}
      onChange={onChange}
      onClick={selectOnFocus}
    />
  )
}
const Input = styled.input`
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: transparent;
  text-align: right;
  outline: none;
  :focus {
        animation: 2s pulse infinite;
    }
    @keyframes pulse {
        0% {
            border: 2px solid #eff3f4; 
        }
        50% {
            border: 2px solid #80e081; 
        }
        100% {
            border: 2px solid #eff3f4; 
        }
    }
`
export default TableCellInput