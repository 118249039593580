import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/reducers/rootReducer'
import { IWorkerItem } from '../../modules/API/Defaults/models/workers'

type Props = {
  workerID: number
}

const useWorkerInfo = ({
  workerID
}: Props) => {
  const workerList: IWorkerItem[] = useSelector((state: RootState) => state.defaults.workerList)
  const worker = workerList.find(item => item.WorkerId === workerID)

  return {
    Role_108: worker?.Role_108 || 0,
  }
}


const hardCodedNames = {
  5: 'מנהל',
}
export default useWorkerInfo