import { useEffect, useMemo } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { SAVE_CLIENTNAME, SELECT_AZMANA, SELECT_CURRENT_ORDER } from "../../../../../../store/actions/actionsTypes";
import history from "../../../../../../history";
import { PackageIcon, ShopBagIcon, LocationIcon, PalletIcon, CalendatDay, BoxesIcon } from "../../../../../generic/icons/Icons";
import { LINKS } from "../../../../../Routs/config";
import { convertToDate } from "../../../../../modules/convertDateString";

export const AzmanotItemWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;
    background: #eff3f4;
    border-radius: 20px;
    /* -webkit-filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51));
    filter: drop-shadow(4px 3px 3px rgba(41,127,118,0.51)); */
    z-index: 0;
    gap: 0.5em;
    padding: 0.5em 0;
`

export const TitleWrapper = styled.div`
    
    height: 2em;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 1em;
`
export const TitelContent = styled.div`
    position: relative;
    font-size: 1.1em;
    z-index: 0;
    text-transform: lowercase;
    color: black;
    display: flex;
    gap: 0.5em;
`
const BackgroundBox = styled.div`
    position: absolute;
    background: #ced3ff;
    z-index: -1;
    right: 0;
    width: 4em;
    height: 0.8em;
    bottom: -0.1em;
`
export const BodyContainer = styled.div`
    min-height: 1.8em;
    width: 100%;
    padding: 0 1em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;

`
export const StatusWrapper = styled.div`
    position: absolute;
    width: 4em;
    min-height: 2em;
    top: .5em;
    left: 0.4em;
    color: #fdfdfd;
    border-radius: 30px;
    transition: 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
   
`
export const Item = styled.div`
    position: relative;
    display: flex;
    max-width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: rgba(0,0,0, 0.8);
    padding-left: 0.5em;
    :nth-of-type(2) {
        white-space: nowrap;
    }
    i {
        margin-left: 0.5em;
        opacity: 0.9;
        color: #297f76;
    }
`

export default function LikutExpressAzmanotItem({
    Customer_Name, OrderID, azmanaNum, GroupDesc, GroupID, isAddIdToDesc,
    setAzmanaNum, Delivery_Date, Status, CustomerID, Type, WorkerID, TZSort,
    Packs, Products, Pallets15, SupplyPacks, checkForActiveLikutAzmana, clientList, item
}) {
    const [focusColor, setFocusColor] = useState(false);
    const [showStatus, setShowStatus] = useState('')
    const formatedDate = convertToDate(Delivery_Date)
    const [color, setColor] = useState('')
    const dispatch = useDispatch()
    const handlerColorSwitcher = () => {
        if (azmanaNum === OrderID) {
            setFocusColor(true)
        }
        else {
            setFocusColor(false)
        }
    }

    useEffect(() => {
        handlerColorSwitcher()
    }, [azmanaNum])

    useEffect(() => {
        if (Status === 0) {
            setColor('rgba(233,190,200,0.7)')
            setShowStatus('פתוחה')
        }
        else if (Status === 2) {
            setColor('gray')
            setShowStatus('הזמנה נבדקה')
        }
        else if (Status === 1) {
            setColor('#f7f5f2')
            setShowStatus('ליקוט')
        }
        else if (Status === 3) {
            setColor('rgba(164,184,183, 0.3)')
            setShowStatus('סיום ליקוט')
        }
        else if (Status === 4) {
            setColor('gray')
            setShowStatus('מוכנה למשלוח')
        }
        else if (Status === 5) {
            setColor('gray')
            setShowStatus('מוכנה לליקוט')
        }
        else if (Status === 9) {
            setColor('rgba(164,184,183, 0.3)')
            setShowStatus('סגורה')
        }
        else if (Status === 7) {
            setColor('gray')
            setShowStatus('השלמות')
        }

    }, [Status])

    const passFunc = () => {
        setAzmanaNum(OrderID)
        handlerColorSwitcher()
        dispatch({ type: SELECT_CURRENT_ORDER, payload: item })
        dispatch({ type: SELECT_AZMANA, payload: OrderID, group: GroupID, status: Status, client: CustomerID })
        dispatch({ type: SAVE_CLIENTNAME, payload: Customer_Name })
        history.push(LINKS.likut.likutExperess.palletSection)
    }
    let colorToggle = focusColor ? 'circle(150% at 16% 3%)' : 'circle(30.3% at 10% 90%)'
    return (
        <AzmanotItemWrapper
            style={{ background: color, }}
            onClick={() => 
                checkForActiveLikutAzmana(Status, CustomerID, 1, OrderID, passFunc, WorkerID, TZSort)
            }
        >
            <StatusWrapper style={{ color: 'black', background: color }} >
                {showStatus}
            </StatusWrapper>
            {
                GroupDesc?.trim() &&
                    <StatusWrapper style={{ color: 'black', background: '#fff', top: '65%' }} >
                        {GroupDesc}
                    </StatusWrapper>
                
            }

            <TitleWrapper>
                <TitelContent>
                    <BackgroundBox style={{ background: color }} />
                    {
                        isAddIdToDesc &&
                        <IdWrapper>
                            {CustomerID}
                        </IdWrapper>
                    }
                    {Customer_Name?.trim()}
                </TitelContent>
            </TitleWrapper>
            <BodyContainer>
                <Item >
                    {clientList?.hashTable[CustomerID]?.RampId} <LocationIcon />

                </Item>
                <Item>
                    {SupplyPacks} / {Packs} :אריזות <PackageIcon />
                </Item>

                <Item >
                    פריטים: {Products} <BoxesIcon />
                </Item>
            </BodyContainer>
            <BodyContainer>
                <Item >
                    {formatedDate} <CalendatDay />
                </Item>
                <Item >
                    {Pallets15} <PalletIcon />
                </Item>
                <Item >
                    {OrderID}<ShopBagIcon />
                </Item>

            </BodyContainer>


        </AzmanotItemWrapper>
    )
}

const IdWrapper = styled.span`
    padding: 0.1em 0.3em;
    background: #8CC53D;
    border-radius: 5px;
    font-size: 1em;
`