import React, {useState} from 'react'
import { OrderDetail } from '../../../../../../modules/API/Orders/models/detailsProductCustomer'
import styled from 'styled-components'
import SearchPackage from '../../../knisot/Create/Components/SearchPackage'
import { IPackagesItem } from '../../../../../../modules/API/Defaults/models/packages'
import CircleNumericInput from '../../../../../../generic/inputs/CircleNumericInput'
import SearchBlockWithTitle from '../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import { EditIcon } from '../../../../../../generic/icons/Icons'

interface Props extends OrderDetail {
    pacakges: IPackagesItem[]
    handleChange: (item: OrderDetail) => void
    index: number
    item: OrderDetail
}

const ProductModalNestedItem = ({
    pacakges,
    PackID,
    OrderPacks,
    item,
    handleChange
}: Props) => {
    const [packageID, setPackageID] = useState(PackID)
    const [orderPacks, setOrderPacks] = useState(OrderPacks)
    const [isOpenRemarkField, setIsOpenRemarkField] = useState<boolean>(false)

    const handleRemarkField = () => {
        setIsOpenRemarkField(!isOpenRemarkField)
    }
    const handleChangeQty = (qty: number) => {
        setOrderPacks(qty)
        handleChange({
            ...item,
            PackID: packageID,
            OrderPacks: qty
        })
    }
    const handleChangePackage = (id: number) => {
        setPackageID(id)
        handleChange({
            ...item,
            PackID: id,
            OrderPacks: orderPacks
        })
    }
    const handleChangeRemark = (remark: string) => {
        handleChange({
            ...item,
            Remarks: remark
        })
    }
  return (
    <SearchBlockWithTitle
        title={item.Customer_Name.trim()}
        
    >
        <MainWrapper>
            <FlexWrapper>
                <CircleNumericInput 
                    value={orderPacks}
                    changeFunc={handleChangeQty}
                    />
                <SearchPackage 
                    list={pacakges}
                    defaultID={packageID || PackID}
                    setPackageID={handleChangePackage}
                    placeholder='בחר סוג אריזה'
                />
                <Button
                    isPrimal={item.Remarks.trim() !== ''}
                    onClick={handleRemarkField}
                >
                    <EditIcon />
                </Button>
            </FlexWrapper>
            {
                isOpenRemarkField && (
                    <RemarkWrapper>
                        <TextField
                            placeholder='הערה'
                            value={item.Remarks.trim()}
                            onChange={(e) => handleChangeRemark(e.target.value)} 
                        />
                    </RemarkWrapper>
                )
            }
            
        </MainWrapper>
    </SearchBlockWithTitle>
  )
}
const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 0.5em;
    flex-direction: column;
`
const FlexWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 0.5em;
`
const RemarkWrapper = styled.div`
    padding: 0.5em;
`
const TextField = styled.textarea`
width: 100%;
    padding: 1em 0.5em;
    letter-spacing: 0.5px;
    border-radius: 30px;
    font-size: 1.2em;
    direction: rtl;
    border: 2px solid #7ab0ab;
    outline: none;
    color: #757575;
    resize: none;
    ::-webkit-scrollbar {
        width: 0
    }
    :disabled {
        background: #ffff;
    }
`
const Button = styled.button<{isPrimal?: boolean}>`
    padding: 0.5em 1em;
    border: none;
    border-radius: 10px;
    color: ${props => props.isPrimal ? "tomato" : "#7ab0ab"} ;
    cursor: pointer;
`
export default ProductModalNestedItem