import { ISellerReqData } from '../../../../../modules/API/Seller/models/reqests'
import { SELLER } from '../../../../../modules/API/Seller/seller'
import { Forecasts } from '../../../../../modules/API/Forcasts/forcasts'
import { IForecastReqData } from '../../../../../modules/API/Forcasts/models/forcastProductitem'
import { AxiosRequestConfig } from 'axios'


const useSellerController = () => {
  const createForecast = async (data: IForecastReqData, config?: AxiosRequestConfig) => {
    try {
      const res = await Forecasts.createForecast([data])
      return res
    } catch (error) {
      console.log(error)
    }
  }
  const updateForecast = async (data: IForecastReqData, config?: AxiosRequestConfig) => {
    try {
      const res: string = await Forecasts.updateForecast(data)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const putData = async (data: ISellerReqData, config?: AxiosRequestConfig) => {
    try {
      const res = await SELLER.putOrder(data)
      return res
    } catch (error) {
      console.log(error)
    } finally {

    }
  }

  const postData = async (data: ISellerReqData, config?: AxiosRequestConfig) => {
    try {
      const res = await SELLER.postOrder(data)
      return res
    } catch (error) {
      console.log(error)
    } finally {

    }
  }

  return {
    postData,
    putData,
    createForecast,
    updateForecast
  }
}

export default useSellerController