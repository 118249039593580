import { requests } from "../config";
import { TRANSPORT_AREAS } from "../settings";
import { IChangeKavData, IShowDriverKav, IUpdateCarDriver } from "./models/fetchData";


export const Trucks = {
    getOrdersTree: (date: string) => requests.get(`distribution/orders_tree?date=${date}`, 'go'),
    changeKav: (data: IChangeKavData) => requests.put('distribution/change_line', 'go', data),
    patchDriver: (kavID: number, data: {CarId: number}) => requests.put(`${TRANSPORT_AREAS}${kavID}`, 'node', data),
    updateCarDriver: (kavID: number, data: IUpdateCarDriver) => requests.put(`transport_area/by_date/${kavID}`, 'go', data),
    showDriverKav: (kavID: number, data: IShowDriverKav) => requests.put(`transport_area/open/${kavID}`, 'go', data),
    sortKavByOrders: (data: number[]) => requests.put(`distribution/distr_order`, 'go', data),
    bulkConfirm: (data: IUpdateCarDriver[]) => requests.put(`transport_area/update_by_date/bulk`, 'go', data),
    setSort: (data: number[]) => requests.put(`transport_area/sort/bulk`, 'go', data),
}