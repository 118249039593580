

const teudotMainEN = {
  "teudot_main_title": "Shipping certificates",
  "teudot_main_search_placeholder": "Search for a certificate",
  "teudot_main_button_new": "Create certificate",
  "teudot_main_element_swipe_edit": "Update",
}

const teudotCreatePageModalTitleEN = {
  "teudot_create_modal_title_title": "Certificate title",
  "teudot_create_modal_title_client": "Select a client",
  "teudot_create_modal_title_seller": "Select a seller",
  "teudot_create_modal_title_teudaType": "Select a certificate type",
  "teudot_create_modal_title_car": "Car number",
}

const teudotCreatePageEN = {
  "teudot_create_page_title": "New certificate",
  "teudot_create_page_title_ids": "Certificate: {{id}}",
  "teudot_create_page_search_placeholder": "Search",
  "teudot_create_page_tabs_rest": "Inventory",
  "teudot_create_page_tabs_taken": "Selected",
  "teudot_create_page_element_swipe_take": "Add",
  "teudot_create_page_element_swipe_cancel": "Remove",
}

const createProductModalEN = {
  "create_product_modal_title_new": "New product",
  "create_product_modal_title_created": "Product {{id}}",
  "create_product_modal_search": "Search",
  "create_product_modal_product": "Select a product",
  "create_product_modal_size": "Select a size",
  "create_product_modal_grower": "Grower",
  "create_product_modal_grower_button": "All",
  "create_product_modal_grower_package": "Select a package",
  "create_product_modal_grower_qty": "Quantity",
  "create_product_modal_calcMethod": "Calculation method",
  "create_product_modal_weight": "Weight",
  "create_product_modal_price": "Price",
  "create_product_modal_pallet": "Product type",
  "create_product_modal_includeTareWeight": "Include tare weight",
  "create_product_modal_remark": "Remark",
  "create_product_modal_button_save": "Save product",
}

export const teudotTranslationEN = {
  teudot: {
    ...teudotMainEN,
    ...teudotCreatePageModalTitleEN,
    ...teudotCreatePageEN,
    ...createProductModalEN
  }
}
