import React, { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import SearchBlockWithTitle from '../../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import CircleNumericInput from '../../../../../../../generic/inputs/CircleNumericInput'
import SearchProductWithVariety from '../SearchProductWithVariety'
import { IProductsWithVarietyItem } from '../../../../../../../modules/API/Defaults/models/compliteProducts'
import { IPalletTypeItem } from '../../../../../../../modules/API/Defaults/models/pallets'
import SearchPalletType from '../SearchPalletType'
import { IPackagesItem } from '../../../../../../../modules/API/Defaults/models/packages'
import SearchPackage from '../SearchPackage'
import { IDetailsSelected } from '../../CreateKnisa'
import ControlButton from '../../../../../../../generic/buttons/ControlButton'
import { IEntriesKnisotItem } from '../../../../../../../modules/API/Knisot/models/entrie'
import { IEntriePutData } from '../../../../../../../modules/API/Market/models/putRequestInterface'
import selectOnFocus from '../../../../../../../modules/autoselect'
import { useKnisaConfig } from '../../config'
import { ISizeItem } from '../../../../../../../modules/API/Products/models/size'
import SearchSize from '../SearchSize'
import CheckBoxWithTitle from '../../../../../../../generic/particles/CheckBox'
import SearchInput from '../../../../../../../generic/inputs/inputSearch'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../../store/reducers/rootReducer'
import { SET_IS_OPEN } from '../../../../../../../../store/reducers/printer'
import { EXTERNAL } from '../../../../../../../modules/API/External/external'
import CircleActiveFilterBtn from '../../../../../../../generic/particles/CircleActiveFilterBtn'
import { ScalesIcon } from '../../../../../../../generic/icons/Icons'
import LoadingDotsAnimated from '../LoadingDotsAnimated'
import { vibrationFunc } from '../../../../../../../modules/vibrationModule'
import useDataFetcher from '../../../hooks/useFetchData'
import { IBomItem } from '../../../../../../../modules/API/Knisot/models/bom'
import { Knisot } from '../../../../../../../modules/API/Knisot/knisot'
import { useTranslation } from 'react-i18next'

type Props = {
  productsWithVariety: IProductsWithVarietyItem[]
  palletList: IPalletTypeItem[]
  packageList: IPackagesItem[]
  cashedDetails: IEntriesKnisotItem | null
  sizeList: ISizeItem[]
  handleCreateDetails: (data: IDetailsSelected, addictional?: { multyplier: number, isCopyWithWeigh: boolean, isEdit: boolean}) => void
  handleChangeDetails: (data: IEntriePutData) => void,
  handleControlModal: () => void
}

const ModalKnisaAddProduct = ({
  productsWithVariety, palletList, packageList, cashedDetails,
  handleCreateDetails, handleChangeDetails, sizeList, handleControlModal
}: Props) => {
  const [t] = useTranslation(['knisa', 'buttons'])
  const dispatch = useDispatch()
  const scales = useSelector((state: RootState) => state.printerReducer.scales)
  const config = useKnisaConfig()
  const defaultPackage = cashedDetails?.TypePack || config.create.details.package[0].value as number
  const defaultPallet = cashedDetails?.TypePallet || config.create.details.pallet[0].value as number
  const defaultProductID = cashedDetails?.ProductID || config.create.details.productID[0].value as number
  const defaultVarietyID = cashedDetails?.VarietyID || config.create.details.varietyID[0].value as number
  const [productQty, setProductQty] = useState<number>(cashedDetails?.AmountPack || 0)
  const [productID, setProductID] = useState<number>(defaultProductID || 0)
  const [varietyID, setVarietyID] = useState<number>(defaultVarietyID || 0)
  const [price, setPrice] = useState<number>(cashedDetails?.PriceWeight || 0)
  const [weight, setWeight] = useState<number>(cashedDetails?.Gross || 0)
  const [remark, setRemark] = useState<string>(cashedDetails?.Remark || '')
  const [palletID, setPalletID] = useState<number>(defaultPallet || 0)
  const [prevPalletID, setPrevPalletID] = useState<number>(cashedDetails?.TypePallet || 0)
  const [palletQty, setPalletQty] = useState<number>(defaultPackage ? cashedDetails?.AmountPallets || 1  :  cashedDetails?.AmountPallets || 0)
  const [packageID, setPackageID] = useState<number>(defaultPackage || 0)
  const [sizeID, setSizeID] = useState<ISizeItem | null>(null)
  const [location, setLocation] = useState<string>('')
  const [multiplier, setMultiplier] = useState<number>(0)
  const [isCopyWeight, setIsCopyWeight] = useState<boolean>(config.create.details.multuplier[0].value as boolean)
  const [externalPalletNum, setExternalPalletNum] = useState<number>(cashedDetails?.ExternalPallet || 0)
  const [isLoadScales, setIsLoadScales] = useState<boolean>(false)

  const withoutPalletID = useMemo(() => {
    return palletList.find((item) => item.Real_Pallete === 0)?.Pallete_Type
  }, [palletList])

  const isActiveSave = useMemo(() => {
    return productQty > 0 && productID !== 0 &&
      (config.create.details.weight[0].value ? weight > 0 : true) &&
      palletID !== 0 && packageID !== 0
  }, [
    productQty, 
    config,
    productID,
    weight,
    palletID, 
    packageID
  ])

  const fetchScale = async () => {
    if(!scales || scales === 0 ) {
      handleControlModal()
      dispatch({type: SET_IS_OPEN, payload: true})
      return
    }
    try {
      setIsLoadScales(true)
      const res = await EXTERNAL.getWeightFromScales(scales)
      if(!Number.isNaN(res.Weight)) {
        setWeight(res.Weight)
        vibrationFunc()
      }

    } catch (err) {

    } finally {
      setIsLoadScales(false)
    }
  }


  const handleChangePalletType = useCallback((id: number) => {
    if (id === withoutPalletID) {
      setPalletQty(0)
    } else if (id !== 0 && prevPalletID === withoutPalletID) {
      setPalletQty(1)
    } 
    setPalletID(id)
    if(id !== 0) {
      setPrevPalletID(id)
    }
  }, [withoutPalletID, prevPalletID])

  const handleCreateDetailsFunc = (isEdit: boolean) => {
    handleCreateDetails({
      productQty,
      productID,
      varietyID,
      price,
      weight,
      remark,
      palletID,
      packageID,
      externalPalletNum,
      palletQty,
      sizeID: sizeID?.Code || 0,
    }, multiplier ? { multyplier: multiplier, isCopyWithWeigh: isCopyWeight, isEdit: isEdit } : undefined)
  }

  const handleChangesDetailsFunc = (isEdit: boolean) => {
    if (!cashedDetails) return
    console.log(sizeID?.Code || 0)
    handleChangeDetails({
      Details: [{
        DetailNum: cashedDetails.DetailNum,
        ProductID: productID,
        VarietyID: varietyID,
        PriceWeight: price,
        Gross: weight,
        Remark: remark,
        TypePallet: palletID,
        TypePack: packageID,
        AmountPack: productQty,
        ExternalPallet: +externalPalletNum,
        AmountPallets: palletQty,
        SizeNum: sizeID?.Code || 0,
        // TODO: add location
      }]
    })
    if (multiplier) {
      handleCreateDetailsFunc(isEdit)
    }
  }
  const {
    data: bom,
    isLoading: isLoadBom
  } = useDataFetcher<IBomItem>(
    Knisot.getBomWeight, `ProductID=${productID}&VarietyID=${varietyID}&SizeID=${sizeID?.Code || 0}&PackID=${packageID}`
  )
  useEffect(() => {
    if(!bom || bom.DF_PackWeigt === 0) return
    setWeight(+(bom.DF_PackWeigt).toFixed(2) * productQty)
  }, [bom, productQty])
  return (
    <MainWrapper>
      <BodyWrapper>
       
        <SearchBlockWithTitle
          title={[t("knisa_create_modal_product_externalEntry"), '']}
        >
          <SearchInput 
            input={externalPalletNum + ''}
            value={''}
            type={'number'}
            iconHide
            setInput={setExternalPalletNum}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={[
            t("knisa_create_modal_product_pallet"), 
            t("knisa_create_modal_product_qty")
          ]}
        >
          {
            palletID ?
              <CircleNumericInput 
                defaultValue={palletQty}
                changeFunc={(e: number) => setPalletQty(e)}
              /> : ''

          }
          <SearchPalletType
            list={palletList}
            defaultID={palletID}
            setPalletID={handleChangePalletType}
            required
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={[t("knisa_create_modal_product_product"), t("knisa_create_modal_product_size")]}
        >
          <SearchSize
            list={sizeList}
            defaultID={sizeID}
            setSizeID={setSizeID}
          />
          <SearchProductWithVariety
            defaultProductID={productID}
            setProductID={setProductID}
            defaultVarietyID={varietyID}
            setVarietyID={setVarietyID}
            list={productsWithVariety}
            required
          />
        </SearchBlockWithTitle>

        <SearchBlockWithTitle
          title={[
              t("knisa_create_modal_product_package"), 
              t("knisa_create_modal_product_qty")
            ]}
        >
          {
            isLoadBom ? <LoadingDotsAnimated /> : 
            <CircleNumericInput
              defaultValue={productQty}
              changeFunc={(e: number) => setProductQty(e)}
            />
          }
          <SearchPackage
            list={packageList}
            defaultID={packageID}
            setPackageID={setPackageID}
            required

          />

        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={[
            t("knisa_create_modal_product_weight"), 
            t("knisa_create_modal_product_price")
          ]}
        >
          <Input
            defaultValue={price}
            placeholder='מחיר'
            type="number"
            onChange={(e) => setPrice(Number(e.target.value))}
            onClick={selectOnFocus}
          />
          <InputWrapper>
            <SideWrapper>
              <CircleActiveFilterBtn
                func={fetchScale}
              >
                {
                  isLoadScales ?
                  <LoadingDotsAnimated />
                  :
                  <ScalesIcon />
                } 
              </CircleActiveFilterBtn>
            </SideWrapper>
            <Input
            value={+weight.toFixed(2)}
              // defaultValue={weight}
              placeholder='משקל'
              type="number"
              onChange={(e) => setWeight(Number(e.target.value))}
              onClick={selectOnFocus}
            />
          </InputWrapper>
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={t("knisa_create_modal_product_detection")}
        >
          <SearchInput 
            setInput={setLocation}
            input={location}
            iconHide
            value={'איתור'}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={t("knisa_create_modal_title_remark")}
        >
          <TextArea
            defaultValue={remark}
            placeholder='הערה'
            onChange={(e) => setRemark(e.target.value)}
          />
        </SearchBlockWithTitle>
        <SearchBlockWithTitle
          title={[t("knisa_create_modal_product_copy"), t("knisa_create_modal_product_includeWeight")]}
        >
          <CheckBoxWithTitle
            title=''
            stateFunc={setIsCopyWeight}
            defaultState={isCopyWeight}
          />
          <Input
            defaultValue={multiplier}
            placeholder='multiplier'
            type="number"
            onChange={(e) => setMultiplier(Number(e.target.value))}
            onClick={selectOnFocus}
          />
        </SearchBlockWithTitle>
      </BodyWrapper>
      {
        isActiveSave ?
          cashedDetails ?
            <ControlButton
              lable={t("buttons:save")}
              handleClick={() => handleChangesDetailsFunc(true)}
            /> :
            <ControlButton
              lable={t("buttons:save")}
              handleClick={() => handleCreateDetailsFunc(false)}

            /> : ''
      }
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  align-items: center;
`

const BodyWrapper = styled.div`
  width: 90%;
  max-height: 83%;
  display: flex;
  flex-flow: column;
  gap: 1em;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;

  }

`
const Input = styled.input`
  width: 100%;
  height: 3.4em;
  border-radius: 30px;
  background: #EFF3F4;
  border: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  text-align: end;
  padding: 0 1em;
  font-size: 1em;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
`

const TextArea = styled.textarea`
  appearance: none;
  border-radius: 30px;
  border: none;
  width: 100%;
  background: #EFF3F4;
  padding: 1em;
  font-size: 1em;
  font-family: 'Assistant', sans-serif;
  outline: none;
  direction: rtl;
  color: #757575;
  ::placeholder {
    opacity: 0.5;
  }
  ::-webkit-scrollbar {
    width: 0;
  }
`
const InputWrapper = styled.div`
  position: relative;
`

const SideWrapper = styled.div`
  position: absolute;
  left: 0.1em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

`
export default ModalKnisaAddProduct