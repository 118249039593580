import {useImperativeHandle, useRef, } from 'react'
import styled from "styled-components"
import selectOnFocus from '../../modules/autoselect'
import { SearchIcon, CloseIcon } from "../icons/Icons"
import PropTypes from 'prop-types'
const InputWrapper = styled.div`
    position: relative;
    width: ${props => props.size === 'full' ? '100%'
                : props.size === 'large' ? '250px'
                    : props.size === 'medium' ? '200px'
                        : props.size === 'small' ? '150px'
                            : 'none'
    };
    /* background: #eff3f4; */
    /* border-radius: 30px; */
`
const IconWrapper = styled.div`
    position: absolute;
    background: #eff3f4;
    /* background: red; */
    top: .3em;
    left: 0.3em;
    width: 2.7em;
    height: 2.7em;
    border-radius: 50%;
    display: ${props => !props.visible ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    i {
        display: ${props => props.iconHide ? 'none' : 'block'};
        font-size: 0.9em;
        color: #838484;
    }
    .fa-times {
        cursor: pointer;
    }
`

const Input = styled.input`
    width: 100%;
    height: 3.4em;
    border: ${props => props.borderColor ? `2px solid ${props.borderColor}` : '2px solid #eff3f4'} ; 

    padding: 0.5em 1em; 
    letter-spacing: 0.5px;
    outline: none;
    text-align: right;
    z-index: 0;
    background: #eff3f4;
    font-size: 1em;
    border-radius: 30px;
    -webkit-appearance: none;
    appearance: none;
    color: #8a97a5;
    ::placeholder {
        opacity: 0.5;
        
    }
    :focus {
        animation: 2s pulse infinite;
    }
    @keyframes pulse {
        0% {
            border: 2px solid #eff3f4 ; 
        }
        50% {
            border: 2px solid #80e081; 
        }
    }
`



const SearchInput = ({
    input, setInput, value, fieldClietns, disabled, defaultValue,
    iconHide, type, size, enterFunc, ref, isEnableFocusSelect, borderColor
}) => {
    
    const clearInput = () => {
        setInput('')
    }
    const handleChange = (e) => {
        setInput(e.target.value)
    }
    // console.log('render input')
    let controlToggle = input?.length > 0 ? <CloseIcon handler={clearInput} /> : <SearchIcon />
    return(
        <InputWrapper size={size}>
            {
                !disabled &&
                    <IconWrapper iconHide={iconHide} >{controlToggle}</IconWrapper>

            }
            <Input
                style={{borderRadius: fieldClietns ? '30px 30px 0 30px' : '30px'}}
                type={type}
                placeholder={value}
                defaultValue={defaultValue}
                value={input}
                onClick={type === 'number' || isEnableFocusSelect ? selectOnFocus  : undefined}
                readOnly={disabled}
                onChange={handleChange}
                min={0}
                max={9999}
                ref={ref}
                borderColor={borderColor}
                onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        enterFunc(e.target.value)
                    }

                }}
                
             />
        </InputWrapper>
    )
}
export default SearchInput;
SearchInput.propTypes = {
    input: PropTypes.string,
    setInput: PropTypes.func.isRequired,
    value: PropTypes.string,
    fieldClietns: PropTypes.bool,
    disabled: PropTypes.bool,
    iconHide: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
    defaultValue: PropTypes.string,
    borderColor: PropTypes.string,
}

SearchInput.defaultProps = {
    size: 'full',
    type: 'text',
    value: 'חיפוש',
    setInput: (e) => console.log(e),
    enterFunc: (e) => console.log(e),
    ref: null,
    isDisableFocusSelect: true,
    isEnableFocusSelect: false,
    defaultValue: '',
    borderColor: ''
}