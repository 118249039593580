import React, {useState, useMemo, useEffect, useContext} from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import { useHistory, useParams } from 'react-router-dom'
import ButtonLayout from '../Layouts/ButtonLayout'
import ControlButton from '../../../../../generic/buttons/ControlButton'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import ColumModal from '../../../../../generic/Layouts/ColumModal'
import ModalKnisaTitle from './Components/Modal/ModalKnisaTitle'
import CircleActiveFilterBtn from '../../../../../generic/particles/CircleActiveFilterBtn'
import { PlusIcon, SettingMiksher } from '../../../../../generic/icons/Icons'
import DatePickerModule from '../../../../../generic/datePicker/datePicker'
import BodyScrollContainer from '../../../../../generic/Layouts/Wrappers/BodyScrollContainer'
import useDataFetcher from '../hooks/useFetchData'
import { IGrowerNodeGetResponse } from '../../../../../modules/API/Defaults/models/growers'
import { Defaults } from '../../../../../modules/API/Defaults/defaults'
import { ICarItemResponse } from '../../../../../modules/API/Defaults/models/cars'
import { IProductsWithVarietyItem } from '../../../../../modules/API/Defaults/models/compliteProducts'
import ModalKnisaAddProduct from './Components/Modal/ModalKnisaAddProduct'
import { IWarhouseListItem } from '../../../../../modules/API/Defaults/models/warhouseList'
import TitleKnisa from '../Components/TitleKnisa'
import { IEntrie, IEntrieDetails, IEntriesKnisotItem } from '../../../../../modules/API/Knisot/models/entrie'
import { IGetReuqestResponseNode, IPalletTypeItem } from '../../../../../modules/API/Defaults/models/pallets'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../store/reducers/rootReducer'
import { IPackagesItem } from '../../../../../modules/API/Defaults/models/packages'
import TitleCreateKnisa from './Components/TitleCreateKnisa'
import { Knisot } from '../../../../../modules/API/Knisot/knisot'
import { IEntriesItem } from '../../../../../modules/API/Miun/models/sortingList'
import TitlesList from '../../../../../generic/titlesRowItem/TitlesList'
import EntryDetailsItem from '../Components/EntryDetailsItem'
import { IEntriePutData } from '../../../../../modules/API/Market/models/putRequestInterface'
import SearchInput from '../../../../../generic/inputs/inputSearch'
import { CLEAR_ENTRY_STATE, CREATE_ENTRIE_DETAILS, LOAD, LOAD_END } from '../../../../../../store/actions/actionsTypes'
import { createObjectsArray } from './Components/Helpers/createObjectsArray'
import { useKnisaConfig } from './config'
import { ISizeItem } from '../../../../../modules/API/Products/models/size'
import { Product } from '../../../../../modules/API/Products/products'
import { LINKS } from '../../../../../Routs/config'
import { ITeudotTypeItem } from '../../../../../modules/API/Defaults/models/teudotTypes'
import { IPrinterData } from '../../../../../modules/API/Defaults/models/printSticker'
import { SET_IS_OPEN } from '../../../../../../store/reducers/printer'
import { ContextModal } from '../../../../../modules/modal/modalContext'
import { useTranslation } from 'react-i18next'

type Props = {
  refetch?: () => void,
  print?: (entryNum: number | string) => void
}
export interface IDetailsSelected {
  productQty: number,
  productID: number,
  varietyID: number,
  price: number,
  weight: number,
  remark: string,
  palletID: number,
  palletQty: number,
  packageID: number,
  externalPalletNum: number,
  sizeID: number
}

const CreateKnisa = ({ print}: Props) => {
  const [t] = useTranslation(['knisa', 'buttons'])
  const history = useHistory()
  const dispatch = useDispatch()
  const {id} = useParams<{id: string}>()
  const [modal, setModal] = useContext(ContextModal);
  const user = useSelector((state: RootState) => state.mainReducer.user)
  const isOffice = user?.rights?.includes('OFFICE')
  const printerState = useSelector((state: RootState) => state.printerReducer)

  const {title, details} = useSelector((state: RootState) => state.entryReducer.create)
  const packageList: IPackagesItem[] = useSelector((state: RootState) => state.defaults.packageList)
  const [isOpenTitle, setIsOpenTitle] = useState<boolean>(false)
  const [isOpenProduct, setIsOpenProduct] = useState<boolean>(false)
  const [knisaTitleData, setKnisaTitleData] = useState<IEntrieDetails | null>(title)
  const [knisaDetailsData, setKnisaDetailsData] = useState<IEntriesKnisotItem[] | null>(details)
  const [selectedItem, setSelectedItem] = useState<IEntriesKnisotItem | null>(null)
  const [searchDetails, setSearchDetails] = useState<string>('')
 
  // console.log(selectedItem)
  useEffect(() => {
    if(title || id ) return
    setTimeout(() => {
      setIsOpenTitle(true)
    }, 100)
  }, [])
  const {
    data: growerList
  } =  useDataFetcher<IGrowerNodeGetResponse>(
    Defaults.getGrowerList, 'data'
  )

  const {
    data: carList
  } = useDataFetcher<ICarItemResponse>(
    Defaults.getCarList, 'data'
  )
  const {
    data: warehouseList
  } = useDataFetcher<IWarhouseListItem[]>(
    Defaults.getWarhouseList, 'data'
  )
  const {
    data: productsWithVariety
  } = useDataFetcher<IProductsWithVarietyItem[]>(
    Defaults.getProductsWithVariety, 'data'
  )
  const {
    data: palletTypeList
  } = useDataFetcher<IGetReuqestResponseNode>(
    Defaults.getPalletTypes, 'data'
  )
  const {
    data: sizeList
  } = useDataFetcher<{sizes: ISizeItem[]}>(
    Product.getSizes, 'data'
  )
  const {
    data: teudaTypes
  } = useDataFetcher<ITeudotTypeItem[]>(
    Defaults.getTblTeudotTypes, 'כ'
  )

  const isAllowToShowTitle = isOpenTitle && warehouseList && teudaTypes
  // console.log(config)
  const handleControlTitle = () => {
    setIsOpenTitle(!isOpenTitle)
  }
  const handleControlProduct = () => {
    setIsOpenProduct(!isOpenProduct)
    setSelectedItem(null)
  }

  const handlePrintKnisa = async (entryNum: number | string) => {
    const selectedPrinter = printerState.printerID
    if(!selectedPrinter) {
        dispatch({type:SET_IS_OPEN, payload: true})
        return
    }
    const printerData: IPrinterData = {
        ZPL_15_75: 4,
        PalletEntryNum: entryNum
    }
    dispatch({type: LOAD})
    try {
        const res = await Defaults.printSticker(selectedPrinter, printerData)
        setModal({
            open: true,
            value: `כניסה מספר ${entryNum} הודפס בהצלחה`,
            mood: true,
        })
        setTimeout(() => {
            setModal({
                open: false,
                value: '',
                mood: false
            })
        }, 3000)
    } catch {
        setModal({
            open: true,
            value: `כישלון בהדפסת כניסה מספר ${entryNum}`,
            mood: false
        })
        setTimeout(() => {
            setModal({
                open: false,
                value: '',
                mood: false
            })
        }, 3000)    
    } finally {
        dispatch({type: LOAD_END})

    }
}
  const changeDeteilsItem = async (data: IEntriePutData ) => {
    if(!knisaTitleData) return
    try {
      const {Details}: IEntrieDetails = await Knisot.putDetailsKnisa(knisaTitleData.EntryNum, data)
      dispatch({type: 'CREATE_ENTRIE_DETAILS', payload: Details})
      setKnisaDetailsData(Details)
      handleControlProduct()
    } catch (error) {
      console.log(error)
    }
  }

  const handleCreateTitle = async (data: IEntrieDetails) => {
   
    try {
      const {Details, ...rest} = data
      const res: IEntrie = await Knisot.createTitleKnisa(rest)
      dispatch({type: 'CREATE_ENTRIE_TITLE', payload: res})
      setKnisaTitleData({...res, Details})
      handleControlTitle()
    } catch (err: any) {
      let error
      if (err.response.status === 405) {
        error = `אסמכתא כבר נקלטה בתאריך ${err.response.data.error}`
      }
      setModal({
        open: true,
        value: error || 'שגיאה ביצירת כניסה',
        mood: false
      })
      setTimeout(() => {
        setModal({
          open: false,
          value: '',
          mood: false
        })
      }, 3000)
    }
  }

  const handleAddDetailesItem = async (data: IDetailsSelected, addictional?: {multyplier: number, isCopyWithWeigh: boolean, isEdit: boolean}) => {
    if(!knisaTitleData) return
    const dataFormed: IEntriesKnisotItem = {
      AmountPack: data.productQty,
      DetailNum: 0,
      EntryNum: knisaTitleData.EntryNum,
      Gross: data.weight,
      PalletDetailsCount: 0,
      // ExternalPallet: 0,
      Pack_Name: '',
      Invoice: 0,
      ExternalPallet: +data.externalPalletNum || 0,
      // id?: string;
      // isSelected?: boolean;
      OutPackId: 0,
      // Given?: boolean,
      // Type?: string
      AmountPallets: data.palletQty,
      Discount: 0,
      Pallete_Desc: '',
      PriceWeight: data.price,
      ProductDesc: '',
      ProductID: data.productID,
      SizeDesc: '',
      SizeNum: data.sizeID,
      TypePack: data.packageID,
      TypePallet: data.palletID,
      VarietyDesc: '',
      VarietyID: data.varietyID,
      // Image: '',
      GeneralProductID: 0,
      CalculationMethod: 0,
      AmountReject:0,
      Reject: false,
      WeightReject: 0,
      PalletsReject: 0,
      Remark: data.remark,
      WeightNeto: data.weight,
      // EntryDetailNum: '',
    }
    let result:IEntriesKnisotItem[]  = [dataFormed]
    if (addictional?.multyplier) {
      
      result = createObjectsArray(
        dataFormed, 
        addictional?.isEdit ? addictional.multyplier : addictional.multyplier + 1, 
        // addictional.multyplier + (addictional?.isEdit ? 0 : 1), 
        addictional.isCopyWithWeigh,
        
      )
    }

    try {
      const {Details}: IEntrieDetails = await Knisot.createDetailsKnisa(knisaTitleData.EntryNum, result)
      // console.log(res)
      dispatch({type: 'CREATE_ENTRIE_DETAILS', payload: Details})
      setKnisaDetailsData(Details)
      
      setSelectedItem(null)
      handleControlProduct()
    } catch (error) {
    }
  }
 
  const handleSelectDetailsItem = (item: IEntriesKnisotItem) => {
    if(!isOffice) return
    setSelectedItem(item)
    setIsOpenProduct(true)
  }

  const handleDeleteItem = async (item: IEntriesKnisotItem) => {
    if(!knisaTitleData) return
    dispatch({type: LOAD})
    try {
      await Knisot.deleteEntryDetail(knisaTitleData.EntryNum, item.DetailNum)
      const newArr = knisaDetailsData?.filter(i => i.DetailNum !== item.DetailNum)
      console.log(item.DetailNum)
      dispatch({type: CREATE_ENTRIE_DETAILS, payload: newArr})
      setKnisaDetailsData(newArr || [])
    } catch (error) {

    } finally {
      dispatch({type: LOAD_END})
    }
  }

  const handleBack = () => {
    history.push(LINKS.knisot.teudot.mainURL)
    dispatch({type: CLEAR_ENTRY_STATE})
  }

  useEffect(() => {
    if(!id) return
    const fetchData = async () => {
      dispatch({type: LOAD})
      setIsOpenTitle(false)
      try {
        const res: IEntrieDetails = await Knisot.getEntryDetails(id)
        const {Details, ...rest} = res
        setKnisaTitleData(res)
        setKnisaDetailsData(Details)
      } catch (error) {
      } finally {
        dispatch({type: LOAD_END})
      
      }
    }
    fetchData()
  }, [id])

  const render = useMemo(() => {
    if(!knisaDetailsData) return []
    return knisaDetailsData.filter(item => 
      `${item.ProductDesc.trim()} ${item.VarietyDesc.trim()}`.toLocaleLowerCase().includes(searchDetails.toLocaleLowerCase())
    )
  }, [searchDetails, knisaDetailsData])

  const modalProps = {
    fetchedKtisa: title || knisaTitleData,
    productsWithVariety: productsWithVariety,
    growerList: growerList?.growers || [],
    carList: carList?.cars || [],
    warehouseList: warehouseList || [],
    createTitle: handleCreateTitle,
    teudaTypes: teudaTypes || []
  }
  const addProductProps = {
    productsWithVariety: productsWithVariety || [],
    palletList: palletTypeList?.pallets || [],
    packageList: packageList || [],
    cashedDetails: selectedItem,
    sizeList: sizeList?.sizes || [],
    handleCreateDetails: handleAddDetailesItem,
    handleChangeDetails: changeDeteilsItem,
    handleControlModal: handleControlProduct
  }
  const detailsProps = {
    handleSelectItem: handleSelectDetailsItem,
    print: handlePrintKnisa,
    isOffice: isOffice,
    handleDeleteItem: handleDeleteItem
  }
  return (
    <OrderlikeLayout label={id ? t("knisa_create_page_title_ids", {id: `#${knisaTitleData?.EntryNum}`})  : t("knisa_create_page_title")}>
      {
        isAllowToShowTitle &&
          <ColumModal 
            title={t("knisa_create_modal_title_title")}
            Children={ModalKnisaTitle}
            controlFunc={handleControlTitle}
            childrenProps={modalProps}
          />
      }
      {
        isOpenProduct &&
          <ColumModal 
            title={selectedItem ? t("knisa_create_modal_product_title_edit") : t("knisa_create_modal_product_title_create")}
            Children={ModalKnisaAddProduct}
            controlFunc={handleControlProduct}
            childrenProps={addProductProps}
          />
      }
      <TitleCreateKnisa
        knisaTitleData={knisaTitleData}
        handleControlTitle={handleControlTitle}
      />
      {
        knisaTitleData &&
          <ControlSearchPannelLayout>
            <SearchInput 
              input={searchDetails}
              setInput={setSearchDetails}
              value={t("knisa_create_page_search_placeholder")}
            />
            {
              isOffice && 
                <CircleActiveFilterBtn
                  func={handleControlProduct}
                >
                  <PlusIcon />
                </CircleActiveFilterBtn>
            }
          
          </ControlSearchPannelLayout>

      }
      <BodyScrollContainer>
        {
          knisaDetailsData &&
            <TitlesList 
              list={render}
              uniqueKey='DetailNum'
              Element={EntryDetailsItem}
              noPadding={false}
              backgroundColor='#e9f3f2'
              additionalData={detailsProps}
            />
        }
      </BodyScrollContainer>
      <ButtonLayout>
        <ControlButton 
          lable={t("buttons:back")}
          handleClick={handleBack}
        />
        
      </ButtonLayout>             
    </OrderlikeLayout>
  )
}

export default CreateKnisa