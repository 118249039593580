// reference

// const knisaMainHE = {
//   "knisa_main_title": "תעודות כניסה",
//   "knisa_main_search_placeholder": "חיפוש תעודה",
//   "knisa_main_button_new": "הוספת כניסה",
// }

// const knisaCreatePageHE = {
//   "knisa_create_page_title": "כניסה חדשה",
//   "knisa_create_page_title_ids": "כניסה: {{id}}",
//   "knisa_create_page_search_placeholder": "חיפוש",
// }

// const knisaCreateTitleModalHE = {
//   "knisa_create_modal_title_title": "כניסה חדשה",
//   "knisa_create_modal_title_date": "תאריך",
//   "knisa_create_modal_title_grower": "מגדל",
//   "knisa_create_modal_title_refference": "אסמכתא",
//   "knisa_create_modal_title_carNum": "מספר רכב",
//   "knisa_create_modal_title_driver": "נהג",
//   "knisa_create_modal_title_warehouse": "מחסן",
//   "knisa_create_modal_title_teudaType": "סוג תעודה",
//   "knisa_create_modal_title_remark": "הערה",
//   "knisa_create_modal_title_saveTitle": "צור כניסה",
// }

// const knisaCreateProductModalHE = {
//   "knisa_create_modal_product_title_create": "הוספת פריט לכניסה",
//   "knisa_create_modal_product_title_edit": "עדכון פריט",
//   "knisa_create_modal_product_externalEntry": "מספר כניסה חיצוני",
//   "knisa_create_modal_product_pallet": "משטח",
//   "knisa_create_modal_product_qty": "כמות",
//   "knisa_create_modal_product_product": "פריט",
//   "knisa_create_modal_product_size": "גודל",
//   "knisa_create_modal_product_package": "אריזה",
//   "knisa_create_modal_product_weight": "משקל",
//   "knisa_create_modal_product_price": "מחיר",
//   "knisa_create_modal_product_detection": "איתור",
//   "knisa_create_modal_product_copy": "העתק",

//   "knisa_create_modal_product_includeWeight": "ומשקל",
//   "knisa_create_modal_product_createButton": "הוסף פריט",
// }

// const knisaCreateElementHE = {
//   "knisa_create_element_swipe_edit": "עדכון",
//   "knisa_create_element_swipe_delete": "מחיקה",
// }

// export const knisaTranslationHE = {
//   knisa: {
//     ...knisaMainHE,
//     ...knisaCreatePageHE,
//     ...knisaCreateTitleModalHE,
//     ...knisaCreateProductModalHE,
//     ...knisaCreateElementHE
//   }
// }

const knisaMainAR = {
  "knisa_main_title": "مستندات الدخول",
  "knisa_main_search_placeholder": "البحث عن مستند",
  "knisa_main_button_new": "إضافة دخول",
}

const knisaCreatePageAR = {
  "knisa_create_page_title": "دخول جديد",
  "knisa_create_page_title_ids": "دخول: {{id}}",
  "knisa_create_page_search_placeholder": "بحث",
}

const knisaCreateTitleModalAR = {
  "knisa_create_modal_title_title": "دخول جديد",
  "knisa_create_modal_title_date": "تاريخ",
  "knisa_create_modal_title_grower": "مزارع",
  "knisa_create_modal_title_refference": "مرجع",
  "knisa_create_modal_title_carNum": "رقم السيارة",
  "knisa_create_modal_title_driver": "السائق",
  "knisa_create_modal_title_warehouse": "مستودع",
  "knisa_create_modal_title_teudaType": "نوع المستند",
  "knisa_create_modal_title_remark": "ملاحظة",
  "knisa_create_modal_title_saveTitle": "إنشاء دخول",
}

const knisaCreateProductModalAR = {
  "knisa_create_modal_product_title_create": "إضافة عنصر للدخول",
  "knisa_create_modal_product_title_edit": "تحديث العنصر",
  "knisa_create_modal_product_externalEntry": "رقم الدخول الخارجي",
  "knisa_create_modal_product_pallet": "بالت",
  "knisa_create_modal_product_qty": "الكمية",
  "knisa_create_modal_product_product": "العنصر",
  "knisa_create_modal_product_size": "الحجم",
  "knisa_create_modal_product_package": "العبوة",
  "knisa_create_modal_product_weight": "الوزن",
  "knisa_create_modal_product_price": "السعر",
  "knisa_create_modal_product_detection": "الموقع",
  "knisa_create_modal_product_copy": "نسخ",

  "knisa_create_modal_product_includeWeight": "تضمين الوزن",
  "knisa_create_modal_product_createButton": "إضافة عنصر",
}

const knisaCreateElementAR = {
  "knisa_create_element_swipe_edit": "تحديث",
  "knisa_create_element_swipe_delete": "حذف",
}

export const knisaTranslationAR = {
  knisa: {
    ...knisaMainAR,
    ...knisaCreatePageAR,
    ...knisaCreateTitleModalAR,
    ...knisaCreateProductModalAR,
    ...knisaCreateElementAR
  }
}
