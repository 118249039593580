const knisaMainEN = {
  "knisa_main_title": "Entry Documents",
  "knisa_main_search_placeholder": "Search document",
  "knisa_main_button_new": "Add entry",
}

const knisaCreatePageEN = {
  "knisa_create_page_title": "New entry",
  "knisa_create_page_title_ids": "Entry: {{id}}",
  "knisa_create_page_search_placeholder": "Search",
}

const knisaCreateTitleModalEN = {
  "knisa_create_modal_title_title": "New entry",
  "knisa_create_modal_title_date": "Date",
  "knisa_create_modal_title_grower": "Grower",
  "knisa_create_modal_title_refference": "Reference",
  "knisa_create_modal_title_carNum": "Car number",
  "knisa_create_modal_title_driver": "Driver",
  "knisa_create_modal_title_warehouse": "Warehouse",
  "knisa_create_modal_title_teudaType": "Document type",
  "knisa_create_modal_title_remark": "Remark",
  "knisa_create_modal_title_saveTitle": "Create entry",
}

const knisaCreateProductModalEN = {
  "knisa_create_modal_product_title_create": "Add item to entry",
  "knisa_create_modal_product_title_edit": "Update item",
  "knisa_create_modal_product_externalEntry": "External entry number",
  "knisa_create_modal_product_pallet": "Pallet",
  "knisa_create_modal_product_qty": "Quantity",
  "knisa_create_modal_product_product": "Item",
  "knisa_create_modal_product_size": "Size",
  "knisa_create_modal_product_package": "Package",
  "knisa_create_modal_product_weight": "Weight",
  "knisa_create_modal_product_price": "Price",
  "knisa_create_modal_product_detection": "Location",
  "knisa_create_modal_product_copy": "Copy",

  "knisa_create_modal_product_includeWeight": "Include weight",
  "knisa_create_modal_product_createButton": "Add item",
}

const knisaCreateElementEN = {
  "knisa_create_element_swipe_edit": "Update",
  "knisa_create_element_swipe_delete": "Delete",
}

export const knisaTranslationEN = {
  knisa: {
    ...knisaMainEN,
    ...knisaCreatePageEN,
    ...knisaCreateTitleModalEN,
    ...knisaCreateProductModalEN,
    ...knisaCreateElementEN
  }
}