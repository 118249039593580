import { requests } from "../config";
import { ForcastProductItem, IForecastReqData } from "./models/forcastProductitem";
import { IProductRecWithCount } from "./models/productRecWithCount";
import { ISupplyDataCustomerItem, ISupplyDataGrowerItem } from "./models/seller";

export const Forecasts = {
  //DateFrom=2&DateTo=3
  getForecastsByDateRange: (data: string): Promise<ForcastProductItem[]> => requests.get(`grower_supply_forecast?${data}`, 'go'),
  getForecastById: (RecID: number | string): Promise<ForcastProductItem> => requests.get(`grower_supply_forecast/${RecID}`, 'go'),
  createForecast: (data: IForecastReqData[]): Promise<ForcastProductItem[]> => requests.post('grower_supply_forecast', 'go', data ),
  updateForecast: (data: IForecastReqData) => requests.put(`grower_supply_forecast/${data.RecID}`, 'go', data),
  sendSms: (date: string, customerID: number, warehouseID: number) => requests.get(`order/supply_details/send_sms_to_growers?Date=${date}&CustomerID=${customerID}&WarehouseID=${warehouseID}`, 'go'),
  deleteForecasts: (RecID: number | string) => requests.delete(`grower_supply_forecast/${RecID}`, 'go'),
  getProductWithCounts: (data: string): Promise<IProductRecWithCount[]> => requests.get(`recommendation/with_counts?Date=${data}`, 'go'),
  getProductWithCountsByProdVarID: (date: string, prodID: number, varID: number ): Promise<IProductRecWithCount[]> => requests.get(`recommendation/with_counts?Date=${date}&ProductID=${prodID}&VarietyID=${varID}`, 'go'),
  getSellerSupplyByCustomer: (date: string): Promise<ISupplyDataCustomerItem[]> => requests.get(`seller/supply_details?Date=${date}`, 'go'),
  getSellerSupplyByGrower: (date: string): Promise<ISupplyDataGrowerItem[]> => requests.get(`seller/supply_details_by_growers?Date=${date}`, 'go'),
}