
import { addIDForTheList } from '../../generic/utils/addUIDForTheList';
import { handleNotifications } from '../../generic/utils/handleNotifications';

export const getPallets = async (token, url, setState, setStateDefault) => {
    let axios = require('axios');
    const CancelToken = axios.CancelToken.source();
    try{
        let response = await axios.get(`${url}`, {
            headers: {
                'Authorization' : 'Bearer ' + token
            }
        },
        {
            cancelToken: CancelToken.token
        })
        let concatArr = addIDForTheList(response.data.pallets);
        setState(concatArr);
        setStateDefault(concatArr);
    
    } catch (err) {
        handleNotifications('משהו השתבש', {type: "error"});
    }
}