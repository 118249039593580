import { requests } from "../config";
import { GET_PALLETS } from "../settings";
import { IPutLocationData } from "./models/locations";
import { IPalletToChangeItem, PalletToChangeTypes } from "./models/palletToChange";
import { IPalletZPLData } from "./models/printPalletZPL";

export const Pallets = {
    getPalletList: (data: string) => requests.get(GET_PALLETS, "node"),
    getPalletItems: (palletID: number) => requests.get(`delivery/15/${palletID}`, 'node'),
    getPalletsDetailsByDelivery: (id: number, subID: number) => requests.get(`pallet/details?Delivery_Sub=${subID}&Delivery_Num=${id}`, 'go'),
    getPalletsDetailsByOrderID: (id: number) => requests.get(`pallet/details/by_order?OrderID=${id}`, 'go'),
    getPalletsByIDs: (orderID: number, clientID: number) => requests.get(`likut/pallet/${orderID}/${clientID}`, 'node'),
    getPalletTypesForClient: (customerID: number | string) => requests.get(`pallet/available_for_client?CustomerID=${customerID}`, 'go'),
    getPalletListWithDetails: (customerID: string | number) => requests.get(`pallet/available_list_with_details?CustomerID=${customerID}`, 'go'),
    getPalletWithDitailesByPalletNum: (palletNum: string | number, isExternal?: number) => 
        requests.get(`pallet/by_num?Pallet_Num=${palletNum}&IsExternal=${isExternal ? isExternal : 0}`, 'go'),
    getPalletLocations: (data: string) => requests.get(`tbl/Tbl_Locations`, 'go'),
    putPalletLocation: (data: IPutLocationData) => requests.put(`pallet/location`, 'go', data),
    putPalletDitailes: (data: any) => requests.put(`pallet/not_exit/detail`, 'go', data),
    putPalletTypesAndQty: (data: any) => requests.put(`pallet/detail`, 'go', data),
    deleteProductFromPallet: (RecID: number) => requests.delete(`delivery/18/${RecID}`, 'node'),
    deletePalletGo: (palletID: number) => requests.delete(`pallet/${palletID}`, 'go'),
    putCustomerToPallet: (palletID: number, data: {CustomerID: number}) => requests.put(`/pallet/${palletID}/customer`, 'go', data),
    printPalletZPL: (palletNum: number, data: IPalletZPLData) => requests.put(`likut/printer/${palletNum}`, 'node', data),
    getPalletsToChange: (id: number, type: PalletToChangeTypes): Promise<IPalletToChangeItem[]> => requests.get(`pallet/with_deteils_to_change_pallet_type${type}${id}`, 'go'),
    
}