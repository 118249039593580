import React from 'react'
import { IReportQ10Body, IReportQ10Meta } from '../../../../../../modules/API/Reports/models/q10'
import TableBody from '../../Q8/Layouts/Table/TableBody'
import TableRow from '../../Q8/Layouts/Table/TableRow'
import TableBodyCell from '../../Q8/Layouts/Table/TableBodyCell'

type Props = {
  head: IReportQ10Meta
  data: IReportQ10Body[]
}

const BodyQ10 = ({
  data, head
}: Props) => {
  const formatedHead = Object.entries(head).sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) => value)
  return (
    <TableBody>
      {
        data.map((item, index) => {
          const formatedData = Object.entries(item).sort((a, b) => a[0].localeCompare(b[0])).map(([key, value]) => value)
            console.log(item)
            console.log(head)
            console.log(formatedData)
            console.log(formatedHead)
          return <TableRow key={index}>
            {
              formatedData.map((headItem, headIndex) => {
                return formatedHead[headIndex].trim() !== "" && formatedHead[headIndex].trim() !== "" &&
                <TableBodyCell key={headIndex}>
                  {
                    typeof formatedData[headIndex] !== "string" 
                    ? formatedData[headIndex].toFixed(1) 
                    : formatedData[headIndex]
                  }
                </TableBodyCell>
                }
              
              )
            }
          </TableRow>
          }
        )
      }
    </TableBody>
  )
}

export default BodyQ10