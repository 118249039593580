import React, { useState } from 'react'
import OrderlikeLayout from '../../../../../generic/Layouts/OrderlikeLayout'
import Table from './Components/Table'
import ControlSearchPannelLayout from '../../../../../generic/Layouts/Wrappers/ControlSearchPannelLayout'
import SearchInput from '../../../../../generic/inputs/inputSearch'

const TableCustomers = () => {

  const [search, setSearch] = useState<string>('')


  return (
    <OrderlikeLayout
      label='לקוחות'
    >
      <ControlSearchPannelLayout>
        <SearchInput 
          input={search}
          setInput={setSearch}
        />
      </ControlSearchPannelLayout>
      <Table 
        search={search}
      />
    </OrderlikeLayout>
  )
}

export default TableCustomers