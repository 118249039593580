import React, {useMemo, useState} from 'react'
import { IProductCustomerItem, OrderDetail } from '../../../../../../modules/API/Orders/models/detailsProductCustomer'
import styled from 'styled-components'
import { IPackagesItem } from '../../../../../../modules/API/Defaults/models/packages'
import { IProductListItemNode } from '../../../../../../modules/API/Defaults/models/productListNode'
import SearchBlockWithTitle from '../../../procedures/Q8/Layouts/SearchBlockWithTitle'
import useDataFetcher from '../../../knisot/hooks/useFetchData'
import { Defaults } from '../../../../../../modules/API/Defaults/defaults'
import SearchProduct from '../../../forcasts/Products/Components/SearchProduct'
import SearchVaiety from '../../../forcasts/Products/Components/SearchVaiety'
import { IVarietyListResponse } from '../../../../../../modules/API/Defaults/models/varietyList'
import ProductModalNestedItem from './ProductModalNestedItem'
import ControlButton from '../../../../../../generic/buttons/ControlButton'
import { useTranslation } from 'react-i18next'
import CircleNumericInput from '../../../../../../generic/inputs/CircleNumericInput'

type Props = {
  product: IProductCustomerItem
  packages: IPackagesItem[]
  products: IProductListItemNode[]
  handleSaveFunc: (
    OriginalItem: IProductCustomerItem, 
    resultItem: IProductCustomerItem
  ) => void
}

const ProductModal = ({
  product,
  packages,
  products,
  handleSaveFunc
}: Props) => {
  const [t] = useTranslation('buttons')
  const [selectedProduct, setSelectedProduct] = useState<number>(product.Product.ProductID)
  const [selectedVariety, setSelectedVariety] = useState<number>(product.Product.VarietyID)
  const [selectedSize, setSelectedSize] = useState<string>(product.Product.SizeID)
  const [defaultProduct, setDefaultProduct] = useState<IProductCustomerItem>(product)
  const [search, setSearch] = useState<string>('')

  const {
    data: varietyList
  } = useDataFetcher<IVarietyListResponse>(
    Defaults.getVarietyByProductID, `${selectedProduct}`
  )
  
  const handleChangeItem = (item: OrderDetail) => {
    const newOrderDetails = defaultProduct.OrderDetails.map(e => {
      if(e.RecID === item.RecID) {
        return item
      }
      return e
    })
    setDefaultProduct({
      ...defaultProduct,
      OrderDetails: newOrderDetails
    })
  }
  
  const total = useMemo(() => {
    return defaultProduct.OrderDetails.reduce((acc, item) => {
      return acc + item.OrderPacks
    }, 0)
  },[defaultProduct])

  const handleSave = () => {
    const result = 
       {
          Product: {
            ...product.Product,
            ProductID: selectedProduct,
            VarietyID: selectedVariety,
            SizeID: selectedSize
          },
          OrderDetails: render.OrderDetails.map(e => {
           return {
             ...e,
             ProductID: selectedProduct,
             VarietyID: selectedVariety,
             SizeID: selectedSize,
             Unit: 1
             // RecID: 0
           }
         })

       }
    handleSaveFunc(
      product, 
      result
    )
  }

  const render = useMemo(() => {
    if(!product) return {} as IProductCustomerItem
    const data =  defaultProduct.OrderDetails.filter(item => 
      item.Customer_Name.trim().toLocaleLowerCase().includes(search.toLocaleLowerCase())
    )
    return {
      ...defaultProduct,
      OrderDetails: data
    }
  }, [search, defaultProduct, product])
  
  return (
    <MainWrapper>
      <SearchBlockWithTitle
        title={["פריט", "סך הכל"]}
      >
        <CircleNumericInput 
          value={total}
          changeFunc={() => {}}
          disabled
        />
        {
          selectedProduct && varietyList && (varietyList.variety.length > 0) && (
            <SearchVaiety 
              defaultID={selectedVariety}
              setID={setSelectedVariety}
              list={varietyList.variety}
            />
          )
        }
        <SearchProduct 
          defaultProductID={selectedProduct}
          setDefaultProductID={setSelectedProduct}
          list={products}
        />
      </SearchBlockWithTitle>
      <Title>
        לקוחות
      </Title>
      <SearchBlockWithTitle
        title=""
      >
        <InnerColumn>
          {
            render.OrderDetails.map((e, i) => 
              <ProductModalNestedItem 
                index={i}
                item={e}
                pacakges={packages}
                handleChange={handleChangeItem}
                {...e}
              />
            )
          }
        </InnerColumn>
      </SearchBlockWithTitle>
      <ControlButton 
        lable={t('save')}
        handleClick={handleSave}
      />
    </MainWrapper>
  )
}
const MainWrapper = styled.div`
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: .5em;
  overflow: hidden;

`
const InnerColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .5em;
  height: 400px;
  overflow-y: auto;

`
const Title = styled.h4`
  width: 100%;
  text-align: center;
  color: #297F76;
`
export default ProductModal